import React from 'react';
import '../../../../css/cotacao.css'
import Select from 'react-select';
export default class QuoteTransport extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            transportCost:0,
            clienteResponsavelPeloFrete:this.props.cotacao.isClientPayingFreight()
        }
        this.selectedOption=this.selectedOption.bind(this)
        this.selectedFreightResponsible=this.selectedFreightResponsible.bind(this)
        this.setTransportCost=this.setTransportCost.bind(this)
        this.setResponsibleForFreight=this.setResponsibleForFreight.bind(this)

    }


    setTransportCost(e){
        e.preventDefault()
        this.setState({
            [e.target.name]:e.target.value
        })
        console.log(e.target.value)
        this.props.cotacao.setValorDoTransporte(e.target.value)
        this.props.updateTabs(this.props.cotacao)
    }

    setResponsibleForFreight(e){
        e.preventDefault();
        let responsible=e.target.value
        if(responsible==="cliente")
        {
            this.setState({clienteResponsavelPeloFrete:true})
        }
        else{
            this.setState({clienteResponsavelPeloFrete:false})
        }
        this.props.updateTabs(this.props.cotacao)
    }

    selectedOption = (selectedOption) => {
        try{
            this.setState({
                selectedTransportador:selectedOption.value,
                selectedTransportadorId:selectedOption.value.transportadorId
            })
        }
        catch(error){
            console.log(error)
        }
        let cotacao=this.props.cotacao
        cotacao.setTransportador(selectedOption.value)
        this.props.updateTabs(cotacao)
    }

    selectedFreightResponsible = (selectedOption) => {
        if(selectedOption.value==="cliente"){
            this.setState({
                clienteResponsavelPeloFrete:true
            })
        }
        else{
            this.setState({
                clienteResponsavelPeloFrete:false
            })
        }
        this.props.cotacao.setResponsibleForFreight(selectedOption.value)
        this.props.updateTabs(this.props.cotacao)
        console.log("cliente responsável: ",
            this.props.cotacao.isClientPayingFreight())
    }

    //remember to sync localStorage with server responses

    render() {
        let cotacao=this.props.cotacao
        let nomeDoTransportador=""
        try{
            nomeDoTransportador=cotacao.transportador.Nome
        }catch(err){
            console.log(err)
            nomeDoTransportador=""
        }
        console.log(nomeDoTransportador)
        let freightOptions=[
            {
               value:"cliente",
               label: "Client"
            },
            {
                value: "aerotubos",
                label:"Seller"
            }
        ]
        let freightPlaceholder="Client"
        if(!this.props.cotacao.isClientPayingFreight()){
            freightPlaceholder="Seller"
        }

        return (
            <div className="quotePanelTransportador">
                <div className="freightCompany">
                    <span>Select the freight company: </span>
                    <Select

                        classNamePrefix={"leftContent"}
                        placeholder={nomeDoTransportador}
                        onChange={this.selectedOption}
                        label="Single select"
                        options={this.props.options}
                    />
                    </div>
                <div >
                    <label for="transportadorCost" className="labelTransportCost">Transport Cost:</label>
                    <input
                        id="transportadorCost"
                        placeholder={this.props.cotacao.getValorTransporte()}
                        onChange={this.setTransportCost}
                        type="number"
                        step={0.01}
                        name="transportCost">
                    </input>
                </div>
                <div className="freightResponsible">
                    Responsible for paying transport
                    <Select
                        onChange={this.selectedFreightResponsible}
                        placeholder={freightPlaceholder}
                        label="Single select"
                        options={freightOptions}

                    />
                </div>
            </div>
        )
    }
}