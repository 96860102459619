import {Invoice} from "./Invoice";

export default class Invoices{

    constructor(invoices){
        this.invoices=[]
        if(invoices){
            if(invoices.length>0){
                let f=[]
                for(let i=0;i<invoices.length;i++){
                    f.push(new Invoice(invoices[i]))
                }
                this.invoices=f
            }
        }
        else{
            let invoices=JSON.parse(localStorage.getItem("invoices"))
            if(invoices){
                if(invoices.length>0){
                    for(let i=0;i<invoices.length;i++){
                        this.invoices.push(new Invoice(invoices[i]))
                    }
                }
            }
        }


    }

    addOrUpdateInvoice(invoice){
        let invoices=[]
        let inserted=false;
        for(let i=0;i<this.invoices.length;i++){
            let inv=new Invoice(this.invoices[i])
            if(inv.quotationId===invoice.quotationId){
                invoices.push(new Invoice(invoice))
                inserted=true
            }
            else{
                invoices.push(inv)
            }
        }
        if(!inserted){
            invoices.push(new Invoice(invoice))
        }
        localStorage.setItem('invoices',JSON.stringify(invoices))
        this.invoices=invoices
    }

    getOutstandingInvoices(clientId){
        let outstanding=[]
        for(let i=0;i<this.invoices.length;i++){
            let invoice=this.invoices[i]
            if((invoice.clientId===clientId)||(clientId==="*")){
                if(invoice.balance>0){
                    outstanding.push(invoice)
                }
            }
        }
        return outstanding
    }

    getOptions(){
        let options=[]
        for(let i=0;i<this.invoices.length;i++){
            try{
                let invoice=this.invoices[i]
                let option={
                    value:invoice,
                    label:this.invoices[i].clientId+"/"+this.invoices[i].invoiceId
                }
                options.push(option)
            }
            catch(err){
                console.log(err)
            }
        }
        return options;
    }
    getInvoices(){
        if(this.invoices){
            if(this.invoices.length>0){
                return this.invoices
            }
            else return []
        }
        return []
    }

}