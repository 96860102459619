import React from 'react';
import '../../css/pagamentos.css'
import Updated from "./Updated";
import config from '../../config.js'
import {Quotation} from "../../model/Quotation";
import Select from "react-select";
const API_URL = config.api_endpoint

export default class PaymentAllocation extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            selectedClientId:null,
            quotes:[],
            allocations:{},
            amount:null,
            datePaid:null,
            reference:null,
            totalEntered:0,
            details: '',
            options:[]

        }
        this.getClientQuotations=this.getClientQuotations.bind(this)
        this.showContent=this.showContent.bind(this)
        this.getInvoice=this.getInvoice.bind(this)
        this.getPastPayments=this.getPastPayments.bind(this)
        this.getOutstanding=this.getOutstanding.bind(this)
        this.allocatePayment=this.allocatePayment.bind(this)
        this.handleChange=this.handleChange.bind(this)
        this.totalAllocated=this.totalAllocated.bind(this)
        this.showSubmit=this.showSubmit.bind(this)
        this.submitPaymentAllocation=this.submitPaymentAllocation.bind(this)
        this.updateLocalStorage=this.updateLocalStorage.bind(this)
        this.getQuoteStatusString=this.getQuoteStatusString.bind(this)
    }


    getQuoteStatusString(statusString){
        switch (statusString.toUpperCase()){
            case 'OPEN':
                return 'Em aberto';
            case 'AWAITINGFORPAYMENT':
                return 'Aguardando pagamento';
            case 'AWAITINGFORPAYMENTNOTSHIPPED':
                return 'Aguardando pagamento / Produtos não enviados'
            default:
                return 'N/A'
        }
    }

    componentWillMount(){
        let items=this.getClientQuotations()
        let options=[]
            items.map(function(item, i){
                options.push({
                    label: item.client.clientId + " "+item.client.name,
                    value:item
                })
            },this)
        this.setState({
            payment:{},
            options:options
        })

    }

    handleClientChange = (selectedOption) => {
        console.log(selectedOption)
        this.setState({ selectedOption });
        //item => quotation
        let item=selectedOption.value
        try{
            this.setState({
                selectedClientId:item.client.clientId,
                quotes:item.openQuotes,
                payment:{},
            })
            console.log(selectedOption.value.clientId)
        }
        catch(error){console.log(error)}




    }

    updateLocalStorage(invoices,quotations){
        let savedInvoices=JSON.parse(localStorage.getItem("invoices"))
        let savedQuotations=JSON.parse(localStorage.getItem("quotations"))
        if(!savedInvoices){savedInvoices=[]}
        if(!savedQuotations){savedQuotations=[]}

        let newInvoices=invoices;
        for(let i=0;i<savedInvoices.length;i++){
            let invoice=savedInvoices[i]
            let existent=false;
            for(let j=0;j<invoices.length;j++){
                let n=invoices[j];
                if(n.quotationId===invoice.quotationId){
                    existent=true;
                    break;
                }
            }
            if(!existent){
                newInvoices.push(invoice)
            }
        }
        localStorage.setItem("invoices",JSON.stringify(newInvoices))


        let newQuotations=quotations;
        for(let i=0;i<savedQuotations.length;i++){
            let quotation=savedQuotations[i]
            let existent=false;
            for(let j=0;j<quotations.length;j++){
                let n=quotations[j];
                if(n.quotationId===quotation.quotationId){
                    existent=true;
                    break;
                }
            }
            if(!existent){
                newQuotations.push(quotation)
            }
        }
        localStorage.setItem("quotations",JSON.stringify(newQuotations))

    }

    showSubmit(){
        if(this.state.amount){
            if(this.state.amount===this.totalAllocated()){
                return(
                    <button>SUBMIT</button>
                )
            }
        }
    }
    totalAllocated(){
        let total=0
        for(let key in this.state.allocations){
            total=total+(this.state.allocations[key]*1)
        }
        return total
    }
    allocatePayment(e){
        e.preventDefault()
        let allocations=this.state.allocations
        allocations[e.target.name]=e.target.value

        let total=0
        for(let key in allocations){
            total=total+(allocations[key]*1)
        }
        this.setState({
            allocations:allocations,
            totalEntered:total
        })
        console.log(this.state.allocations)
        console.log(this.state.totalEntered)

    }
    getInvoice(quotationId){
        let invoice='NOT INVOICED'
        for(let i=0;i<this.props.invoices.invoices.length;i++){
            let item=this.props.invoices.invoices[i]
            if(item.quotationId===quotationId){
                invoice=item.invoiceId;
                break;
            }
        }
        return invoice
    }
    getOutstanding(item){
        let outstanding=item.getTotal()
        for(let i=0;i<this.props.invoices.invoices.length;i++){
            let invoice=this.props.invoices.invoices[i]
            if(invoice.quotationId===item.quotationId){
                outstanding=invoice.balance
                break;
            }
        }
        return outstanding
    }
    getPastPayments(quotationId){
        let pastPayments=0;
        for(let i=0;i<this.props.invoices.invoices.length;i++){
            let invoice=this.props.invoices.invoices[i]
            if(invoice.quotationId===quotationId){
                pastPayments=invoice.amount-invoice.balance
                break;
            }
        }
        return pastPayments
    }
    handleChange(e){
        e.preventDefault();
        this.setState({
            [e.target.name]:e.target.value
        })

    }
    getClientQuotations(){
        let clients=[]
        for(let i=0; i<this.props.listaDeClientes.clients.length; i++){
            let clientData={
                client:this.props.listaDeClientes.clients[i],
                openQuotes:[]
            }
            clients.push(clientData)
        }
        let quotations=this.props.quotations.getQuotes()
        if(quotations){
            for(let i=0;i<quotations.length;i++){
                for(let j=0;j<clients.length;j++){
                    let quotation=quotations[i]
                    console.log(quotation)
                    let client=clients[j]
                    console.log(client)
                    if(quotation.cliente.clientId===client.client.clientId){
                       if("quoteStatus" in quotation){
                           if(quotation.quoteStatus){
                               if((quotation.quoteStatus.toUpperCase()==='OPEN')||
                                   (quotation.quoteStatus.toUpperCase()==='AWAITINGFORPAYMENT')||
                                   (quotation.quoteStatus.toUpperCase()==='AWAITINGFORPAYMENTNOTSHIPPED')
                               ){
                                   clients[j].openQuotes.push(quotation)
                               }
                               break;
                           }
                       }

                    }

                }
            }
        }
        let c=[]
        for(let i=0;i<clients.length;i++){
            if(clients[i].openQuotes.length>0){
                c.push(clients[i])
            }
        }

        return c
    }
    showContent(){
        if(this.state.selectedClientId){

            let component0=(
                <table>
                    <tbody>
                    <tr>
                        <th>Valor do pagamento</th>
                        <th>Date do recebimento</th>
                        <th>Referência</th>
                    </tr>
                    <tr>
                        <td><input type="decimal" name="amount" onChange={this.handleChange}/></td>
                        <td><input type="date" name="datePaid" onChange={this.handleChange}/></td>
                        <td><input type="text" name="reference" onChange={this.handleChange}/></td>
                    </tr>
                    </tbody>
                </table>
            )

            let component1=(
                    <tr>
                        <th>Cotação #</th>
                        <th>Fatura #</th>
                        <th>Total Orçamento</th>
                        <th>Pagamentos</th>
                        <th>Balanço</th>
                        <th>Situação</th>
                        <th>Alocação</th>
                    </tr>
            )
            let component2= (this.state.quotes.map(function(item, i){
                let quote=new Quotation(item)
                return(
                    <tr key={i}>
                        <td>{item.quotationId}</td>
                        <td>{this.getInvoice(item.quotationId)}</td>
                        <td>{quote.getTotal()}</td>
                        <th>{this.getPastPayments(item.quotationId)}</th>
                        <th>{(this.getOutstanding(quote)*1).toFixed(2)}</th>
                        <th>{this.getQuoteStatusString(item.quoteStatus)}</th>
                        <td>
                            <input
                                type={"text"}
                                name={item.quotationId}
                                onChange={(e)=>{
                                    e.preventDefault()
                                    this.allocatePayment(e,item)
                                }}
                            >

                            </input>
                        </td>
                    </tr>
                )
            },this))
            return (
                <div>
                    {component0}
                <table>
                    <tbody>
                    {component1}
                    {component2}
                    </tbody>
                </table>
                </div>
            )
        }
    }
    showLinks(){
        let items=this.getClientQuotations()
        return (items.map(function(item, i){
            return(
                <a
                    href="/#"
                    key={i}
                    onClick={(e)=>{
                        e.preventDefault()
                        console.log('clicked ',item.client.clientId)
                        this.setState({
                            selectedClientId:item.client.clientId,
                            quotes:item.openQuotes,
                            payment:{}
                        })
                    }}
                >
                    {item.client.clientId}
                </a>
            )
        },this))
    }
    submitPaymentAllocation(e){
        e.preventDefault();
        let body={
            clientId:this.state.selectedClientId,
            amount:this.state.amount,
            datePaid:this.state.datePaid,
            reference:this.state.reference,
            allocations:this.state.allocations,
            authorizationToken:this.props.token
        }
        fetch(API_URL+"/payments/", {
            method: "POST",
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body:JSON.stringify(body)
        })
            .then(response => response.json())
            .then(response => {
                if(response){
                    if("statusCode" in response){
                        if(response.statusCode===200){
                            //need to update invoice, quotation
                            //client
                            this.props.listaDeClientes.changeClientData(response.body.client)
                            for (let i=0;i<response.body.invoices.length;i++){
                                this.props.invoices.addOrUpdateInvoice(response.body.invoices[i])
                            }
                            for(let i=0;i<response.body.quotations.length;i++){
                                this.props.quotations.updateQuote(response.body.quotations[i])
                            }

                            this.setState({
                                selectedClientId:null,
                                quotes:[],
                                allocations:{},
                                amount:null,
                                datePaid:null,
                                reference:null,
                                totalEntered:0,
                                updated:true
                            })
                            this.props.setContent('updated')

                        }
                    }
                }


            })
            .catch(error => {
               console.log(error)
            });
    }

    render() {

        if(this.state.updated){
            return <Updated/>
        }
        if(!this.state.selectedClientId){
            return(
                <div>
                    <h5>Selecione o cliente</h5>
                    <Select
                            onChange={this.handleClientChange}
                        options={this.state.options}
                    />
                    {this.showContent()}
                </div>

            )
        }
        if(parseFloat(this.state.amount)===parseFloat(this.state.totalEntered)){
            console.log('match')
            return(
                <div>
                    <h5>Selecione o cliente</h5>
                    <Select
                        onChange={this.handleClientChange}
                        options={this.state.options}
                    />
                            {this.showContent()}
                            <div className="buttonWrapper">
                            <button
                                onClick={this.submitPaymentAllocation}
                            >ENVIAR</button>
                            </div>
                </div>
            )
        }

        return(
            <div>
                <h5>Selecione o cliente</h5>
                <Select
                    onChange={this.handleClientChange}
                    options={this.state.options}
                />
                {this.showContent()}
            </div>
        )


    }
}