import React from 'react';
const rio = require('../assets/rio.jpg');
const logo_aerotubos = require('../assets/arcos.jpg');
export default class VerifyingAuthentication extends React.Component {

    render() {
        return (
            <div className="appLoading">
                <h3>Authenticating</h3>
                <div className="rio">
                    <img
                        className="rio"
                        src={rio}
                        aria-hidden
                        alt="background image"
                    />
                </div>


                <div className="aerotubos">
                    <img
                        className="aerotubos"
                        src={logo_aerotubos}
                        aria-hidden
                        alt="background image"
                    />

                </div>

                <div className="appLoadingText">

                    <h1>Authenticating</h1>
                    <br/><br/><br/><br/><br/><br/><br/><br/>
                    <p>
                        Cloud Computing RMIT
                    </p>
                    <p>

                    </p>
                    <p>
                        Version: 1.0
                    </p>
                </div>


            </div>
        )
    }
}


