import React from 'react';
const rio = require('../assets/rio.jpg');
const logo_aerotubos = require('../assets/arcos.jpg');
export default class Shipped extends React.Component {

    render() {
        return (
            <div className="appLoading">
                <div className="appLoadingInner">
                    <h1>SHIPMENT ADDED</h1>
                    <div className="rio">
                        <img
                            className="rio"
                            src={rio}
                            aria-hidden
                            alt="background image"
                        />
                    </div>


                    <div className="aerotubos">
                        <img
                            className="aerotubos"
                            src={logo_aerotubos}
                            aria-hidden
                            alt="background image"
                        />

                    </div>

                    <div className="appLoadingText">

                        <h3>Shipment added sucessfully!</h3>

                        <p>A new debit transaction for the shipment should appear in the clients history.</p>
                        <p>If you cannot see the debit nor the invoice, try and refresh he resources from the main menu and audit the information. Please contact admin@leonardomartins.com.au if you believe data is not consistent.</p>

                    </div>
                    <div className="bottomNote">
                        <p>
                            Developed by Cloud Computing Team - Royal Melbourne Institute of Technology (RMIT)
                        </p>
                        <p>
                            Version: 1.0
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}


