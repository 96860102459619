import React from 'react';
import '../../css/instructions.css'

export default class PaymentsAllocationInstructions extends React.Component {
    constructor(props) {
        super(props);
        this.state={

        }

    }


    render() {
        return(
            <div className="instructions">
                <h1>Payment Allocation Basic Instructions</h1>
                <ul>
                    <li>INVOICES
                        <ol>
                            <li>Select a clientID on the left</li>
                            <li>Allocate payments for each outstanding invoice</li>
                            <li>Total payment is automatically calculated for you so that you can cross check if you have not entered any wrong information. Please make sure the totals match with your client's received payment</li>
                            <li>Add date paid</li>
                            <li>Add a reference for the transaction (i.e. bank reference)</li>
                            <li>Submit</li>
                            <li>Both invoice and client balance are updated.</li>
                        </ol>
                    </li>
                    <li>QUOTATIONS
                        <ol>
                            <li>You can allocate partial or full payment for one quotation at a time</li>
                            <li>If you have received a single payment for multiple quotations, you will need to allocate each quotation separately</li>
                        </ol>
                    </li>

                </ul>

                <h1>Transactions</h1>
                <ul>
                    <li>View history of client transactions
                        <ol>
                            <li>Simply search / select a client from the drop-down list</li>
                            <li>You will see the client's current balance and a list of the the whole transaction history for that client</li>
                            <li>A debit type relates to a shipment and will have a quotation ID and an invoice ID</li>
                            <li>Click on the reference text to see more details about the shipment</li>
                            <li>A credit refers to a client's payment.</li>
                            <li>You can track which quotations the payments were made for by clicking on the reference</li>
                        </ol>
                    </li>

                </ul>
            </div>
        )

    }
}