import React from 'react';
import { FaHome } from "react-icons/fa";
import {Quotation} from '../../../model/Quotation'
import Item from '../../../model/Item';
import TabContainer from "../../../ui_components/TabContainer";
import QuoteProducts from "./quote_tab_components/QuoteProducts";
import QuoteClient from "./quote_tab_components/QuoteClient";
import QuoteTransport from "./quote_tab_components/QuoteTransport";
import ListaDeTransportadores from "../../../model/ListaDeTransportadores";
import ListaDeClientes from "../../../model/ListaDeClientes";
import Stock from "../../../model/Stock";
import QuoteSummary from "./quote_tab_components/QuoteSummary";
import Client from "../../../model/Client";
import config from '../../../config.js'
import NewQuotationInstructions from "./NewQuotationInstructions";
const API_URL = config.api_endpoint;
export default class NewQuotation extends React.Component {
    constructor(props) {

        super(props);
        this.state = {
            quotation: NewQuotation.retrieveSavedQuote(),
            tabs:[],
            productModal:false,
            options:[],
            quantidadeNovoProduto:0
        };

        this.updateTabs=this.updateTabs.bind(this)
        this.setClient=this.setClient.bind(this)
        this.removeItem=this.removeItem.bind(this)
        this.addProduct=this.addProduct.bind(this)
        this.setDesconto=this.setDesconto.bind(this)
        this.emitir=this.emitir.bind(this)
        this.setBank=this.setBank.bind(this)
    }

    emitir(e){
        e.preventDefault();
        console.log(JSON.stringify(this.state.quotation))
        let bodyRequest=this.state.quotation
        bodyRequest.authorizationToken=this.props.token
        fetch (API_URL+"/quotations", {
            method: "POST",
            mode:"cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(bodyRequest)
        })
            .then(response => response.json())
            .then(response => {
                if("quotationId" in response){
                    //add new quote to localStorage
                    let quotations=JSON.parse(localStorage.getItem("quotations"))
                    if(!quotations){
                        quotations=[]
                    }
                    quotations.push(response)
                    localStorage.setItem("quotations",JSON.stringify(quotations))
                    let cotacao=new Quotation()
                    localStorage.setItem("novaCotacao",JSON.stringify(cotacao))
                    //update client balance
                    this.updateTabs(cotacao)
                    this.props.setContent('quoteSent')
                    this.setState({quotation:cotacao})

                }


            })
            .catch(error => {
                console.error('Error:', error)
            });

    }

    saveQuote(cotacao){
        let c={
            itens:[],
            cliente:null,
            clientId:"",
            transportador:"",
            valorTransporte:"",
            desconto:"",
            observacoes:"",
            emissao:"",
            validade:"",
            contaBancaria:"",
            clienteResponsavelPeloFrete:"",
            requestForQuote:"",
            requestId: ""
        }
        try{c.itens=cotacao.getItens()}catch(err){console.log(err)}
        try{c.cliente=new Client(cotacao.getClient())}catch(err){console.log(err)}
        try{c.clientId=cotacao.getClient().getClientId()}catch(err){console.log(err)}
        try{c.transportador=cotacao.getTransportador()}catch(err){console.log(err)}
        try{c.valorTransporte=cotacao.getValorTransporte()}catch(err){console.log(err)}
        try{c.desconto=cotacao.getDesconto()}catch(err){console.log(err)}
        try{c.observacoes=cotacao.getObservacoes()}catch(err){console.log(err)}
        try{c.emissao=cotacao.getEmissao()}catch(err){console.log(err)}
        try{c.validade=cotacao.getValidade()}catch(err){console.log(err)}
        try{c.contaBancaria=cotacao.getContaBancaria()}catch(err){console.log(err)}
        try{c.clienteResponsavelPeloFrete=cotacao.isClientPayingFreight()}catch(err){console.log(err)}
        if("requestForQuote" in cotacao){c.requestForQuote=cotacao.requestForQuote}
        if("requestId" in cotacao){c.requestId=cotacao.requestId}
        if("bank" in cotacao){c.bank=cotacao.bank}
        localStorage.setItem("novaCotacao",JSON.stringify(c))
    }
    static retrieveSavedQuote(){
        let savedQuote=JSON.parse(localStorage.getItem("novaCotacao"))
        if(!savedQuote)
        {
            return new Quotation()
        }
        let c=new Quotation()
        c.parseQuote(savedQuote)
        return c;
    }
    // removeItem(e,productId){
    //     e.preventDefault();
    //     let cotacao=new Quotation();
    //     cotacao.copy(this.state.quotation)
    //     cotacao.removeItem(productId)
    //     this.updateTabs(cotacao)
    //
    // }
    removeItem(e,item){
        e.preventDefault();
        let cotacao=new Quotation();
        cotacao.copy(this.state.quotation)
        cotacao.removeSpecificItem(item)
        this.updateTabs(cotacao)

    }

    addProduct(product,quantity){
        let item=new Item(product,quantity)
        let cotacao=new Quotation();
        cotacao.copy(this.state.quotation)
        cotacao.addItem(item)
        this.updateTabs(cotacao)
    }

    setDesconto(desconto){
        let cotacao=new Quotation();
        cotacao.copy(this.state.quotation);
        cotacao.setDesconto(desconto)
        this.updateTabs(cotacao)
    }

    updateTabs(quotation){
        let listaDeClientes=null
        try{
            listaDeClientes=this.props.listaDeClientes;
        }catch(err){
            if(!listaDeClientes){
                listaDeClientes=new ListaDeClientes()
            }

        }
        let estoque=null;
        try{
            estoque=this.props.estoque
        }catch(err){
            if(!estoque){
                estoque=new Stock()
            }
        }
        this.setState(
            {
                tabs:[
                    {
                        content:'',
                        label: <FaHome onClick={(e)=>{
                            e.preventDefault();
                            this.props.setContent(e,"")
                        }}/>,
                        selected:false
                    },
                    {
                        content:<QuoteClient
                            banksList={this.props.banksList}
                            listaDeClientes={listaDeClientes}
                            setClient={this.setClient}
                            setBank={this.setBank}
                            cotacao={quotation}
                            options={this.props.clientOptions}
                        />,
                        label: 'CLIENT',
                        selected:false
                    },
                    {
                        content:<QuoteProducts
                            stock={estoque}
                            quotation={quotation}
                            addProduct={this.addProduct}
                            removeItem={this.removeItem}
                            options={(new Stock()).getOptions()}
                        />,
                        label: 'PRODUCTS',
                        selected:false
                    },
                    {
                        content:<QuoteTransport
                                    listaDeTransportadores={new ListaDeTransportadores()}
                                    options={(new ListaDeTransportadores()).getOptions()}
                                    cotacao={quotation}
                                    updateTabs={this.updateTabs}
                        />,
                        label: 'TRANSPORT',
                        selected:false
                    },
                    {
                        content:<QuoteSummary
                            banksList={this.props.banksList}
                            emitir={this.emitir}
                            quotation={quotation}
                            setDesconto={this.setDesconto}
                            listaDeClientes={this.props.listaDeClientes}
                        />,
                        label: 'REVIEW',
                        selected:false
                    }
                    ],
            quotation:quotation})
        this.saveQuote(quotation)
        console.log(quotation)
    }

    componentWillMount(){
        this.updateTabs(this.state.quotation)
    }

    setClient(client){
        let cotacao=this.state.quotation
        cotacao.setClient(client.value)
        //set bank
        if("bank" in client.value){
          cotacao.setBank(client.value.bank)

        }
        this.updateTabs(cotacao)
    }

    setBank(bank){
        let cotacao=this.state.quotation
        cotacao.setBank(bank.value)
        this.updateTabs(cotacao)
    }

    render() {
        return (
                <TabContainer
                    tabs={this.state.tabs}
                    setContent={this.props.setContent}
                    selectedTab="CLIENTS"
                    initialContent={<NewQuotationInstructions/>}
                />

        );
    }
}