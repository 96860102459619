
//to create a Product object as per database data
import Produto from "./Produto";

export default class Stock{

    constructor(produtos){
        this.produtos=null;
        if(produtos){
            this.produtos=produtos;
        }
        if(!this.produtos){
            let produtos=[]
            try{
            let p=JSON.parse(localStorage.getItem("produtos"))
            for(let i=0;i<p.length;i++){
                produtos.push(new Produto(p[i]))
            }}catch(err){
                produtos=[]
            }
            this.produtos=produtos
        }
    }

    changeProductData(product){
        if(product){
            if("productId" in product){
                let currentList=this.produtos
                let newList=[]
                for(let i=0;i<currentList.length;i++){
                    if(currentList[i].productId===product.productId){
                        newList.push(new Produto(product))
                    }
                    else{
                        newList.push(currentList[i])
                    }
                }
                this.produtos=newList
                localStorage.setItem("produtos",JSON.stringify(newList))
            }
        }
    }
    //setters
    addProduct(product){
        let existent=false;
        for(let i=0;i<this.produtos.length;i++){
            let produto=this.produtos[i]
            if(produto.description===product.description){
                existent=true;
                break;
            }
        }
        if(!existent){
            let produtos=this.produtos;
            produtos.push(product)
            this.produtos=produtos
        }
    }
    //getters
    getOptions(){
        let options=[]
        for(let i=0;i<this.produtos.length;i++){
            let produto=this.produtos[i]
            let option={
                value:produto,
                label:produto.getName()+ " - "+produto.getDescription()
            }
            options.push(option)
        }
        return options;
    }

    getProduct(productId){
        for(let i=0;i<this.produtos.length;i++){
            if(this.produtos[i].getProductId()===productId){
                return this.produtos[i]
            }
        }
        return new Produto()
    }

    getProducts(){return this.produtos}

    isInStock(productId){
        for(let i=0;i<this.produtos.length;i++){
            let product=this.produtos[i]
            if(product.productId===productId){
                return true
            }
        }
        return false
    }
}
