//to create a Product object as per database data
import {timeConverter} from "../functions";

export default class Client {

    constructor(cliente) {
        if(cliente){
            if("Balance" in cliente){this.Balance=cliente.Balance}
            if("clientId" in cliente){this.clientId=cliente.clientId}
            if("name" in cliente){this.name=cliente.name}
            if("id" in cliente){this.id=cliente.id}
            if("email" in cliente){this.email=cliente.email}
            if("email2" in cliente){this.email2=cliente.email2}
            if("landlineNumber" in cliente){this.landlineNumber=cliente.landlineNumber}
            if("consigned" in cliente){this.consigned=cliente.consigned}
            if("mobileNumber" in cliente){this.mobileNumber=cliente.mobileNumber}
            if("phone1" in cliente){this.phone1=cliente.phone1}
            if("phone2" in cliente){this.phone2=cliente.phone2}
            if("phone3" in cliente){this.phone3=cliente.phone3}
            if("phone4" in cliente){this.phone4=cliente.phone4}
            if("whatsapp" in cliente){this.whatsapp=cliente.whatsapp}
            if("address" in cliente){this.address=cliente.address}
            if("suburb" in cliente){this.suburb=cliente.suburb}
            if("zipCode" in cliente){this.zipCode=cliente.zipCode}
            if("city" in cliente){this.city=cliente.city}
            if("state" in cliente){this.state=cliente.state}
            if("poBox" in cliente){this.poBox=cliente.poBox}
            if("transactions" in cliente){
                this.transactions=cliente.transactions
            }
            if("bankId" in cliente){this.bankId=cliente.bankId}
            if("bank" in cliente){this.bank=cliente.bank}
        }


    }

    //setters
    //getters
    getName() {
        let name = ""
        if ("name" in this) {
            name = this.name
        }
        return name
    }

    getBalance(){
        if("Balance" in this){
            return this.Balance
        }
        else{
            return 0
        }
    }

    getClientId() {
        if ("clientId" in this) {
            return this.clientId
        }
        return null
    }

    getEmail() {
        if ("email" in this) {
            return this.email
        }
        return ""
    }

    getLandLineNumber() {
        if ("landlineNumber" in this) {
            return this.landlineNumber
        }
        return ""
    }

    getMobileNumber() {
        if ("mobileNumber" in this) {
            return this.mobileNumber
        }
        return ""
    }

    getAddress() {
        if ("address" in this) {
            return this.address
        }
        return ""
    }

    getSuburb() {
        if ("suburb" in this) {
            return this.suburb
        }
        return ""
    }

    getZipCode() {
        if ("zipCode" in this) {
            return this.zipCode
        }
        return ""
    }

    getCity() {
        if ("city" in this) {
            return this.city
        }
        return ""
    }

    getState() {
        if ("state" in this) {
            return this.state
        }
    }

    getPoBox() {
        if ("poBox" in this) {
            return this.poBox
        }
        return ""
    }

    getTransactions(){
        if("transactions" in this){
            let transactions=[]
            for(let i=0;i<this.transactions.length;i++){
                let transaction=this.transactions[i]
                transaction.dateAddedFormatted=timeConverter(transaction.dateAdded)
                transactions.push(transaction)
            }
            return transactions
        }
        return []
    }
}