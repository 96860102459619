import React from 'react';
import {Button} from 'reactstrap'
import config from '../../../config.js'
const API_URL = config.api_endpoint
const plane2 = require('../../../assets/flying2.gif');
const plane3 = require('../../../assets/flying4.gif');
export default class EditClientDetails extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            success:false,
            isUpdating:false,
            hasUpdated:false,
            client:this.props.client,
            name: this.props.client.name,
            consigned: this.props.client.consigned,
            id: this.props.client.id,
            landlineNumber: this.props.client.landlineNumber,
            address: this.props.client.address,
            suburb:this.props.client.suburb,
            zipCode: this.props.client.zipCode,
            city: this.props.client.city,
            state: this.props.client.state,
            stateRegistration: this.props.client.stateRegistration,
            email: this.props.client.email,
            phone1: this.props.client.phone1,
            phone2: this.props.client.phone2,
            phone3: this.props.client.phone3,
            phone4: this.props.client.phone4,
            mobileNumber:this.props.client.mobileNumber,
            poBox: this.props.client.poBox,
            bankPreference:this.props.client.bankPreference,
            email2: this.props.client.email2,
            authorizationToken:this.props.token
        }
        this.changeData=this.changeData.bind(this)
    }
    componentWillMount(){
        if(!this.state.name){
            this.setState({name:"null"})
        }
        if(!this.state.consigned){
            this.setState({consigned:"null"})
        }
        if(!this.state.id){
            this.setState({id:"null"})
        }
        if(!this.state.landlineNumber){
            this.setState({landlineNumber:"null"})
        }
        if(!this.state.address){
            this.setState({address:"null"})
        }
        if(!this.state.suburb){
            this.setState({suburb:"null"})
        }
        if(!this.state.zipCode){
            this.setState({zipCode:"null"})
        }
        if(!this.state.city){
            this.setState({city:"null"})
        }
        if(!this.state.state){
            this.setState({state:"null"})
        }
        if(!this.state.stateRegistration){
            this.setState({stateRegistration:"null"})
        }
        if(!this.state.email){
            this.setState({email:"null"})
        }
        if(!this.state.phone1){
            this.setState({phone1:"null"})
        }
        if(!this.state.phone2){
            this.setState({phone2:"null"})
        }
        if(!this.state.phone3){
            this.setState({phone3:"null"})
        }
        if(!this.state.phone4){
            this.setState({phone4:"null"})
        }
        if(!this.state.mobileNumber){
            this.setState({mobileNumber:"null"})
        }
        if(!this.state.poBox){
            this.setState({poBox:"null"})
        }
        if(!this.state.bankPreference){
            this.setState({bankPreference:"null"})

        }
        if(!this.state.email2){
            this.setState({email2:"null"})
        }
    }
    changeData(e){
        e.preventDefault();
        this.props.toggleEditing(e)
        this.props.toggleIsUpdating(e)
        //to track if parent variables have been toggled

        let client={
            clientId:this.state.client.clientId,
            name: this.state.name,
            consigned: this.state.consigned,
            id: this.state.id,
            landlineNumber: this.state.landlineNumber,
            address: this.state.address,
            suburb:this.state.suburb,
            zipCode: this.state.zipCode,
            city: this.state.city,
            state: this.state.state,
            IE: this.state.IE,
            email: this.state.email,
            phone1: this.state.phone1,
            phone2: this.state.phone2,
            phone3: this.state.phone3,
            phone4: this.state.phone4,
            mobileNumber:this.state.mobileNumber,
            poBox: this.state.poBox,
            bankPreference:this.state.bankPreference,
            email2: this.state.email2
        }
        fetch(API_URL+"/banks/"+this.state.client.clientId, {
            method: "PUT",
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    name: this.state.name,
                    consigned: this.state.consigned,
                    id: this.state.id,
                    landlineNumber: this.state.landlineNumber,
                    address: this.state.address,
                    suburb:this.state.suburb,
                    zipCode: this.state.zipCode,
                    city: this.state.city,
                    state: this.state.state,
                    IE: this.state.IE,
                    email: this.state.email,
                    phone1: this.state.phone1,
                    phone2: this.state.phone2,
                    phone3: this.state.phone3,
                    phone4: this.state.phone4,
                    mobileNumber:this.state.mobileNumber,
                    poBox: this.state.poBox,
                    bankPreference:this.state.bankPreference,
                    email2: this.state.email2
                }
            )
        })
            .then(response => response.json())
            .then(response => {
                if(response==="Client updated"){
                    this.props.updateClientList(client)
                    this.props.toggleUpdated(e)
                    this.props.toggleEditing(e)
                    this.props.toggleIsUpdating(e)

                }

            })
            .catch(error => {
                console.error('Error:', error)
            })
    }


    showDetails(){
        if(this.props.isEditing){
            return "changing client details..."
        }
        if(this.props.client){
            return(
                <div className="right">
                    <p>
                        <span className="clientDetailLabel">
                            Client Id:
                        </span>
                        <span className="editClientDetail">

                            {this.props.client.clientId}
                            </span>
                    </p>
                    <p>
                        <span className="clientDetailLabel">Name: </span>

                        <input
                            className="editClientDetailsInput"
                            placeholder={this.state.name}
                            style={{height: 40, borderColor: 'gray', borderWidth: 1}}
                            onChange={
                                (text) => {
                                    if((text.target.value!==null)&&(text.target.value!=="null")&&(text.target.value!==this.state.name))
                                    {
                                        this.setState(
                                            {
                                                name:text.target.value
                                            }
                                        )
                                    }
                                    else if((text===null)||(text==="null")||(text==="")){
                                        this.setState(
                                            {
                                                name:this.state.client.name
                                            }
                                        )
                                    }

                                }}

                        />
                    </p>
                    <p>
                        <span className="clientDetailLabel">Consigned: </span>
                            <input
                                className="editClientDetailsInput"
                                placeholder={this.state.consigned}
                                style={{height: 40, borderColor: 'gray', borderWidth: 1}}
                                onChange={
                                    (text) => {
                                        if((text.target.value!==null)&&(text.target.value!=="null")&&(text.target.value!==this.state.consigned))
                                        {
                                            this.setState(
                                                {
                                                    consigned:text.target.value
                                                }
                                            )
                                        }
                                        else if((text===null)||(text==="null")||(text==="")){
                                            this.setState(
                                                {
                                                    consigned:this.state.client.consigned
                                                }
                                            )
                                        }

                                    }}

                            />
                    </p>
                    <p>
                        <span className="clientDetailLabel">ID</span>
                        <input
                            className="editClientDetailsInput"
                            placeholder={this.state.id}
                            style={{height: 40, borderColor: 'gray', borderWidth: 1}}
                            onChange={
                                (text) => {
                                    if((text.target.value!==null)&&(text.target.value!=="null")&&(text.target.value!==this.state.id))
                                    {
                                        this.setState(
                                            {
                                                id:text.target.value
                                            }
                                        )
                                    }
                                    else if((text.target.value===null)||(text.target.value==="null")||(text.target.value==="")){
                                        this.setState(
                                            {
                                                id:this.state.client.id
                                            }
                                        )
                                    }

                                }}

                        />
                    </p>
                    <p>
                        <span className="clientDetailLabel">State Registration</span>
                        <input
                            className="editClientDetailsInput"
                            placeholder={this.state.IE}
                            style={{height: 40, borderColor: 'gray', borderWidth: 1}}
                            onChange={
                                (text) => {
                                    if((text.target.value!==null)&&(text.target.value!=="null")&&(text.target.value!==this.state.IE))
                                    {
                                        this.setState(
                                            {
                                                IE:text.target.value
                                            }
                                        )
                                    }
                                    else if((text.target.value===null)||(text.target.value==="null")||(text.target.value==="")){
                                        this.setState(
                                            {
                                                IE:this.state.client.IE
                                            }
                                        )
                                    }

                                }}

                        />

                    </p>                        <hr/>
                    <p>
                        <span className="clientDetailLabel">E-mail:</span>
                        <input
                            className="editClientDetailsInput"
                            placeholder={this.state.email}
                            style={{height: 40, borderColor: 'gray', borderWidth: 1}}
                            onChange={
                                (text) => {
                                    if((text.target.value!==null)&&(text.target.value!=="null")&&(text.target.value!==this.state.email))
                                    {
                                        this.setState(
                                            {
                                                email:text.target.value
                                            }
                                        )
                                    }
                                    else if((text.target.value===null)||(text.target.value==="null")||(text.target.value==="")){
                                        this.setState(
                                            {
                                                email:this.state.client.email
                                            }
                                        )
                                    }

                                }}

                        />
                    </p>
                    <p>
                        <span className="clientDetailLabel">E-mail 2:</span>
                        <input
                            className="editClientDetailsInput"
                            placeholder={this.state.email2}
                            style={{height: 40, borderColor: 'gray', borderWidth: 1}}
                            onChange={
                                (text) => {
                                    if((text.target.value!==null)&&(text.target.value!=="null")&&(text.target.value!==this.state.email2))
                                    {
                                        this.setState(
                                            {
                                                email2:text.target.value
                                            }
                                        )
                                    }
                                    else if((text.target.value===null)||(text.target.value==="null")||(text.target.value==="")){
                                        this.setState(
                                            {
                                                email2:this.state.client.email2
                                            }
                                        )
                                    }

                                }}

                        />
                    </p>
                    <p>
                        <span className="clientDetailLabel">Phone:
                        <input
                            className="editClientDetailsInput"
                            placeholder={this.state.landlineNumber}
                            style={{height: 40, borderColor: 'gray', borderWidth: 1}}
                            onChange={
                                (text) => {
                                    if((text.target.value!==null)&&(text.target.value!=="null")&&(text.target.value!==this.state.landlineNumber))
                                    {
                                        this.setState(
                                            {
                                                landlineNumber:text.target.value
                                            }
                                        )
                                    }
                                    else if((text.target.value===null)||(text.target.value==="null")||(text.target.value==="")){
                                        this.setState(
                                            {
                                                landlineNumber:this.state.client.landlineNumber
                                            }
                                        )
                                    }

                                }}

                        />
                        </span>

                    </p>
                    <p>
                        <span className="clientDetailLabel">Phone 1:</span>
                        <input
                            className="editClientDetailsInput"
                            placeholder={this.state.phone1}
                            style={{height: 40, borderColor: 'grey', borderWidth: 1}}
                            onChange={
                                (text) => {
                                    if((text.target.value!==null)&&(text.target.value!=="null")&&(text.target.value!==this.state.phone1))
                                    {
                                        this.setState(
                                            {
                                                phone1:text.target.value
                                            }
                                        )
                                    }
                                    else if((text.target.value===null)||(text.target.value==="null")||(text.target.value==="")){
                                        this.setState(
                                            {
                                                phone1:this.state.client.phone1
                                            }
                                        )
                                    }

                                }}

                        />
                    </p>
                    <p>
                        <span className="clientDetailLabel">Phone 2:</span>
                        <input
                            className="editClientDetailsInput"
                            placeholder={this.state.phone2}
                            style={{height: 40, borderColor: 'grey', borderWidth: 1}}
                            onChange={
                                (text) => {
                                    if((text.target.value!==null)&&(text.target.value!=="null")&&(text.target.value!==this.state.phone2))
                                    {
                                        this.setState(
                                            {
                                                phone2:text.target.value
                                            }
                                        )
                                    }
                                    else if((text.target.value===null)||(text.target.value==="null")||(text.target.value==="")){
                                        this.setState(
                                            {
                                                phone2:this.state.client.phone2
                                            }
                                        )
                                    }

                                }}

                        />
                    </p>
                    <p>
                        <span className="clientDetailLabel">Phone 3:</span>
                        <input
                            className="editClientDetailsInput"
                            placeholder={this.state.phone3}
                            style={{height: 40, borderColor: 'grey', borderWidth: 1}}
                            onChange={
                                (text) => {
                                    if((text.target.value!==null)&&(text.target.value!=="null")&&(text.target.value!==this.state.phone3))
                                    {
                                        this.setState(
                                            {
                                                phone3:text.target.value
                                            }
                                        )
                                    }
                                    else if((text.target.value===null)||(text.target.value==="null")||(text.target.value==="")){
                                        this.setState(
                                            {
                                                phone3:this.state.client.phone3
                                            }
                                        )
                                    }

                                }}

                        />
                    </p>
                    <p>
                        <span className="clientDetailLabel">Phone 4:</span>
                        <input
                            className="editClientDetailsInput"
                            placeholder={this.state.phone4}
                            style={{height: 40, borderColor: 'grey', borderWidth: 1}}
                            onChange={
                                (text) => {
                                    if((text.target.value!==null)&&(text.target.value!=="null")&&(text.target.value!==this.state.phone4))
                                    {
                                        this.setState(
                                            {
                                                phone4:text.target.value
                                            }
                                        )
                                    }
                                    else if((text.target.value===null)||(text.target.value==="null")||(text.target.value==="")){
                                        this.setState(
                                            {
                                                phone4:this.state.client.phone4
                                            }
                                        )
                                    }

                                }}

                        />
                    </p>
                    <p>
                        <span className="clientDetailLabel">Mobile :</span>
                        <input
                            className="editClientDetailsInput"
                            placeholder={this.state.mobileNumber}
                            style={{height: 40, borderColor: 'grey', borderWidth: 1}}
                            onChange={
                                (text) => {
                                    if((text.target.value!==null)&&(text.target.value!=="null")&&(text.target.value!==this.state.mobileNumber))
                                    {
                                        this.setState(
                                            {
                                                mobileNumber:text.target.value
                                            }
                                        )
                                    }
                                    else if((text.target.value===null)||(text.target.value==="null")||(text.target.value==="")){
                                        this.setState(
                                            {
                                                mobileNumber:this.state.client.mobileNumber
                                            }
                                        )
                                    }

                                }}

                        />

                    </p><hr/>
                    <p>
                        <span className="clientDetailLabel">Address: </span>
                        <input
                            className="editClientDetailsInput"
                            placeholder={this.state.address}
                            style={{height: 40, borderColor: 'gray', borderWidth: 1}}
                            onChange={
                                (text) => {
                                    if(
                                        (text.target.value!==null)
                                        &&(text.target.value!=="null")
                                        &&(text.target.value!==this.state.address)
                                        &&(text.target.value!=="")
                                    )
                                    {
                                        this.setState(
                                            {
                                                address:text.target.value
                                            }
                                        )
                                    }
                                    else {
                                        this.setState(
                                            {
                                                address:this.props.client.address
                                            }
                                        )
                                    }

                                }
                            }

                        />
                    </p>
                    <p>
                        <span className="clientDetailLabel">Suburb:</span>
                        <input
                            className="editClientDetailsInput"
                            placeholder={this.state.suburb}
                            style={{height: 40, borderColor: 'gray', borderWidth: 1}}
                            onChange={
                                (text) => {
                                    if((text.target.value!==null)&&(text.target.value!=="null")&&(text.target.value!==this.state.suburb))
                                    {
                                        this.setState(
                                            {
                                                suburb:text.target.value
                                            }
                                        )
                                    }
                                    else if((text.target.value===null)||(text.target.value==="null")||(text.target.value==="")){
                                        this.setState(
                                            {
                                                suburb:this.state.client.suburb
                                            }
                                        )
                                    }

                                }}

                        />
                    </p>
                    <p>
                        <span className="clientDetailLabel">City:</span>
                        <input
                            className="editClientDetailsInput"
                            placeholder={this.state.city}
                            style={{height: 40, borderColor: 'gray', borderWidth: 1}}
                            onChange={
                                (text) => {
                                    if((text.target.value!==null)&&(text.target.value!=="null")&&(text.target.value!==this.state.city))
                                    {
                                        this.setState(
                                            {
                                                city:text.target.value
                                            }
                                        )
                                    }
                                    else if((text.target.value===null)||(text.target.value==="null")||(text.target.value==="")){
                                        this.setState(
                                            {
                                                city:this.state.client.city
                                            }
                                        )
                                    }

                                }}

                        />
                    </p>
                    <p>
                        <span className="clientDetailLabel">State:</span>
                        <input
                            className="editClientDetailsInput"
                            placeholder={this.state.state}
                            style={{height: 40, borderColor: 'gray', borderWidth: 1}}
                            onChange={
                                (text) => {
                                    if((text.target.value!==null)&&(text.target.value!=="null")&&(text.target.value!==this.state.state))
                                    {
                                        this.setState(
                                            {
                                                state:text.target.value
                                            }
                                        )
                                    }
                                    else if((text.target.value===null)||(text.target.value==="null")||(text.target.value==="")){
                                        this.setState(
                                            {
                                                state:this.state.client.state
                                            }
                                        )
                                    }

                                }}

                        />
                    </p>
                    <p>
                        <span className="clientDetailLabel">Zip code:</span>
                        <input
                            className="editClientDetailsInput"
                            placeholder={this.state.zipCode}
                            style={{height: 40, borderColor: 'gray', borderWidth: 1}}
                            onChange={
                                (text) => {
                                    if((text.target.value!==null)&&(text.target.value!=="null")&&(text.target.value!==this.state.zipCode))
                                    {
                                        this.setState(
                                            {
                                                zipCode:text.target.value
                                            }
                                        )
                                    }
                                    else if((text.target.value===null)||(text.target.value==="null")||(text.target.value==="")){
                                        this.setState(
                                            {
                                                zipCode:this.state.client.zipCode
                                            }
                                        )
                                    }

                                }}

                        />
                    </p>
                    <p>
                        <span className="clientDetailLabel">PO Box: </span>
                        <input
                            className="editClientDetailsInput"
                            placeholder={this.state.poBox}
                            style={{height: 40, borderColor: 'gray', borderWidth: 1}}
                            onChange={
                                (text) => {
                                    if((text.target.value!==null)&&(text.target.value!=="null")&&(text.target.value!==this.state.poBox))
                                    {
                                        this.setState(
                                            {
                                                poBox:text.target.value
                                            }
                                        )
                                    }
                                    else if((text.target.value===null)||(text.target.value==="null")||(text.target.value==="")){
                                        this.setState(
                                            {
                                                poBox:this.state.client.poBox
                                            }
                                        )
                                    }

                                }}

                        />
                    </p>
                    <div className="buttonWrapper">
                        <Button
                            onClick={
                                (e)=>
                                {
                                    e.preventDefault()
                                    this.setState({isUpdating:true});
                                    this.changeData(e);
                                    }
                            }
                        >Alterar</Button>
                    </div>

                </div>
            )
        }
        else{
            return ""
        }
    }

    render() {
        if(this.props.isUpdating){
            return (
                <div className="right">
                    <div className="updating">
                        <h3>Changing client information</h3>
                        <img
                            src={plane2}
                            aria-hidden
                            alt="background image"
                        />
                        <p>
                            Please wait!
                        </p>
                    </div>
                </div>
            )
        }
        if(this.props.updated){
            return(
                <div className="right">
                    <div className="updating">
                        <h3>Data updated.</h3>
                        <img
                            src={plane3}
                            aria-hidden
                            alt="background image"
                        />
                        <p>
                            Thank you!
                        </p>
                    </div></div>
            )
        }


            return (
                <div>
                    <span>
                    {this.showDetails()}
                    </span>

                </div>
            )


    }
}