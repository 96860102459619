import React from 'react';
import '../../css/transactionHistory.css'
import Select from 'react-select';
import {sortByKey, timeConverter} from "../../functions";
import Modal from "react-responsive-modal";

export default class Transactions extends React.Component {

    constructor(props){
        super(props)
        this.state={
            selectedClientId:null,
            shippingInformation:null,
            paymentInformation:null
        }
        this.showBalance=this.showBalance.bind(this)
        this.closePaymentModal=this.closePaymentModal.bind(this)
        this.closeShippingModal=this.closeShippingModal.bind(this)
        this.showShippingInformation=this.showShippingInformation.bind(this)
        this.showAllocations=this.showAllocations.bind(this)
    }

    closeShippingModal(e){
        e.preventDefault()
        this.setState({shippingInformation:null})
    }
    closePaymentModal(e){
        e.preventDefault()
        this.setState({paymentInformation:null})
    }

    showAllocations(){
        if(this.state.paymentInformation){
            let a=this.state.paymentInformation.allocations
            let allocations=[]
            for (let key in a) {
                if (a.hasOwnProperty(key)) {
                    allocations.push({
                        "quotationId":key,
                        "amount": (a[key])
                    })
                    console.log(key + " -> " + a[key]);
                }
            }
            if(allocations){
                let allocationRows=allocations.map(function(allocation,i){
                    return(
                        <tr key={i}>
                            <td>{allocation.quotationId}</td>
                            <td>{(allocation.amount*1).toFixed(2)}</td>
                        </tr>
                    )
                },this)
                return(
                    <div className="paymentDetailsContainer">
                        <h5>Alocações</h5>
                        <p>Abaixo o descritivo de quantias / faturas para as quais este pagamento foi realizado</p>
                        <table>
                            <tbody>
                            <tr>
                                <th>Cotação #</th>
                                <th>Quantia R$</th>
                            </tr>
                            {allocationRows}
                            </tbody>
                        </table>
                    </div>
                )
            }

        }
    }

    handleChange = (selectedOption) => {
        console.log(selectedOption)
        this.setState({ selectedOption });
        try{
            this.setState({
                selectedClientId:selectedOption.value.clientId
            })
            console.log(selectedOption.value.clientId)
        }
        catch(error){console.log(error)}



    }

    showBalance(){
        if(this.state.selectedClientId){
            let client=this.props.listaDeClientes.getClient(this.state.selectedClientId)
            if(client){
                if("transactions" in client){
                    if(client.transactions){
                        console.log('client transactions:')
                        console.log(client.transactions)
                        let transactions=sortByKey(client.getTransactions(),"dateAdded")
                        let component= transactions.map(function(transaction,i){
                            if(transaction.type==='debit'){
                                    return(
                                        <tr key={i}>
                                            <td>{transaction.dateAddedFormatted}</td>
                                            <td>{transaction.type}</td>
                                            <td className="creditAmount">{(transaction.amount*1).toFixed(2)}</td>
                                            <td>
                                                <a
                                                    href="/#"
                                                    onClick={(e)=>
                                                    {
                                                        e.preventDefault()
                                                        this.setState({
                                                            shippingInfoModal:true,
                                                            shippingInformation:transaction
                                                        })
                                                    }}
                                                >
                                                    Cotação #: {transaction.quotationId} / Fatura #: {transaction.invoiceId}
                                                </a>
                                                </td>

                                        </tr>
                                    )
                                }
                                else{
                                return(
                                    <tr key={i}>
                                        <td>{transaction.dateAddedFormatted}</td>
                                        <td>credit</td>
                                        <td className="debitAmount" >
                                                -{(transaction.amount*1).toFixed(2)}
                                                </td>
                                        <td>

                                            <a
                                                href="/#"
                                                onClick={(e)=>
                                                {
                                                    e.preventDefault()
                                                    this.setState({
                                                        paymentInformation:transaction
                                                    })
                                                }}
                                            >
                                                {transaction.reference}
                                            </a>



                                            </td>
                                    </tr>
                                )
                                }
                            },this)


                        return (
                            <div>
                                <p className="currentBalance">Saldo atual: {parseFloat(client.Balance).toFixed(2)}</p>
                                <table>
                                    <tbody>
                                    <tr>
                                        <th>Data incluída</th>
                                        <th>Tipo</th>
                                        <th>Valor</th>
                                        <th>Referência</th>
                                    </tr>
                                    {component}
                                    </tbody>
                                </table>
                            </div>
                        )
                        }

                    }
                }
            }
    }

    showShippingInformation(){
        let info=this.state.shippingInformation
        if(info){
            return(<div>
                <table>
                    <tbody>
                    <tr>
                        <th>Data do envio</th>
                        <th>Hora do envio</th>
                        <th>Cotação #</th>
                        <th>Fatura #</th>
                        <th>Código de rastreio</th>
                        <th>Data de inclusão</th>
                    </tr>
                    <tr>
                        <td>{info.shippingDate}</td>
                        <td>{info.shippingTime}</td>
                        <td>{info.quotationId}</td>
                        <td>{info.invoiceId}</td>
                        <td>{info.trackingNumber}</td>
                        <td>{timeConverter(info.dateAdded)}</td>
                    </tr>
                    </tbody>
                </table>
            </div>)
        }

    }


    render() {
        return (
            <div className="transactionsOutterContainer">
                <Select
                    onChange={this.handleChange}
                    options={this.props.listaDeClientes.getOptions()}
                />
                {this.showBalance()}


                <Modal
                    open={(this.state.shippingInformation!==null)}
                    onClose={this.closeShippingModal}>
                    {this.showShippingInformation()}
                </Modal>

                <Modal
                    open={(this.state.paymentInformation!==null)}
                    onClose={this.closePaymentModal}
                >
                    {this.showAllocations()}
                </Modal>


            </div>
        )
    }
}


