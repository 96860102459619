import React from 'react';
import Select from 'react-select';
import '../../css/App.css';
import {FaEdit, FaHome} from "react-icons/fa";
import {TiUserAdd} from 'react-icons/ti'
import TransportadoresDetails from "./TransportadoresDetails";
import NewTransportadorForm from "./NewTransportadorForm";
import FreightCompanyUpdatingWindow from "./miscelaneous/FreightCompanyUpdatingWindow";
export default class TransportadoresScreen extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            selectedOption: null,
            selectedFreightCompany:null,
            selectedFreightCompanyId:null,
            freightCompanies: this.props.listaDeTransportadores.getTransportadores(),
            isEditing:false,
            isUpdating:false,
            options:'',
            updated:false,
            isNewFreightCompany:false,
            isMounted:false,
            leftMaximised:true,
            newFreightCompanyAdded:false
        }
        this.toggleEditing=this.toggleEditing.bind(this)
        this.toggleIsUpdating=this.toggleIsUpdating.bind(this)
        this.toggleUpdated=this.toggleUpdated.bind(this)
        this.showCard=this.showCard.bind(this)
        this.newFreightCompanyAdded=this.newFreightCompanyAdded.bind(this)
    }

    newFreightCompanyAdded(e){
        e.preventDefault()
        this.setState({newFreightCompanyAdded:true})
    }

    toggleIsUpdating(e){
        e.preventDefault()
        this.setState({isUpdating:!this.state.isUpdating})
    }
    toggleUpdated(e){
        e.preventDefault()
        this.setState(
            {
                updated:!this.state.updated,
                isUpdating:false})
    }


    toggleEditing(e){
        e.preventDefault()
        if(!this.state.isEditing){
            this.setState({leftMaximised:false})
        }
        console.log(this.state.isEditing)
        this.setState({
            isEditing:!this.state.isEditing,
            isNewFreightCompany:false
        })
    }


    handleChange = (selectedOption) => {
        console.log(selectedOption)
        this.setState({ selectedOption });
        try{
            this.setState({
                isEditing:false,
                isNewFreightCompany:false,
                updated:false,
                leftMaximised:false,
                selectedFreightCompanyId:selectedOption.value.freightCompanyId,
                selectedFreightCompany:selectedOption.value
            })
        }
        catch(error){console.log(error)}



    }

    setOptions(){
        this.setState({
            isEditing:false
        })
        let freightCompanies=this.state.freightCompanies
        //make sure freightCompanies is not null if loading from this component..
        if(freightCompanies.length<1){
            freightCompanies=JSON.parse(localStorage.getItem("transportadores"))
        }
        let options=[]
        for(let i=0;i<freightCompanies.length;i++){
            let value=freightCompanies[i]
            let label=freightCompanies[i].Nome
            options.push({
                value:value,
                label:label.toString()
            })
        }
        this.setState({
            options:options
        })
        this.setState({
            isMounted:true
        })
    }

    componentWillMount(){
        localStorage.setItem("content","transportadores")

    }

    showDetails(){
        if(this.state.selectedOption){
            return(
                <TransportadoresDetails
                    freightCompany={this.state.selectedOption.value}
                />
            )
        }
        else{
            return ""
        }
    }

    showIcons(){
        if(this.state.selectedOption!==null){
            return(
                <div className="toolBar">
                    <span className="icone">
                        <button
                            onClick={()=>{
                                this.props.setContent('home')
                            }}>
                            <FaHome/>
                        </button>
                    </span>
                    <span className="icone">
                        <button
                            onClick={()=>{
                                this.setState({
                                    isNewFreightCompany:!this.state.isNewFreightCompany,
                                    isEditting:false
                                })
                            }}
                        >
                            <TiUserAdd/>
                        </button>
                    </span>
                    <span className="icone">
                        <button onClick={(e)=>this.toggleEditing(e)} >
                            <FaEdit onClick={(e)=>this.toggleEditing(e)}/>
                        </button>
                    </span>
                </div>
            )
        }
        else{
            return(
                <div className="toolBar">
                    <span className="icone">
                        <button
                            onClick={(e)=>{
                                this.props.setContent(e,"")

                            }}>
                            <FaHome/>
                        </button>
                    </span>
                    <span  className="icone">
                        <button
                            onClick={()=>{
                                this.setState({
                                    isNewFreightCompany:!this.state.isNewFreightCompany,
                                    isEditting:false
                                })
                            }}
                        >
                            <TiUserAdd/>
                        </button>
                    </span>
                </div>
            )
        }
    }

    showEdit(){
        if(this.state.selectedOption!==null){
            return(

                <button
                    onClick={(e)=>this.toggleEditing(e)}
                >
                    <FaEdit onClick={(e)=>this.toggleEditing(e)}/>
                </button>
            )
        }
    }

    showWithLeftMaximised(){

        return (
            <div className="clientContainer">

                {this.showIcons()}
                <span className="left">
                        <div>
                            <Select
                                classNamePrefix={"leftContent"}
                                placeholder="Select a freight company"
                                onChange={this.handleChange}
                                label="Single select"
                                options={this.props.listaDeTransportadores.getOptions()}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 15,
                                    minMenuHeight:100,
                                    colors: {
                                        ...theme.colors,
                                        text: 'orangered',
                                        primary25: 'pink',
                                        primary: 'black',
                                        borderRadius:15
                                    },
                                })
                                }
                            />
                        </div>
                    </span>
                <span>
                    {this.showCard()}
                    </span>
            </div>

        );
    }
    showCard(){
        if(this.state.isNewFreightCompany)
        {
            return (
                <span className={"right"}>
                        <NewTransportadorForm
                            token={this.props.token}
                            newFreightCompanyAdded={this.newFreightCompanyAdded}
                            setContent={this.props.setContent}
                            updateFreightCompanies={this.props.updateFreightCompanies}
                            toggleIsUpdating={this.toggleIsUpdating}
                            toggleUpdated={this.toggleUpdated}
                        />
                    </span>
            )
        }
        if(this.state.isEditing){
            return (
                <span>
                "Edit feature unvailable for freight companies yet"
                </span>
            )
        }
        else{
            return this.showDetails()
        }
    }

    render() {
        if(this.state.updated){
            return(
                <FreightCompanyUpdatingWindow headerType="updated"/>
            )
        }
        if(this.state.isUpdating){
            return(
                <FreightCompanyUpdatingWindow headerType="updating"/>
            )
        }
        if(this.state.newFreightCompanyAdded){
            return "Freight company added"
        }
        return this.showWithLeftMaximised()

    }
}