import React from 'react';
import '../../css/visualizarCotacoes.css'
import TabContainer from "../../ui_components/TabContainer";

import { getReadableDateTime } from "../../functions";

export default class PaymentsPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state={
        }
        this.refresh=this.refresh.bind(this)
    }

    refresh(e){
        e.preventDefault();
        window.location.reload();
    }
    componentWillMount(){
        this.props.updateTabs()
    }


    //remember to sync localStorage with server responses

    render() {
        var currentdate = new Date().getTime();

        console.log(currentdate)
        let readableDate=getReadableDateTime(currentdate)
        console.log(readableDate)
        console.log(this.props.quotations)
        return(
            <div className="visualizarCotacoes">
                <TabContainer
                    tabs={this.props.tabs}
                    setContent={this.props.setContent}
                />
            </div>
        )
    }
}