import React from 'react';
import '../../css/productList.css';
import {FaTrash} from "react-icons/fa";

export default class ProductList extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            produtos:this.props.produtos
        }

    }


    componentWillMount(){

    }

    render() {
        return (
            <div className="productsContainer">
                <table>
                    <tr>
                        <th>Product ID</th>
                        <th>Description</th>
                        <th>UN</th>
                        <th>Qtd</th>
                        <th>Price ($/un)</th>
                        <th>Total</th>
                        <th></th>
                    </tr>
                    {
                        this.props.produtos.map(function(item, i){
                            return(
                                <tr key={i}>
                                    <td>{item.productId}</td>
                                    <td>{this.props.stock.getProduct(item.productId).name}</td>
                                    <td>{item.unity}</td>
                                    <td>{item.quantity}</td>
                                    <td>{item.price}</td>
                                    <td>{item.price*item.quantity}</td>
                                    <td>
                                        <FaTrash
                                            onClick={(e)=>this.props.deleteProduct(e,item.productId)}
                                        />
                                    </td>
                                </tr>
                            )
                        },this)
                    }
                </table>
            </div>
        )
    }

}