import Client from "./Client";
import {sortByKey} from "../functions";
//to create a Product object as per database data
export default class ListaDeClientes {

    constructor(clients){
        this.clients=[]
        if(clients){
            let c=[]
            for(let i=0;i<clients.length;i++){
                c.push(new Client(clients[i]))
            }
            this.clients=c
        }
        if(this.clients.length===0){
            let c=JSON.parse(localStorage.getItem("clients"))
            if(c){
                let temp=[]
                for(let i=0;i<c.length;i++){
                    temp.push(new Client(c[i]))
                }
                this.clients=c
            }
        }
        this.clients=sortByKey(this.clients,"name")

    }
    addClients(clients){
        for(let i=0;i<clients.length;i++){
            this.addClient(clients[i])
        }
    }
    //setters
    addClient(client){
        //validate mandatory fields before adding
        if(!("clientId" in client)){return}
        if(!("name" in client)){return}
        let existent=false;
        for(let i=0; i<this.clients.length; i++){
            let tmp=this.clients[i]
            if(tmp.clientId===client.clientId){
                existent=true;
                break;
            }
        }
        if(!existent){
            let clients=this.clients;
            clients.push(new Client(client))
            this.clients=clients
            localStorage.setItem("clients",JSON.stringify(clients))
        }
    }
    //getters
    getOptions(){
        let options=[]
        for(let i=0; i<this.clients.length; i++){
            try{
            let client=new Client(this.clients[i])
            let option={
                value:client,
                label:client.name
            }
            options.push(option)
            }
            catch(err){
                console.log(err)
            }
        }
        return options;
    }
    getClient(clientId){
        if(!clientId){
            return 0
        }
        let client=new Client()
        for(let i=0; i<this.clients.length; i++){
            if(this.clients[i].clientId===clientId){
                return new Client(this.clients[i])
            }
        }
        return client
    }
    getClients(){
        return this.clients
    }

    getClientByEmail(email){
        for(let i=0; i<this.clients.length; i++){
            if(this.clients[i].email===email){
                return new Client(this.clients[i])
            }
        }
            return null

    }

    getBalance(clientId){
        if(!clientId){
            return 0
        }
        for(let i=0; i<this.clients.length; i++){
            if(this.clients[i].clientId===clientId){
                if("Balance" in this.clients[i]){
                    return this.clients[i].Balance
                }
            }
        }
        return 0
    }

    changeClientData(client){
        if(client){
            if("clientId" in client){
                let currentList=this.clients
                let newList=[]
                for(let i=0;i<currentList.length;i++){
                    if(currentList[i].clientId===client.clientId){
                        newList.push(new Client(client))
                    }
                    else{
                        newList.push(currentList[i])
                    }
                }
                this.clients=newList
                localStorage.setItem("clients",JSON.stringify(newList))
            }
        }
    }

    getClientBalance(clientId){
        for(let i=0;i<this.clients.length;i++){
            if(this.clients[i].clientId===clientId){
                return this.clients[i].Balance
            }
        }
    }

}
