import React from 'react';
import {FaTrash, FaPlusCircle} from "react-icons/fa";
import '../../../css/productList.css'
import Modal from "react-responsive-modal";
export default class QuotationDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            openModal:false,
            itemToDelete:''
        }
        this.removeItem=this.removeItem.bind(this)
        this.getProductDescription=this.getProductDescription.bind(this)
    }

    removeItem(){
        this.setState({openModal:true})
        //this.props.removeItem(item.getProductId())
    }

    getProductDescription(e,item){
        e.preventDefault()
        let productId = item.getProductId()
        let products=this.props.stock.produtos
        for(let i=0;i<products.length;i++){
            if(productId===products[i].productId){
                return products[i].description
            }
        }
        return ""
    }

    //remember to sync localStorage with server responses

    render() {
        return (
            <div className="dadosProdutoCotacao">
                <table>
                    <tbody>
                    <tr>
                        <th>ITEM</th>
                        <th>DESCRIÇÃO</th>
                        <th>UN</th>
                        <th>PESO/UN (kg)</th>
                        <th>Preço (R$/un)</th>
                        <th>Qtd</th>
                        <th>Peso total (kg)</th>
                        <th>TOTAL - R$</th>
                        <th
                            className="addProductIcon"
                            onClick={this.props.openModal}>
                            <FaPlusCircle/>
                        </th>
                    </tr>
                    {this.props.quotation.getItens().map(function(item, i){
                        return(
                            <tr key={i}>
                                <td>{i+1}</td>
                                <td>{item.getProduto().getNameAndDescription()}</td>
                                <td>{item.getProduto().getUnity()}</td>
                                <td>{item.getProduto().getKgPerUnit().toFixed(2)}</td>
                                <td>{item.getProduto().getPrice().toFixed(2)}</td>
                                <td>{item.getQuantity().toFixed(2)}</td>
                                <td>{item.getWeight().toFixed(2)}</td>
                                <td>{item.getSubtotal().toFixed(2)}</td>
                                <td className="trashIcon">
                                    <FaTrash
                                        onClick={
                                            ()=>{

                                                this.setState({
                                                    itemToDelete:item,
                                                openModal:true})
                                            }

                                        }
                                    />
                                </td>
                            </tr>
                        )
                    },this)}
                    <tr>
                        <td className="addProductIcon"
                            onClick={this.props.openModal}><FaPlusCircle/></td>
                        <td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                    </tr>
                    </tbody>
                </table>
                <br/>
                <div>SUBTOTAL DOS PRODUTOS: {this.props.quotation.getSubtotalDosProdutos().toFixed(2)}</div>
                <Modal
                    open={this.state.openModal}
                    onClose={()=>{
                        this.setState({openModal:false})
                    }}>
                    <h2>Tem certeza que quer deletar? </h2>
                    <div className="buttonWrapper2">
                    <button
                        className="adicionarProduto"
                        onClick={(e)=>{
                            e.preventDefault()
                            this.props.removeItem(e,this.state.itemToDelete)
                            this.setState({openModal:false})
                        }}>
                        YES
                    </button>

                    <button
                        className="adicionarProduto"
                        onClick={
                            ()=>{
                                this.setState({openModal:false})
                            }
                        }
                    >
                        NO
                    </button>
                    </div>
                </Modal>

            </div>
        )
    }
}