import BankAccount from "./BankAccount";
import {sortByKey} from "../functions";

export default class BankAccounts {

    constructor(banks){
        this.banks=[]
        if(banks){
            let c=[]
            for(let i=0;i<banks.length;i++){
                c.push(new BankAccount(banks[i]))
            }
            this.banks=c
        }
        if(this.banks.length===0){
            let c=JSON.parse(localStorage.getItem("banks"))
            if(c){
                let temp=[]
                for(let i=0;i<c.length;i++){
                    temp.push(new BankAccount(c[i]))
                }
                this.banks=c
            }
        }
        this.banks=sortByKey(this.banks,"bankName")

    }
    addBanks(banks){
        for(let i=0;i<banks.length;i++){
            this.addBank(banks[i])
        }
    }
    //setters
    addBank(bank){
        //validate mandatory fields before adding
        if(!("bankId" in bank)){return}
        if(!("bankName" in bank)){return}
        let existent=false;
        for(let i=0; i<this.banks.length; i++){
            let tmp=this.banks[i]
            if(tmp.bankId===bank.bankId){
                existent=true;
                break;
            }
        }
        if(!existent){
            let banks=this.banks;
            banks.push(new BankAccount(bank))
            this.banks=banks
            localStorage.setItem("banks",JSON.stringify(banks))
        }
    }
    //getters
    getOptions(){
        let options=[]
        for(let i=0; i<this.banks.length; i++){
            try{
                let bank=new BankAccount(this.banks[i])
                let option={
                    value:bank,
                    label:bank.accountHolder + " - "+bank.bankName+ " - "+bank.branch+"/"+bank.accountNumber
                }
                options.push(option)
            }
            catch(err){
                console.log(err)
            }
        }
        return options;
    }
    getBank(bankId){
        if(!bankId){
            return 0
        }
        let bank=new BankAccount()
        for(let i=0; i<this.banks.length; i++){
            if(this.banks[i].bankId===bankId){
                return new BankAccount(this.banks[i])
            }
        }
        return bank
    }
    getBanks(){
        return this.banks
    }


}
