export default class Fornecedor{

    constructor (supplier){
        if("supplierId" in supplier){this.supplierId=supplier.supplierId}
        if("Balance" in supplier){this.Balance=supplier.Balance}
        if("clientId" in supplier){this.clientId=supplier.clientId}
        if("name" in supplier){this.name=supplier.name}
        if("id" in supplier){this.id=supplier.id}
        if("email" in supplier){this.email=supplier.email}
        if("email2" in supplier){this.email2=supplier.email2}
        if("landlineNumber" in supplier){this.landlineNumber=supplier.landlineNumber}
        if("consigned" in supplier){this.consigned=supplier.consigned}
        if("mobileNumber" in supplier){this.mobileNumber=supplier.mobileNumber}
        if("phone1" in supplier){this.phone1=supplier.phone1}
        if("phone2" in supplier){this.phone2=supplier.phone2}
        if("phone3" in supplier){this.phone3=supplier.phone3}
        if("phone4" in supplier){this.phone4=supplier.phone4}
        if("address" in supplier){this.address=supplier.address}
        if("suburb" in supplier){this.suburb=supplier.suburb}
        if("zipCode" in supplier){this.zipCode=supplier.zipCode}
        if("city" in supplier){this.city=supplier.city}
        if("state" in supplier){this.state=supplier.state}
        if("poBox" in supplier){this.poBox=supplier.poBox}
        if("transactions" in supplier){
            this.transactions=supplier.transactions
        }
    }


    setSupplierId(supplierId){
        this.supplierId=supplierId
    }

    getSupplierId(){
        return this.supplierId;
    }
    getName(){return this.name};
    getNome(){return this.Nome};
    getContato(){return this.contato}
    getEndereco(){return this.endereco}
    getBairro(){return this.bairro}
    getCep(){return this.cep}
    getEstado(){return this.estado}
    getCpf(){return this.cpf}
    getCnpj(){return this.cnpj}
    getCaixaPostal(){return this.caixaPostal}
    getCelular(){return this.celular}
    getEmail(){return this.email}
    getEmail2(){return this.email2}
    getIe(){return this.ie}

}