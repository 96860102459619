import React from 'react';
import {sortByKey, timeConverter} from "../../functions";
import {FaPlusCircle} from "react-icons/fa";
import Note from "./Note";
import Modal from "react-responsive-modal";
import QuotationDetails from "./QuotationDetails";
import '../../css/unpaidInvoicesQuotationDetails.css'


export default class InvoicesDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            unpaidInvoices:{},
            quotationModalOpen:false,
            quotationDetailForModal:'',
            quotations:this.props.quotations

        }
        this.showNotes=this.showNotes.bind(this)
        this.showDetails=this.showDetails.bind(this)
        this.showInvoices=this.showInvoices.bind(this)
    }


    componentWillMount(){
    }

    showDetails(){
        return(
            <div>
            <table>
                <tbody>
                    <tr>
                        <th>Registro</th>
                        <td>{this.props.invoice.client.clientId}</td>
                        <th>Nome</th>
                        <td>{this.props.invoice.client.name}</td>
                        <th>Saldo</th>
                        <td>R$ {(this.props.invoice.client.Balance*1).toFixed(2)}</td>
                    </tr>
                </tbody>
            </table>
                <br/>
            <table>
                <tbody>
                    <tr>
                        <th>Código Fatura</th>
                        <th>Cotação #</th>
                        <th>Total fatura</th>
                        <th>Saldo devedor</th>
                        <th>Emitida em</th>
                        <th>Vencimento</th>
                        <th>Observações</th>
                    </tr>
                    {this.showInvoices()}
                </tbody>
            </table>
            </div>
        )
    }
    showNotes(invoice){
        if("notes" in invoice){
            let notes = invoice.notes
            notes=sortByKey(notes,"added")
            return (
                notes.map((note,index)=>{
                    return(
                        <div className="stickyNote">
                        <ul key={index} className="invoiceNote">
                            <li>
                                <a href="/#">
                                    <Note note={note}/>
                                </a>
                            </li>
                        </ul>
                        </div>
                    )
                })
            )
        }
    }
    handleChange = (e) => {

        this.setState({[e.target.name]:e.target.value})

    }
    showInvoices(){
        return (
            this.props.invoice.invoices.map((invoice,index)=>{
                return(
                    <tr key={index}>
                        <td>{invoice.clientId}/{invoice.invoiceId}</td>
                        <td

                            onClick={
                                (e)=>{
                                    e.preventDefault();
                                    this.setState({
                                        quotationModalOpen:true,
                                            quotationDetailForModal:<QuotationDetails
                                            invoice={invoice}
                                            quotations={this.props.quotations}
                                        />,
                                        quotationId:invoice.quotationId
                                    })
                                }
                            }
                        >
                            <div className="detailLink">{invoice.quotationId}</div>
                            </td>
                        <td>{(invoice.amount*1).toFixed(2)}</td>
                        <td>{(invoice.balance*1).toFixed(2)}</td>
                        <td>{timeConverter(invoice.generated)}</td>
                        <td>{timeConverter(invoice.generated*1 + 24*60*60*14)}</td>
                        <td>
                            {this.showNotes(invoice)}
                            <div className="noteContainer">
                                <input
                                    className="addNote"
                                    name={invoice.clientId+invoice.invoiceId}
                                    type="text"
                                    placeholder="Digite um lembrete"
                                    onChange={this.handleChange}
                                />
                                <div className="direita">
                                    <div className="buttonWrapper">
                                        <button
                                            onClick={(e)=>{
                                                this.props.addNote(e,invoice,this.state[invoice.clientId+invoice.invoiceId])
                                            }}
                                        >
                                            <FaPlusCircle/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            </td>
                    </tr>
                )
            },this)
        )
    }

    render() {
        return(
            <div>
                {this.showDetails()}
                <Modal
                    open={this.state.quotationModalOpen}
                    onClose={
                        ()=>{
                            this.setState({quotationModalOpen:false})
                        }
                    }
                >
                    {this.state.quotationDetailForModal}
                </Modal>

            </div>
        )
    }

}