
export class Invoice{
    constructor(invoice){
        this.clientId=null;
        this.invoiceId=null;
        this.quotationId=null;
        this.generated=null;
        this.amount=null;
        this.currency=null;
        this.balance=null;
        this.status=null;
        this.payments=null;
        try{
            this.clientId=(invoice.clientId)
        }catch(err){

        }
        try{
            this.invoiceId=invoice.invoiceId
        }catch(err){
            this.invoiceId=null
        }
        try{
            this.quotationId=invoice.quotationId
        }catch(err){
            this.quotationId=null
        }
        try{this.generated=invoice.generated}catch(err){this.generated=null}
        try{this.amount=invoice.amount}catch(err){this.amount=null}
        try{this.currency=invoice.currency}catch(err){this.currency=null}
        try{this.balance=invoice.balance}catch(err){this.balance=null}
        try{this.status=invoice.status}catch(err){this.status=null}
        try{this.payments=invoice.payments}catch(err){this.payments=null}
    }

    //setters


    //getters

    getClientId(){return this.clientId};
    getInvoiceId(){return this.invoiceId};
    getQuoteId(){return this.invoiceId};
    getGenerated(){return this.generated};
    getAmount(){return this.amount}
    getCurrency(){return this.currency}
    getBalance(){return this.balance}
    getStatus(){return this.status}
    getPayments(){return this.payments}


}