import React from 'react';
import config from '../../../config.js'
import Client from '../../../model/Client'
import '../css/clients.css'
const API_URL=config.api_endpoint
export default class NewClient extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            clientAdded:false,
            attemptedToAdd:false,
            name: this.props.name,
            clientId:' ',
            consigned: ' ',
            id: ' ',
            landlineNumber: this.props.landlineNumber,
            address: ' ',
            suburb:' ',
            zipCode: ' ',
            city: ' ',
            state: ' ',
            stateRegistration: ' ',
            email: this.props.email,
            phone1: ' ',
            phone2:' ',
            phone3: ' ',
            phone4: ' ',
            whatsapp: null,
            whatsappDDD:null,
            whatsappNumber:null,
            mobileNumber:this.props.mobileNumber,
            poBox: ' ',
            bankPreference:' ',
            email2: ' ',
            isUpdating:false,
            authorizationToken:this.props.token


        }


        this.addClient=this.addClient.bind(this)
    }

    addClient(e){
        e.preventDefault();
            this.setState({adding:true})
            fetch(API_URL+"/clients/", {
                method: "POST",
                mode: "cors",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',

                },
                body: JSON.stringify(this.state)
            })
                .then(response => response.json())
                .then(response => {
                    if (response.includes('AER')){
                        this.setState({clientAdded:true,adding:false})
                        let client=this.state
                        client.clientId=response
                        //include clientList in localStorage
                        let clients=JSON.parse(localStorage.getItem("clients"))
                        if(!clients){
                            clients=[]
                        }
                        clients.push(new Client(client))
                        localStorage.setItem("clients",JSON.stringify(clients))
                        try{
                            this.props.updateClientList(new Client(client));
                        }catch(err){
                            console.log(err)
                        }

                        this.props.setContent('home')
                        this.setState({
                            clientId:response.clientId,
                            clientAdded:true,
                            adding:false,
                            attemptedToAdd:true
                        })
                    }

                })
                .catch(error => {
                    console.log(error)
                    if(!("TypeError" in error)){
                        this.setState({
                            adding:false,
                            attemptedToAdd:true
                        })
                    }

                });

    }
    handleChange = (e) => {

        this.setState({[e.target.name]:e.target.value})
        if(e.target.name==='whatsappDDD'){
            if(this.state.whatsappNumber){
                let whatsapp="+55"+e.target.value+this.state.whatsappNumber
                this.setState({whatsapp:whatsapp})
            }
        }
        if(e.target.name==='whatsappNumber'){
            if(this.state.whatsappDDD){
                let whatsapp="+55"+this.state.whatsappDDD+e.target.value
                this.setState({whatsapp:whatsapp})
            }
        }


    }

    showSubmitButton(){
        if(this.state.name&&this.state.email){
            return(
                <div className="buttonWrapper">
                    <button onClick={this.addClient}>SUBMIT</button>
                </div>
            )
        }
    }
    showClientDetails(){
            return(
                <div id="addClientWrapper">
                    <h5 id="addClientTitle">NOVO CLIENTE</h5>
                    <table id="addClientTable">
                        <tbody>
                            <tr className="addClientTableRow">
                                <td colspan="2">
                                    <span>NOME:</span>
                                    <input
                                        placeholder={this.props.name}
                                        type="text"
                                        name="name"
                                        onChange={this.handleChange}
                                    />
                                </td>
                                <td>
                                    <span>CPF / CNPJ:</span>
                                    <input
                                        placeholder={this.props.id}
                                        type="text"
                                        name="id"
                                        onChange={this.handleChange}
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <span>ENDEREÇO:</span>
                                    <input
                                        placeholder={this.state.address}
                                        type="text"
                                        name="address"
                                        onChange={this.handleChange}
                                    />

                                </td>
                                <td>
                                    <span>BAIRRO:</span>
                                    <input
                                    placeholder={this.state.suburb}
                                    type="text"
                                    name="suburb"
                                    onChange={this.handleChange}
                                    />
                                </td>
                                <td>
                                    <span>MUNICÍPIO:</span>
                                    <input
                                        placeholder={this.state.city}
                                        type="text"
                                        name="city"
                                        onChange={this.handleChange}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>CEP:</span>
                                    <input
                                        placeholder={this.state.zipCode}
                                        type="text"
                                        name="zipCode"
                                        onChange={this.handleChange}
                                    />
                                </td>
                                <td>
                                    <span>UF:</span>
                                    <input
                                        placeholder={this.state.state}
                                        type="text"
                                        name="state"
                                        onChange={this.handleChange}
                                    />
                                </td>
                                <td>
                                    <span>CAIXA POSTAL:</span>
                                    <input
                                        placeholder={this.state.poBox}
                                        type="text"
                                        name="poBox"
                                        onChange={this.handleChange}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>TEL FIXO</span>
                                    <input
                                        placeholder={this.props.landlineNumber}
                                        type="text"
                                        name="landlineNumber"
                                        onChange={this.handleChange}
                                    />
                                </td>
                                <td>
                                    <span>CELULAR TIM:</span>
                                    <input
                                        placeholder={this.props.mobileNumber}
                                        type="text"
                                        name="mobileNumber"
                                        onChange={this.handleChange}
                                    />
                                </td>
                                <td>
                                    <span>CELULAR OI:</span>
                                    <input
                                        placeholder={this.props.phone1}
                                        type="text"
                                        name="phone1"
                                        onChange={this.handleChange}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>CELULAR VIVO:</span>
                                    <input
                                        placeholder={this.props.phone2}
                                        type="text"
                                        name="phone2"
                                        onChange={this.handleChange}
                                    />
                                </td>
                                <td>
                                    <span>CELULAR CLARO:</span>
                                    <input
                                        placeholder={this.props.phone3}
                                        type="text"
                                        name="phone3"
                                        onChange={this.handleChange}
                                    />
                                </td>
                                <td>
                                    <span>CELULAR NEXTEL:</span>
                                    <input
                                        placeholder={this.props.phone4}
                                        type="text"
                                        name="phone4"
                                        onChange={this.handleChange}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>WHATSAPP:</td>
                                <td>
                                    <span>DDD</span><input
                                        placeholder={this.props.whatsappDDD}
                                        type="text"
                                        name="whatsappDDD"
                                        onChange={this.handleChange}
                                    />
                                </td>
                                <td><span>Número</span>
                                    <input
                                        placeholder={this.props.whatsappNumber}
                                        type="text"
                                        name="whatsappNumber"
                                        onChange={this.handleChange}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>E-mail:</td>
                                <td>
                                    <span>E-mail:</span>
                                    <input
                                        placeholder={this.props.email}
                                        type="text"
                                        name="email"
                                        onChange={this.handleChange}
                                    />
                                </td>

                                <td>
                                    <span>E-mail (secundário):</span>
                                    <input
                                        placeholder={this.props.email2}
                                        type="text"
                                        name="email2"
                                        onChange={this.handleChange}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {this.showSubmitButton()}
                </div>
            )
    }


    render() {
        if(this.state.adding){
            return "Trying to add new client ..."
        }
        if((this.state.clientAdded)){
            return "Client successfully added."
        }
        if((this.state.attemptedToAdd)&&(!this.state.clientAdded)){
            return "Error adding client..."
        }
        return (
            <div className="clientDetailContainer">

                {this.showClientDetails()}
            </div>
        )


    }

}