import React from 'react';
import "../../css/unpaidInvoices.css"
import {timeConverter} from "../../functions";


export default class Note extends React.Component {
    constructor(props) {
        super(props);
        this.state={

        }
    }




    render() {
        return(
            <a href="/#">
                <h5>Em {timeConverter(this.props.note.added)}, você anotou</h5>
                <p>{this.props.note.note}</p>

            </a>
        )
    }

}