import React from 'react';
import '../../css/form.css';
import config from '../../config.js'
import {sortByKey} from "../../functions";
const API_URL = config.api_endpoint
export default class NewProductForm extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            name: ' ',
            categoryId: ' ',
            image: '',
            Material: ' ',
            size: '',
            productId:' ',
            description: ' ',
            partNumber: ' ',
            availability:' ',
            currency:' ',
            gramsPerSquareCentimeter:' ',
            kgPerMeter:' ',
            kgPerUnit:' ',
            price:' ',
            unity:' ',
            file:null,
            isUpdating:false,
            hasUpdated:false,
            authorizationToken:this.props.token
        }
        this.incluir=this.incluir.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.clear=this.clear.bind(this)
    }


    async loadProduct(productId){
        let url="https://www.macys.com/xapi/digital/v1/product/"
        url=url+productId
        url=url+"?size=small&clientId=PROS&_shoppingMode=SITE&_customerState=GUEST&currencyCode=AUD&_regionCode=AU"

        await fetch(url, {
            method: "GET"
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                return response
            })
            .catch(error => {
                console.error('Error:', error)
                return null
            });

    }


    componentWillMount(){
        let productIds=[
            7654362,
            6655133,
            6723640,
            6723641,
            6712454,
            7676104,
            7902229,
            6292666,
            7902232,
            7902231,
            7902230,
            6916309,
            7604871,
            7109524,
            7481764,
            7878866,
            7880051,
            6731069,
            7270164,
            7892002,
            7481766,
            7693953,
            7234617,
            7893175,
            7135844,
            7105207,
            7893177,
            7166807,
            7105211,
            7893188,
            6869883,
            7481727,
            7693957,
            7693952,
            8017151,
            7482402,
            8017153,
            8017149,
            7084151,
            7724246,
            7220321,
            7673671,
            7220265,
            7410663,
            8022841,
            7425932,
            7220263,
            8022852,
            6461352,
            8022844,
            8022854,
            8022846,
            8022853,
            8022855,
            8022847,
            8022848,
            7893224,
            8022845,
            7893195,
            7893203,
        ]
        let products=[]
        for(let i=0;i<productIds.length;i++){
            let product=this.loadProduct(productIds[i])
            products.push(product)
        }
        console.log(products)
    }

    handleFileUpload = (event) => {
        this.setState({file: event.target.files});
    }

    clear(){
        this.setState({
            productId:' ',
            description: ' ',
            partNumber: ' ',
            availability:' ',
            currency:' ',
            gramsPerSquareCentimeter:' ',
            kgPerMeter:' ',
            kgPerUnit:' ',
            price:' ',
            unity:' ',
            name: ' ',
            categoryId: ' ',
            image: '',
            Material: ' ',
            size: '',
            isUpdating:false,
            updated:false
        })
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });

    }

    incluir(e){
        e.preventDefault()
        this.setState({isUpdating:true})
        //this.props.toggleIsUpdating(e)
        let produto={authorizationToken:this.props.token}
        if(this.state.description){produto["description"]=this.state.description}
        else{return}
        if(this.state.unity){produto["unity"]=this.state.unity}
        else{return}
        if(this.state.partNumber){produto["partNumber"]=this.state.partNumber}
        if(this.state.currency){produto["currency"]=this.state.currency}
        if(this.state.gramsPerSquareCentimeter){produto["gramsPerCentimeter"]=this.state.gramsPerSquareCentimeter}
        if(this.state.kgPerUnit){produto["kgPerUnit"]=this.state.kgPerUnit}
        if(this.state.price){produto["price"]=this.state.price}
        if(this.state.name){produto["name"]=this.state.name}
        if(this.state.categoryId){produto["categoryId"]=this.state.categoryId}
        if(this.state.image){produto["image"]=this.state.image}
        if(this.state.Material){produto["Material"]=this.state.Material}
        if(this.state.size){produto["size"]=this.state.size}
        if(this.state.file){produto["file"]=this.state.file}
        fetch(API_URL+"/products/", {
            method: "POST",
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body:JSON.stringify(produto)
        })
            .then(response => response.json())
            .then(response => {
                if (response){
                    produto["productId"]=response
                    //update localStorage
                    let products=JSON.parse(localStorage.getItem("produtos"))
                    if(!products){
                        products=[]
                    }
                    products.push(produto)
                    localStorage.setItem("produtos",JSON.stringify(products))
                    this.props.updateProductList(produto)
                    this.clear()
                    this.setState({isUpdating:false,hasUpdated:true})
                    // this.props.toggleUpdated(e)
                }

            })
            .catch(error => {
                console.error('Error:', error)
            })
        console.log(JSON.stringify(produto))
    }

    showDetails(){
        return(
            <div>
                <h5 className="newProductTitle">Cadastrar novo produto</h5>
                <div className="newProductContainer">
                    <div className="container">
                    <span className="dica">
                            <span role="img" aria-label="lightBulb">💡</span>
                    </span>
                    <span className="mostrar-dica">
                        <p>
                            Use este formulátio para incluir um novo produto. A quantidade disponível será 0. Os níveis de estoque serão atualizados conforme compras / vendas forem confirmadas.
                        </p>

                    </span>

                        <div className="row">
                            <div className="col-25">Nome do produto:
                            </div>
                            <div className="col-75">
                                <input
                                    name="name"
                                    className="editClientDetailsInput"
                                    placeholder="Nome do produto (como será apresentado na cotação / fatura)"
                                    style={{height: 40}}
                                    onChange={
                                        (e) =>this.handleChange(e)
                                    }
                                />
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-25">Categoria:
                            </div>
                            <div className="col-75">
                                <input
                                    name="categoryId"
                                    className="editClientDetailsInput"
                                    placeholder="Digite a categoria do produto"
                                    style={{height: 40}}
                                    onChange={
                                        (e) =>this.handleChange(e)
                                    }
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-25">Imagem endereço:
                            </div>
                            <div className="col-75">
                                <input
                                    name="image"
                                    className="editClientDetailsInput"
                                    placeholder="Entre com o endereço da imagem do produto"
                                    style={{height: 40}}
                                    onChange={
                                        (e) =>this.handleChange(e)
                                    }
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-25">Material:
                            </div>
                            <div className="col-75">
                                <input
                                    name="Material"
                                    className="editClientDetailsInput"
                                    placeholder="Digite o material do produto"
                                    style={{height: 40}}
                                    onChange={
                                        (e) =>this.handleChange(e)
                                    }
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">Dimensões:
                            </div>
                            <div className="col-75">
                                <input
                                    name="size"
                                    className="editClientDetailsInput"
                                    placeholder="Dimensões do produto"
                                    style={{height: 40}}
                                    onChange={
                                        (e) =>this.handleChange(e)
                                    }
                                />
                            </div>
                        </div>



                    <div className="row">
                        <div className="col-25">Informações adicionais:
                        </div>
                        <div className="col-75">
                            <input
                                name="description"
                                className="editClientDetailsInput"
                                placeholder="Digite uma informação adicional / descrição"
                                style={{height: 40}}
                                onChange={
                                    (e) =>this.handleChange(e)
                                }
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-25">PN:
                        </div>
                        <div className="col-75">
                            <input
                                name="partNumber"
                                className="editClientDetailsInput"
                                placeholder="O part number / número da peça"
                                style={{height: 40}}
                                onChange={
                                    (e) =>this.handleChange(e)
                                }
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-25">Moeda:
                        </div>
                        <div className="col-75">
                            <input
                                name="currency"
                                className="editClientDetailsInput"
                                placeholder="Apenas para registro. Programa não está habilitado a fazer conversões no momento."
                                style={{height: 40}}
                                onChange={
                                    (e) =>this.handleChange(e)
                                }
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-25">Preço:
                        </div>
                        <div className="col-75">
                            <input
                                name="price"
                                className="editClientDetailsInput"
                                placeholder="Preço para venda"
                                style={{height: 40}}
                                onChange={
                                    (e) =>this.handleChange(e)
                                }
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-25">Unidade:
                        </div>
                        <div className="col-75">
                            <input
                                name="unity"
                                className="editClientDetailsInput"
                                placeholder="Unidade de medida (m, kg, un)"
                                style={{height: 40}}
                                onChange={
                                    (e) =>this.handleChange(e)
                                }
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-25">Peso (kg):
                        </div>
                        <div className="col-75">
                            <input
                                name="kgPerUnit"
                                className="editClientDetailsInput"
                                placeholder="Peso do produto em kg"
                                style={{height: 40}}
                                onChange={
                                    (e) =>this.handleChange(e)
                                }
                            />
                        </div>

                    </div>
                        {/*<div className="row">*/}
                            {/*<div className="col-25">Image:</div>*/}
                            {/*<div className="col-75">*/}
                                {/*<input label='upload file' type='file' onChange={this.handleFileUpload} />*/}

                            {/*</div>*/}

                        {/*</div>*/}
                    <div className="row-center">
                        <span className="center">
                            <button
                                onClick={(e)=>this.incluir(e)}
                                className="button-add"
                            >Incluir
                            </button>
                        </span>
                    </div>
                </div>
                </div>
            </div>
        )

    }

    render() {
        if(this.state.hasUpdated){
            return(
                    <div className="updating">
                        <h3>Product added</h3>

                        <p>
                            Thank you!
                        </p>
                        <p>Press Escape to return to main menu</p>
                    </div>
            )
        }
        else{
            if(this.state.isUpdating){
                return (
                        <div className="updating">
                            <h3>Including new product</h3>
                            <p>
                                Please wait!
                            </p>
                        </div>
                )
            }
            else{
                return (
                    <div>
                    <span>
                    {this.showDetails()}
                    </span>

                    </div>
                );}
        }
    }

    addProduct(produto){
        let produtos=[]

        for(let i=0;i<this.state.produtos.length;i++){
            if(this.state.produtos[i].productId!==produto.productId)
                produtos.push(this.state.produtos[i])
        }
        produtos.push(produto)
        produtos=sortByKey(produtos,"description")
        this.setState(
            {
                produtos:produtos}
        )
        localStorage.setItem("produtos",JSON.stringify(produtos))



    }
}