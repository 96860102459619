import React from 'react';
import '../../../../css/instructions.css'

export default class QuotationPanelInstructions extends React.Component {
    constructor(props) {
        super(props);
        this.state={

        }

    }


    render() {
        return(
            <div className="instructions">
                <h1>Quotation / Shipment Management</h1>
                <ul>
                    <li>OPEN
                        <ul>
                            <li>OPEN quotes are the ones that have just been generated.</li>
                            <li>There is no invoice for this yet.</li>
                            <li>An invoice is automatically generated and a bill sent to clients registered email address when you add SHIP information from within this section</li>
                            <li>Once you ship products relating to a quotation, the quotation passes to the 'PAYMENT PENDING' state.</li>
                        </ul>
                    </li>
                    <li>PAYMENT PENDING
                        <ul>
                            <li>A quotation in 'PAYMENT PENDING' state means that you have shipped products relating to that quote but have not received any payment yet</li>
                            <li>If you have received any payments relating to this quote, you will need to allocate payment through the Allocate Payments from the main menu.</li>
                        </ul>
                    </li>
                    <li>PAYMENT PENDING / NOT SHIPPED
                        <ul>
                            <li>If a quotation is in this state, it means that you have received a partial payment for this quotation but have shipped the products yet.</li>
                            <li>There is also an invoice that related to this quotation that has already been sent to your client for action</li>
                        </ul>
                    </li>
                    <li>EXPIRED
                        <ul>
                            <li>An expired quote means that you have generated it at least 15 days ago and your client has not confirmed any order</li>
                            <li>You cannot revalidate EXPIRED quotation at this stage.</li>
                            <li>They are here for your records and auditing purposes</li>
                        </ul>
                    </li>
                    <li>FINALISED
                        <ul>
                            <li>A finalised quotation simply means that this quote generated an invoice that you have received full payment for and for which you have also sent the products</li>

                        </ul>
                    </li>
                    <li>READY TO SHIP
                        <ul>
                            <li>It's all in the hands of the warehouse.</li>
                            <li>The invoice for which 'READY TO SHIP' has been paid in full but you have not shipped the products yet</li>
                        </ul>
                    </li>
                </ul>
            </div>
        )

    }
}