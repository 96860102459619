import './css/App.css';
import React, {Component} from 'react';
import Authenticate from "./Authenticate";
import LandingPage from "./LandingPage";
import config from './config.js'
import VerifyingAuthentication from "./ui_components/VerifyingAuthentication";
import Loggedout from "./ui_components/Loggedout";
import {isMobile} from 'react-device-detect';

const API_URL = config.api_endpoint


class App extends Component {

    constructor(props){
        super(props)
        this.state={
            authenticated:false,
            token:localStorage.getItem("authToken"),
            failed:false,
            loggedout:false
        }
        this.setToken=this.setToken.bind(this)
        this.verifyToken=this.verifyToken.bind(this)
        this.logout=this.logout.bind(this)
    }
    setToken(e,res){
        e.preventDefault()
        if(res){
            if("statusCode" in res){
                if(res.statusCode===200){
                    localStorage.setItem("authToken",res.body.token)
                    this.setState({
                        token:res.body.token,
                        authenticated:true
                    })
                }
                else{
                    this.setState({failed:true})
                }
            }
            else{
                this.setState({failed:true})
            }
        }
        else{
            this.setState({failed:true})
        }


    }

    verifyToken(){
        let bodyRequest={
            token:this.state.token
        }
        fetch(API_URL+"/admin/authenticate/verify", {
            method: "POST",
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body:JSON.stringify(bodyRequest)
        })
            .then(response => response.json())
            .then(response => {
                if(response===true){
                    this.setState({authenticated:true})
                }
                else{
                    this.setState({token:''})
                    localStorage.removeItem("authToken")
                }

            })
            .catch(error => {

                console.log(error)
            })
    }

    logout(){
        this.setState({loggedout:true})
    }

    render(){
        if(isMobile){
            return <div>"mobile content"</div>
        }
        if(this.state.loggedout){
            return <div className="initial">
                <Loggedout/>
            </div>
        }
        if(this.state.authenticated){
            if(this.state.token){
                return (
                        <div className='initial'>
                            <LandingPage token={this.state.token} logout={this.logout}/>
                        </div>
                )
            }
        }
        if(this.state.token){
            //check authentication token and change state to
            //authenticated
            this.verifyToken()
            return(
                <VerifyingAuthentication/>
            )
        }
        if(this.state.failed){
            return(
                    <Authenticate setToken={this.setToken} failed={this.state.failed}/>

            )
        }
        return(
                <Authenticate setToken={this.setToken} failed={this.state.failed}/>

            // {/*<Router>*/}
            //     {/*<div className="initial" onKeyUp={this.handleKeyUp}>*/}
            //         {/*<Route*/}
            //             {/*exact path={"/"}*/}
            //             {/*component={LandingPage}*/}
            //             {/*/>*/}
            //     {/*</div>*/}
            // {/*</Router>*/}
        )
    }

}

export default App;
