//to create a Product object as per database data
import {Component} from 'react'
class Produto extends Component {

    constructor(produto){
        super();
        this.name=""
        this.productId="";
        this.description="";
        this.availability=0;
        this.unity="";
        this.currency="AUD$";
        this.gramsPerSquareCentimeter="";
        this.kgPerMeter=0;
        this.kgPerUnit=0;
        this.partNumber="";
        this.price=0;
        this.name= ' ';
        this.categoryId= ' ';
        this.image= '';
        this.Material= ' ';
        this.size= '';
        if("productId" in produto){this.productId=produto.productId}
        if("description" in produto){this.description=produto.description}
        if("availability" in produto){this.availability=produto.availability}
        if("unity" in produto){this.unity=produto.unity}
        if("currency" in produto){this.currency=produto.currency}
        if("gramsPerSquareCentimeter" in produto){this.produto=produto.gramsPerSquareCentimeter}
        if("kgPerMeter" in produto){this.kgPerMeter=produto.kgPerMeter}
        if("kgPerUnit" in produto){this.kgPerUnit=produto.kgPerUnit}
        if("partNumber" in produto){this.partNumber=produto.partNumber}
        if("price" in produto){this.price=parseFloat(produto.price)}
        if("name" in produto){this.name=produto.name}
        if("categoryId" in produto){this.categoryId=produto.categoryId}
        if("image" in produto){this.image=produto.image}
        if("material" in produto){this.material=produto.material}
        if("size" in produto){this.size=produto.size}
        if("length" in produto){this.length=produto.length}
        if("lengthUnit" in produto){this.lengthUnit=produto.lengthUnit}
        if("width" in produto){this.width=produto.width}
        if("widthUnit" in produto){this.widthUnit=produto.widthUnit}
        if("thickness" in produto){this.thickness=produto.thickness}
        if("type" in produto){this.type=produto.type}
        if("dimensions" in produto){this.dimensions=produto.dimensions}

    }

    //setters
    setProductId(productId){
        this.productId=productId;
    }
    setPrice(newPrice){
        this.price=newPrice
    }
    setDescription(description){
        this.description=description;
    }
    setAvailability(availability){
        this.availability=availability;
    }

    //getters
    getPrice(){
        return this.price*1
    }
    getDescription(){
        if(this.description){
            return this.description
        }
        return ""
    }

    getNameAndDescription(){
        if("description" in this){
            return (this.getName()+" - "+this.getDescription())
        }
        return (this.getName())

    }

    getName(){
        return this.name
    }

    getAvailability(){
        return this.availability;
}

    getKgPerUnit(){
        return this.kgPerUnit*1
    }

    getProductId(){
        return this.productId;
    }

    getUnity(){return this.unity}

    isTheSame(produto){
        if(this.productId===produto.productId){
            return true
        }
        return false;
    }

    //useful when retrieving items from a quote
    static buildProduct(productId,estoque,price){
        let produto=estoque.getProduct(productId)
        produto.setPrice(price)
        return produto

    }
}

export default Produto;