import {sortByKey} from "../functions";
import {Quotation} from "./Quotation";
export default class Quotations{

    constructor(cotacoes){
        this.quotations=[];
        try{
            let c=sortByKey(cotacoes,"quotationId")
            this.quotations=c;
        }catch(err){
            this.quotations=[]
        }

    }

    updateQuote(quote){
        let c=[]
        for(let i=0; i<this.quotations.length; i++){
            if(this.quotations[i].quotationId===quote.quotationId){
                let temp=new Quotation(quote)
                c.push(temp)
            }
            else{
                let temp=new Quotation(this.quotations[i])
                c.push(temp)
            }
        }
        this.quotations=c
        localStorage.setItem("quotations",JSON.stringify(c))
    }

    addQuote(quote){
        let cotacoes=this.quotations
        cotacoes.push(quote)
        cotacoes=sortByKey(cotacoes,"quotationId")
        this.quotations=cotacoes
    }

    getQuotes(){
        return this.quotations
    }

    getQuote(quotationId){
        for(let i=0; i<this.quotations.length; i++){
            if(this.quotations[i].quotationId===quotationId){
                return this.quotations[i]
            }
        }
        return null;
    }

    getOpenQuotes(){
        let cotacoes=[]
        try{
        for(let i=0; i<this.quotations.length; i++){
            let cotacao=this.quotations[i]
            if(cotacao.quoteStatus.toLowerCase()==="open"){
                cotacoes.push(cotacao)
            }
        }}catch(err){
            console.log(err)
        }
        cotacoes=sortByKey(cotacoes,"quotationId")
        return (new Quotations(cotacoes))
    }

    //can potentially just leave this function and remove
    //getOpenQuotes to avoid repetition
    getQuotesBySituation(situation){
        let cotacoes=[]
        try{
            for(let i=0; i<this.quotations.length; i++){
                let cotacao=new Quotation(this.quotations[i])
                if(cotacao.quoteStatus.toUpperCase()===situation.toUpperCase()){
                    cotacoes.push(cotacao)
                }
            }}catch(err){
            console.log(err)
        }
        cotacoes=sortByKey(cotacoes,"quotationId")
        let c=[]
        for(let i=0;i<cotacoes.length;i++){
            c.push(new Quotation(cotacoes[i]))
        }
        return (new Quotations(c))
    }

    getOptions(){
        let options=[]
        for(let i=0; i<this.quotations.length; i++){
            try{
                let quotation=new Quotation(this.quotations[i])
                let option={
                    value:quotation,
                    label:quotation.quotationId+" - "+quotation.cliente.name
                }
                options.push(option)
            }
            catch(err){
                console.log(err)
            }
        }
        return options;
    }

}
