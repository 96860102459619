import React from 'react';
import Modal from "react-responsive-modal";
import Select from 'react-select';
import QuotationDetails from "../QuotationDetails";
import '../../../../css/cotacao.css'


export default class QuoteProducts extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            productModal:false,
            selectedProduct:'',
            selectedProductId:'',
            quantidadeNovoProduto:0,
            length:null,
            width:null
        }
        this.selectedOption=this.selectedOption.bind(this)
        this.addProductInfo=this.addProductInfo.bind(this)
        this.openModal=this.openModal.bind(this)
        this.showAddButton=this.showAddButton.bind(this)
        this.showQuantity=this.showQuantity.bind(this)
    }

    openModal(e){
        e.preventDefault();
        this.setState({
            selectedProduct:'',
            selectedProductId:'',
            quantidadeNovoProduto:0,
            productModal:true
        })
    }

    addProductInfo(e){
        e.preventDefault();
        this.setState({[e.target.name]:e.target.value});
        if((e.target.name==='length')&&(e.target.name==='width')){
            let length=0;
            let width=0;
            let quantity=0
            if(e.target.name==='length'){
                length=e.target.value;
                width=this.state.width;
            }
            else{
                width=e.target.value;
                length=this.state.length;
            }
            try{
                quantity=(width*length)/(this.state.selectedProduct.length*this.state.selectedProduct.width)
            }catch(err){
                console.log(err)
            }
            let product=this.state.selectedProduct
            let description="("+width.toString()+" cm x "+length.toString()+" cm)"
            product.setDescription(description)
            this.setState({
                quantidadeNovoProduto:quantity,
                selectedProduct:product
            })

        }
        if((e.target.name==='quantidadeNovoProduto')){
            this.setState({
                length:null,
                width:null
            })
        }
        console.log("quantities")
        console.log(this.state.selectedProduct)
        console.log(this.state.quantidadeNovoProduto)

    }

    selectedOption = (selectedOption) => {
        try{
            this.setState({
                selectedProduct:selectedOption.value,
                selectedProductId:selectedOption.value.productId
            })
        }
        catch(error){
            console.log(error)
        }
        console.log(selectedOption.value.getDescription())
    }

    showAddButton(){
        if(this.state.selectedProductId){
            let product=this.state.selectedProduct
            console.log(product)
            if("type" in product){
                if(product.type.toLowerCase()==='sheet'){
                    if(this.state.length&&this.state.width){
                        if((this.state.length>0)&&(this.state.width>0)){
                            return (
                                <div className="buttonWrapper">
                                    <button
                                        className="adicionarProduto"
                                        onClick={
                                            (e)=>{
                                                e.preventDefault();
                                                let quantity=this.state.quantidadeNovoProduto;
                                                let product=this.state.selectedProduct
                                                if("type" in product){
                                                    if(product["type"]==='sheet'){
                                                        quantity=(this.state.length*1)*(this.state.width*1)/((product.length*1)*(product.width*1))
                                                        let description = "("+this.state.length+" cm x "+this.state.width+" cm)"
                                                        product.setDescription(description)
                                                    }
                                                }
                                                this.props.addProduct(product,quantity.toString())
                                                this.setState({
                                                    selectedProduct:'',
                                                    selectedProductId:'',
                                                    quantidadeNovoProduto:0,
                                                    productModal:false,

                                                })
                                            }
                                        }
                                    >
                                        Adicionar
                                    </button>
                                </div>
                            )
                        }
                    }
                }
            }
            else{
                if(this.state.quantidadeNovoProduto>0){
                    let product=this.state.selectedProduct
                    console.log(product)
                    return (
                        <div className="buttonWrapper">
                            <button
                                className="adicionarProduto"
                                onClick={
                                    (e)=>{
                                        e.preventDefault();
                                        this.props.addProduct(this.state.selectedProduct,this.state.quantidadeNovoProduto)
                                        this.setState({
                                            selectedProduct:'',
                                            selectedProductId:'',
                                            quantidadeNovoProduto:0,
                                            productModal:false

                                        })
                                    }
                                }
                            >
                                Adicionar
                            </button>
                        </div>
                    )
                }
            }
        }



    }

    showQuantity(){
        if(this.state.selectedProduct){
            if("type" in this.state.selectedProduct){
                if(this.state.selectedProduct.type.toLowerCase()==="sheet"){
                    return(
                        <div  className="dadosProduto">
                            <span>
                                <label htmlFor="length">Comprimento:</label>
                                <input
                                    id="length"
                                    onChange={this.addProductInfo}
                                    className="formDetailInput"
                                    type="number"
                                    step={0.01}
                                    name="length">
                                </input>
                            </span>
                            <span>
                                <label htmlFor="width">Largura:</label>
                                <input
                                    id="width"
                                    onChange={this.addProductInfo}
                                    className="formDetailInput"
                                    type="number"
                                    step={0.01}
                                    name="width">
                                </input>
                            </span>
                        </div>
                    )
                }

            }
        }
        return(
            <div className="dadosProduto">
                <label htmlFor="quantidadeNovoProduto">Quantidade:</label>
                <input
                    id="quantidadeNovoProduto"
                    onChange={this.addProductInfo}
                    className="formDetailInput"
                    type="number"
                    step={0.01}
                    name="quantidadeNovoProduto">
                </input>
            </div>
        )
    }

    //remember to sync localStorage with server responses

    render() {
        return (
            <div>
                <QuotationDetails
                    stock={this.props.stock}
                    quotation={this.props.quotation}
                    openModal={this.openModal}
                    removeItem={this.props.removeItem}
                />
                <Modal
                    className="addProductModal"
                    open={this.state.productModal}
                    onClose={(e)=>{
                        e.preventDefault();
                        this.setState({productModal:false})
                    }}>
                    <h2>Adicionar Produto</h2>
                    <Select
                        className="addProductSelect"
                        placeholder="Selecione"
                        onChange={this.selectedOption}
                        label="Single select"
                        options={this.props.options}

                    />
                    {this.showQuantity()}
                    <div className="addProductButton">
                        {this.showAddButton()}
                    </div>
                </Modal>
            </div>
        )
    }
}