import React from 'react';
import Draggable from 'react-draggable';

export default class FornecedoresDetails extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            produto:this.props.produto
        }
    }

    showAttribute(attribute){
        if(attribute==="null"){
            return ""
        }
        else{
            return <span>{attribute}</span>
        }
    }


    showDetails(){
        if(this.props.produto){
            return(
                <div className="clientInfo">

                    <p>
                        <span className="clientDetailLabel">Número do Fornec.: </span>
                        <span>{this.props.produto.clientId}</span>
                    </p>
                    <p>
                        <span className="clientDetailLabel">Nome: </span>
                        {this.showAttribute(this.props.produto.Nome)}
                    </p>
                    <p>
                        <span className="clientDetailLabel">CNPJ:</span>
                        {this.showAttribute(this.props.produto.CNPJ)}
                    </p><hr/>

                    <p>
                        <span className="clientDetailLabel">E-mail:</span>
                        {this.showAttribute(this.props.produto.Email)}
                    </p>
                    <p>
                        <span className="clientDetailLabel">Telefone:</span>
                        {this.showAttribute(this.props.produto.Telefone)}
                    </p>
                    <hr/>
                    <p>
                        <span className="clientDetailLabel">Endereço: </span>
                        {this.showAttribute(this.props.produto.Endereco)}
                    </p>
                    <p>
                        <span className="clientDetailLabel">Bairro:</span>
                        {this.showAttribute(this.props.produto.Bairro)}
                    </p>
                    <p>
                        <span className="clientDetailLabel">Município:</span>
                        {this.showAttribute(this.props.produto.Municipio)}
                    </p>
                    <p>
                        <span className="clientDetailLabel">Estado:</span>
                        {this.showAttribute(this.props.produto.Estado)}
                    </p>
                    <p>
                        <span className="clientDetailLabel">CEP:</span>
                        {this.showAttribute(this.props.produto.CEP)}
                    </p>
                    <p>
                        <span className="clientDetailLabel">Caixa Postal: </span>
                        {this.showAttribute(this.props.produto.CaixaPostal)}
                    </p>
                </div>
            )
        }
        else{
            return ""
        }
    }

    render() {
        return (
            <Draggable>
                <div className="right">

                    {this.showDetails()}
                </div>
            </Draggable>
        );
    }
}