import React from 'react';
import Select from 'react-select';
import config from "../../config";
import InvoicesDetails from "./InvoicesDetails";
const API_URL = config.api_endpoint


export default class Unpaid extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            unpaidInvoices:{},
            options:[],
            invoiceDetails:'',
            quotations:this.props.quotations

        }
        this.addNote=this.addNote.bind(this)
        this.getOptions=this.getOptions.bind(this)
        this.getUnpaid=this.getUnpaid.bind(this)
    }

    componentWillMount(){
        this.getUnpaid()
    }


    addNote(e,invoice,note){
        e.preventDefault()
        let body= {
            authorizationToken:this.props.token,
            note:note
        }
        fetch(API_URL+"/invoices/"+invoice.clientId+"/"+invoice.invoiceId, {
            method: "POST",
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body:JSON.stringify(body)
        }).then(response => response.json()).then(response => {
            console.log(response)
            let unpaidInvoices=this.state.unpaidInvoices
            let invoices=unpaidInvoices[invoice.clientId]["invoices"]
            for(let i=0;i<invoices.length;i++){
                if(invoices[i].invoiceId===invoice.invoiceId){
                    invoices[i]=response
                    break;
                }
            }
            unpaidInvoices[invoice.clientId]["invoices"]=invoices
            let client=this.props.clients.getClient(invoice.clientId)
            let updatedInvoices={
                client:client,
                invoices:invoices
            }
            this.setState({
                unpaidInvoices:unpaidInvoices,
                invoiceDetails:<InvoicesDetails
                                    addNote={this.addNote}
                                    invoice={updatedInvoices}
                                    quotations={this.state.quotations}
                                />

            })


        })
            .catch(error => {
                console.error('Error:', error)
            })
    }

    getUnpaid(){
        fetch(API_URL+"/invoices/unpaid", {
            method: "GET",
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                authorizationToken:this.props.token
            },
        }).then(response => response.json()).then(response => {
            console.log(response)
            let unpaidInvoices=this.state.unpaidInvoices;
            for(let i=0;i<response.length;i++){
                let invoice=response[i];
                let clientId=invoice["clientId"]
                if(!(invoice["clientId"] in unpaidInvoices)){
                    unpaidInvoices[clientId]={};
                    unpaidInvoices[clientId]["invoices"]=[]
                    unpaidInvoices[clientId]["client"]={}
                }
                unpaidInvoices[clientId]["client"]=this.props.clients.getClient(clientId)
                unpaidInvoices[clientId]["invoices"].push(invoice)

            }
            console.log(unpaidInvoices)
            this.setState({
                unpaidInvoices:unpaidInvoices,
                options:this.getOptions(unpaidInvoices)
            })

        })
            .catch(error => {
                console.error('Error:', error)
            })
    }

    showInvoiceStatus(clientInvoices){
        return (
            <InvoicesDetails
                addNote={this.addNote}
                invoice={clientInvoices}
                quotations={this.props.quotations}

            />
        )
    }

    getOptions(unpaidInvoices){
        //construct array
        let clientInvoices=[]
        for(let key in unpaidInvoices){
            clientInvoices.push(unpaidInvoices[key])
        }

        //set Options
        let options=[]
        for(let i=0;i<clientInvoices.length;i++){
            options.push({
                value:clientInvoices[i],
                label:clientInvoices[i].client.clientId +" - "+clientInvoices[i].client.name
            })
        }
        console.log('options')
        console.log(options)
        return options
    }

    handleChange = (selectedOption) => {
        this.setState({ selectedOption });
        let invoices=selectedOption.value
        let components=this.showInvoiceStatus(invoices)
        try{
            this.setState({
                invoiceDetails:components
            })
        }
        catch(error){console.log(error)}

    }

    render() {

        return(
            <div>
                <Select
                    onChange={this.handleChange}
                    options={this.state.options}
                />
                {this.state.invoiceDetails}
            </div>
        )
    }

}