import React from 'react';
import '../../css/form.css';
import config from '../../config.js'
import {sortByKey} from "../../functions";
import Select from 'react-select';

const API_URL = config.api_endpoint
export default class NewTubeForm extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            name: ' ',
            categoryId: ' ',
            image: '',
            Material: ' ',
            size: '',
            productId:' ',
            description: ' ',
            partNumber: ' ',
            availability:' ',
            currency:' ',
            gramsPerSquareCentimeter:' ',
            kgPerMeter:' ',
            kgPerUnit:' ',
            price:' ',
            unity:'m',
            thickness:null,
            diameter:null,
            file:null,
            isUpdating:false,
            hasUpdated:false,
            diameterFraction:'',
            selectedOption:null,
            authorizationToken:this.props.token,
            isMounted:false
        }
        this.incluir=this.incluir.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.clear=this.clear.bind(this)
        this.convertToFraction=this.convertToFraction.bind(this)
        this.showIncludeButton=this.showIncludeButton.bind(this)
        this.handleSelect=this.handleSelect.bind(this)
        this.isValidDimensions=this.isValidDimensions.bind(this)
        this.calculateWeights=this.calculateWeights.bind(this)
    }

    componentDidMount(){
        this.setState({isMounted:true})
    }


    calculateWeights(diameter, thickness){
        console.log('calculating weight')
        let density=0;
        if((this.state.selectedOption==='4130')||(this.state.selectedOption==='1020'))
        {
            density=0.00786
        }
        if(this.state.selectedOption==='6061T6'){
            density=0.0027
        }

        let area=(3.141592)*thickness*(diameter-thickness)
        let volume=1000*area;
        let weight=volume*density/1000;
        console.log(weight)
        return weight

    }

    isValidDimensions(){
        if((2*this.state.thickness)>this.state.diameter){
            return "Erro. Diâmetro deve ser maior que o dobro da espessura."
        }
    }

    handleSelect = (selectedOption) => {
        console.log(selectedOption)
        this.setState({ selectedOption });
        try{
            this.setState({
                selectedOption:selectedOption.value
            })
        }
        catch(error){console.log(error)}

    }

    showIncludeButton(){
        if(((this.state.thickness)>0)
            &&((this.state.diameter)>0)
            &&(this.state.selectedOption!==null)

        ){
            if((2*this.state.thickness)<(this.state.diameter)){

                return (
                    <div className="row-center">
                        <span className="center">
                            <button
                                onClick={(e)=>this.incluir(e)}
                                className="button-add"
                            >Incluir
                            </button>
                        </span>
                    </div>
                )
            }


        }

    }

    handleFileUpload = (event) => {
        this.setState({file: event.target.files});
    }

    clear(){
        this.setState({
            productId:' ',
            description: ' ',
            partNumber: ' ',
            availability:' ',
            currency:' ',
            gramsPerSquareCentimeter:' ',
            kgPerMeter:' ',
            kgPerUnit:' ',
            price:' ',
            unity:' ',
            name: ' ',
            categoryId: ' ',
            image: '',
            Material: ' ',
            size: '',
            isUpdating:false,
            updated:false,
            diameterFraction: ' ',
            thickness:null,
            diameter:null,
        })
    }

    handleChange(event) {
        event.preventDefault()
        this.setState({ [event.target.name]: event.target.value });
        if(event.target.name==='diameter'){
            let weight=this.calculateWeights(event.target.value*25.4,this.state.thickness*25.4)
            let diameterFraction=this.convertToFraction(event.target.value)
            let name=''
            if(this.state.selectedOption){
                switch(this.state.selectedOption){
                    case '4130':
                        name="Tubo de aço 4130 - "+diameterFraction+" x "+this.state.thickness+'"'
                        break;
                    case '1020':
                        name="Tubo de alumínio 1020 - "+diameterFraction+" x "+this.state.thickness+'"'

                        break;
                    case '6061T6':
                        name="Tubo 6061T6 - "+diameterFraction+" x "+this.state.thickness+'"'
                        break;
                    default:
                        name="Tubo - "+diameterFraction+" x "+this.state.thickness+'"'
                        break;
                }

            }

            this.setState({
                diameterFraction:diameterFraction + '"',
                kgPerMeter:weight,
                kgPerUnit:weight,
                name:name
            })
        }
        if(event.target.name==='thickness'){
            let weight=this.calculateWeights(this.state.diameter*25.4,event.target.value*25.4)
            let name=''
            if(this.state.selectedOption){
                switch(this.state.selectedOption){
                    case '4130':
                        name="Tubo de aço 4130 - "+this.state.diameterFraction+" x "+event.target.value+'"'
                        break;
                    case '1020':
                        name="Tubo de alumínio 1020 - "+this.state.diameterFraction+" x "+event.target.value+'"'

                        break;
                    case '6061T6':
                        name="Tubo 6061T6 - "+this.state.diameterFraction+" x "+event.target.value+'"'

                        break;
                    default:
                        name="Tubo - "+this.state.diameterFraction+" x "+event.target.value+'"'
                        break;
                }
            }


            this.setState({
                kgPerMeter:weight,
                kgPerUnit:weight,
                name:name
            })
        }


    }

    incluir(e){
        e.preventDefault()
        this.setState({isUpdating:true})
        //this.props.toggleIsUpdating(e)
        let produto={authorizationToken:this.props.token}
        if(this.state.description){produto["description"]=this.state.description}
        else{return}
        if(this.state.unity){produto["unity"]=this.state.unity}
        else{return}
        if(this.state.partNumber){produto["partNumber"]=this.state.partNumber}
        if(this.state.currency){produto["currency"]=this.state.currency}
        if(this.state.gramsPerSquareCentimeter){produto["gramsPerCentimeter"]=this.state.gramsPerSquareCentimeter.toString()}
        if(this.state.kgPerUnit){produto["kgPerUnit"]=this.state.kgPerUnit.toString()}
        if(this.state.kgPerMeter){produto["kgPerMeter"]=this.state.kgPerMeter.toString()}
        if(this.state.price){produto["price"]=this.state.price.toString()}
        if(this.state.name){produto["name"]=this.state.name}
        if(this.state.categoryId){produto["categoryId"]=this.state.categoryId}
        if(this.state.image){produto["image"]=this.state.image}
        if(this.state.Material){produto["Material"]=this.state.Material}
        if(this.state.size){produto["size"]=this.state.size}
        if(this.state.file){produto["file"]=this.state.file}
        fetch(API_URL+"/products/", {
            method: "POST",
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body:JSON.stringify(produto)
        })
            .then(response => response.json())
            .then(response => {
                if (response){
                    produto["productId"]=response
                    //update localStorage
                    let products=JSON.parse(localStorage.getItem("produtos"))
                    if(!products){
                        products=[]
                    }
                    products.push(produto)
                    localStorage.setItem("produtos",JSON.stringify(products))
                    //this.clear()
                    if(this.state.isMounted){
                        this.setState({isUpdating:false,hasUpdated:true})
                    }
                    this.props.updateProductList(produto)

                    // this.props.toggleUpdated(e)
                }

            })
            .catch(error => {
                console.error('Error:', error)
            })
        console.log(JSON.stringify(produto))
    }

    convertToFraction(number){

        let gcd = function(a, b) {
            if ( ! b) {
                return a;
            }

            return gcd(b, a % b);
        };
        let integerPart=number-number%1;
        let decimalPart=number-integerPart
        let denominator=1000
        let numerator=(decimalPart*denominator)/1
        let d=gcd(numerator,denominator)
        denominator=denominator/d
        numerator=numerator/d
        if(integerPart<1){
            return (numerator.toString()+"/"+denominator.toString())
        }
        if(numerator===0){
            return (integerPart.toString())

        }
        return (integerPart.toString() + " - "+numerator.toString()+"/"+denominator.toString())
    }

    showDetails(){
        let options=[
            {
                label:"aço 4130",
                value: "4130"
            },
            {
                label:"aço 1020",
                value: "1020"
            },
            {
                label:"alumínio 6061 T6",
                value: "6061T6"
            }
        ]
        return(
            <div>
                <h5 className="newProductTitle">Cadastrar tubo</h5>
                <div className="newProductContainer">
                    <div className="container">
                    <span className="dica">
                            <span role="img" aria-label="lightBulb">💡</span>
                    </span>
                        <span className="mostrar-dica">
                        <p>
                            Use este formulário para incluir um novo produto. A quantidade disponível será 0. Os níveis de estoque serão atualizados conforme compras / vendas forem confirmadas.
                        </p>

                    </span>

                        <div className="row">
                            <div className="Tubecol-25">Liga</div>
                            <div className="col-75">
                                 <Select
                                     onChange={this.handleSelect}
                                    options={options}

                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="Tubecol-25">Diâmetro</div>
                            <div>
                                <input
                                    type="number"
                                    step="0.0625"
                                    name="diameter"
                                    style={{height: 40}}
                                    onChange={
                                        (e) => this.handleChange(e)
                                    }
                                />
                            </div>
                            <div className="tubeUnity">
                                mm
                            </div>
                            <div className="tubeFraction">
                                {this.state.diameterFraction}
                            </div>
                        </div>

                        <div className="row">
                            <div className="Tubecol-25">Espessura</div>
                            <div>
                                <input
                                    type="number"
                                    step="0.0625"
                                    name="thickness"
                                    placeholder="Espessura"
                                    style={{height: 40}}
                                    onChange={
                                        (e) => this.handleChange(e)
                                    }
                                />
                            </div>
                            <div className="tubeUnity">
                                mm
                            </div>
                            <div className="validDimensions">
                                {this.isValidDimensions()}
                            </div>
                        </div>






                        <div className="row">
                            <div className="col-25">Imagem endereço:
                            </div>
                            <div className="col-75">
                                <input
                                    name="image"
                                    className="editClientDetailsInput"
                                    placeholder="Entre com o endereço da imagem do produto"
                                    style={{height: 40}}
                                    onChange={
                                        (e) =>this.handleChange(e)
                                    }
                                />
                            </div>
                        </div>






                        <div className="row">
                            <div className="col-25">Informações adicionais:
                            </div>
                            <div className="col-75">
                                <input
                                    name="description"
                                    className="editClientDetailsInput"
                                    placeholder="Digite uma informação adicional / descrição"
                                    style={{height: 40}}
                                    onChange={
                                        (e) =>this.handleChange(e)
                                    }
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">PN:
                            </div>
                            <div className="col-75">
                                <input
                                    name="partNumber"
                                    className="editClientDetailsInput"
                                    placeholder="O part number / número da peça"
                                    style={{height: 40}}
                                    onChange={
                                        (e) =>this.handleChange(e)
                                    }
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">Moeda:
                            </div>
                            <div className="col-75">
                                <input
                                    name="currency"
                                    className="editClientDetailsInput"
                                    placeholder="Apenas para registro. Programa não está habilitado a fazer conversões no momento."
                                    style={{height: 40}}
                                    onChange={
                                        (e) =>this.handleChange(e)
                                    }
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">Preço:
                            </div>
                            <div className="col-75">
                                <input
                                    name="price"
                                    className="editClientDetailsInput"
                                    placeholder="Preço para venda"
                                    style={{height: 40}}
                                    onChange={
                                        (e) =>this.handleChange(e)
                                    }
                                />
                            </div>
                        </div>

                        {this.showIncludeButton()}
                        {this.state.name}
                    </div>
                </div>
            </div>
        )

    }



    render() {
        if(this.state.hasUpdated){
            return(
                <div className="updating">
                    <h3>Product added</h3>

                    <p>
                        Thank you!
                    </p>
                    <p>Press Escape to return to main menu</p>
                </div>
            )
        }
        else{
            if(this.state.isUpdating){
                return (
                    <div className="updating">
                        <h3>Including new product</h3>
                        <p>
                            Please wait!
                        </p>
                    </div>
                )
            }
            else{
                return (
                    <div>
                    <span>
                    {this.showDetails()}
                    </span>

                    </div>
                );}
        }
    }

    addProduct(produto){
        let produtos=[]

        for(let i=0;i<this.state.produtos.length;i++){
            if(this.state.produtos[i].productId!==produto.productId)
                produtos.push(this.state.produtos[i])
        }
        produtos.push(produto)
        produtos=sortByKey(produtos,"description")
        this.setState(
            {
                produtos:produtos}
        )
        localStorage.setItem("produtos",JSON.stringify(produtos))



    }
}