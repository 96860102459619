import React from 'react';
import Draggable from 'react-draggable';

export default class ProductDetails extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            produto:this.props.produto
        }
    }

    showAttribute(attribute){
        if(attribute==="null"){
            return ""
        }
        else{
            return <span>{attribute}</span>
        }
    }


    showDetails(){
        //details are:

        //kgPerUnit
        //gramsPerSquareCentimeter
        //kgPerMeter
        if(this.props.produto){
            return(
                <div className="clientInfo">

                    <p>
                        <table>
                            <tbody>
                            <tr>
                                <th className="clientDetailLabel">Código Aerotubos</th>
                                <td>{this.props.produto.productId}</td>
                            </tr>
                            <tr>
                                <th className="clientDetailLabel">Nome</th>
                                <td>{this.props.produto.name}</td>
                            </tr>
                            <tr>
                                <th className="clientDetailLabel">Descrição</th>
                                <td>{this.props.produto.description}</td>
                            </tr>
                            <tr>
                                <th className="clientDetailLabel">Material</th>
                                <td>{this.props.produto.material}</td>
                            </tr>
                            <tr>
                                <th className="clientDetailLabel">Dimensões</th>
                                <td>{this.props.produto.size}</td>
                            </tr>
                            <tr>
                                <th className="clientDetailLabel">Categoria</th>
                                <td>{this.props.produto.categoryId}</td>
                            </tr>
                            <tr>
                                <th className="clientDetailLabel">PN</th>
                                <td>{this.props.produto.partNumber}</td>
                            </tr>
                            <tr>
                                <th className="clientDetailLabel">Unidade</th>
                                <td>{this.props.produto.unity}</td>
                            </tr>
                            <tr>
                                <th className="clientDetailLabel">Quantidade disponível</th>
                                <td>{this.showAttribute(this.props.produto.availability)}</td>
                            </tr>
                            <tr>
                                <th className="clientDetailLabel">Preço</th>
                                <td>{this.props.produto.price}</td>
                            </tr>
                            <tr>
                                <th className="clientDetailLabel">Moeda</th>
                                <td>{this.props.produto.currency}</td>
                            </tr>
                            <tr>
                                <th className="clientDetailLabel">Peso (kg/un)</th>
                                <td>{this.props.produto.kgPerUnit}</td>
                            </tr>
                            <tr>
                                <th className="clientDetailLabel">Peso/metro (kg/m)</th>
                                <td>{this.props.produto.kgPerMeter}</td>
                            </tr>
                            <tr>
                                <th className="clientDetailLabel">Peso / área (g/cm^2)</th>
                                <td>{this.props.produto.gramsPerSquareCentimeter}</td>
                            </tr>
                            <tr>
                                <th className="clientDetailLabel">Endereço imagem</th>
                                <td>{this.props.produto.image}</td>
                            </tr>

                            </tbody>
                        </table>
                    </p>
                </div>
            )
        }
        else{
            return ""
        }
    }

    render() {
        return (
            <Draggable>
                <div className="right">

                    {this.showDetails()}
                </div>
            </Draggable>
        );
    }
}