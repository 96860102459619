
import Fornecedor from "./Fornecedor";

export default class Fornecedores{

    constructor(fornecedores){
        this.fornecedores=[]
        if(fornecedores){
            if(fornecedores.length>0){
                let f=[]
                for(let i=0;i<fornecedores.length;i++){
                    f.push(new Fornecedor(fornecedores[i]))
                }
                this.fornecedores=f
            }
        }


    }


    getOptions(){
        let options=[]
        for(let i=0;i<this.fornecedores.length;i++){
            try{
                let fornecedor=this.fornecedores[i]
                let option={
                    value:fornecedor,
                    label:this.fornecedores[i].name
                }
                options.push(option)
            }
            catch(err){
                console.log(err)
            }
        }
        return options;
    }
    getFornecedores(){
        if(this.fornecedores){
            if(this.fornecedores.length>0){
                return this.fornecedores
            }
            else return []
        }
        return []
    }

    numberOfSuppliers(){return this.fornecedores.length}

}