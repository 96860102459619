import Transportador from "./Transportador";
import {sortByKey} from "../functions";
export default class ListaDeTransportadores{

    constructor(){
        this.transportadores=[];
        let temp=JSON.parse(localStorage.getItem("transportadores"));

        if(temp){
            for(let i=0;i<temp.length;i++){
                delete temp[i].refs
                delete temp[i].updater
            }
            if(temp.length>0){
                for(let i=0;i<temp.length;i++){
                    delete temp.refs;
                    delete temp.updater;
                }
                for(let i=0;i<temp.length;i++){
                    let freightCompany=new Transportador(temp[i])
                    let freightCompanyId=freightCompany.freightCompanyId
                    if(freightCompanyId){
                        this.addTransportador(temp[i])
                    }
                }
            }
        }
        this.transportadores=sortByKey(this.transportadores,"Nome")


    }

    //setters
    addTransportador(transportador){
        delete transportador.refs
        delete transportador.updater
        let existent=false;
        for(let i=0;i<this.transportadores.length;i++){
            let transportador2=this.transportadores[i]
            if(transportador.freightCompanyId===transportador2.freightCompanyId){
                existent=true;
                break;
            }
        }
        if(!existent){
            let transportadores=this.transportadores;
            transportadores.push(new Transportador(transportador))
            transportadores=sortByKey(transportadores,"Nome")
            this.transportadores=transportadores
            localStorage.setItem("transportadores",JSON.stringify(transportadores))
        }

    }
    changeFreightCompanyData(freightCompany){
        if(freightCompany){
            if("freightCompanyId" in freightCompany){
                let currentList=this.transportadores
                let newList=[]
                for(let i=0;i<currentList.length;i++){
                    if(currentList[i].freightCompanyId===freightCompany.freightCompanyId){
                        newList.push(new Transportador(freightCompany))
                    }
                    else{
                        newList.push(currentList[i])
                    }
                }
                this.transportadores=newList
                localStorage.setItem("transportadores",JSON.stringify(newList))
            }
        }
    }
    addTransportadores(transportadores){
        for(let i=0;i<transportadores.length;i++){
            this.addTransportador(transportadores[i])
        }
    }
    //getters
    getOptions(){
        let options=[]
        for(let i=0;i<this.transportadores.length;i++){
            try{
                let transportador=this.transportadores[i]
                let option={
                    value:transportador,
                    label:this.transportadores[i].Nome
                }
                options.push(option)
            }
            catch(err){
                console.log(err)
            }
        }
        return options;
    }
    getTransportador(transportadorId){
        let transportador=null
        for(let i=0;i<this.transportadores.length;i++){
            if(this.transportadores[i].getTransportadorId()===transportadorId){
                return this.transportadores[i]
            }
        }
        return transportador
    }
    getTransportadores(){
        return this.transportadores
    }
    replicate(){
        let newList=new ListaDeTransportadores()
        for(let i=0;i<this.transportadores.length;i++){
            let transportador=this.transportadores[i]
            newList.addTransportador(transportador)
        }
        return newList;
    }
}
