import React from 'react';
import Draggable from 'react-draggable';

export default class TransportadoresDetails extends React.Component {


    showAttribute(attribute){
        if(attribute==="null"){
            return ""
        }
        else{
            return <span>{attribute}</span>
        }
    }


    showDetails(){
        if(this.props.freightCompany){
            return(
                <div className="clientInfo">

                    <p>
                        <span className="clientDetailLabel">Número do Fornec.: </span>
                        <span>{this.props.freightCompany.freightCompanyId}</span>
                    </p>
                    <p>
                        <span className="clientDetailLabel">Nome: </span>
                        {this.showAttribute(this.props.freightCompany.Nome)}
                    </p>
                    <p>
                        <span className="clientDetailLabel">CNPJ:</span>
                        {this.showAttribute(this.props.freightCompany.CNPJ)}
                    </p><hr/>

                    <p>
                        <span className="clientDetailLabel">E-mail:</span>
                        {this.showAttribute(this.props.freightCompany.Email)}
                    </p>
                    <p>
                        <span className="clientDetailLabel">Telefone:</span>
                        {this.showAttribute(this.props.freightCompany.Telefone)}
                    </p>
                    <hr/>
                    <p>
                        <span className="clientDetailLabel">Endereço: </span>
                        {this.showAttribute(this.props.freightCompany.Endereco)}
                    </p>
                    <p>
                        <span className="clientDetailLabel">Bairro:</span>
                        {this.showAttribute(this.props.freightCompany.Bairro)}
                    </p>
                    <p>
                        <span className="clientDetailLabel">Município:</span>
                        {this.showAttribute(this.props.freightCompany.Municipio)}
                    </p>
                    <p>
                        <span className="clientDetailLabel">Estado:</span>
                        {this.showAttribute(this.props.freightCompany.Estado)}
                    </p>
                    <p>
                        <span className="clientDetailLabel">CEP:</span>
                        {this.showAttribute(this.props.freightCompany.CEP)}
                    </p>
                    <p>
                        <span className="clientDetailLabel">Caixa Postal: </span>
                        {this.showAttribute(this.props.freightCompany.CaixaPostal)}
                    </p>
                </div>
            )
        }
        else{
            return ""
        }
    }

    render() {
        return (
            <Draggable>
                <div className="right">

                    {this.showDetails()}
                </div>
            </Draggable>
        );
    }
}