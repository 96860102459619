import React from 'react';
import Draggable from 'react-draggable';

export default class ClientDetails extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            client:this.props.client
        }
    }

    showAttribute(attribute){
        if(attribute==="null"){
            return ""
        }
        else{
            return <span>{attribute}</span>
        }
    }


    showDetails(){
        console.log("Client details: "+this.props.client)
        if(this.props.client){
            return(
                <div className="clientInfo">

                    <p>
                        <span className="clientDetailLabel">ID: </span>
                        <span>{this.props.client.clientId}</span>
                    </p>
                    <p>
                        <span className="clientDetailLabel">NOME: </span>
                        {this.showAttribute(this.props.client.name)}
                    </p>
                    <p>
                        <span className="clientDetailLabel">CONSIGNADO: </span>
                        {this.showAttribute(this.props.client.consigned)}
                    </p>
                    <hr/>
                    <p>
                        <span className="clientDetailLabel">TEL FIXO:</span>
                        {this.showAttribute(this.props.client.landlineNumber)}
                    </p>
                    <p>
                        <span className="clientDetailLabel">CELULAR TIM:</span>
                        {this.showAttribute(this.props.client.mobileNumber)}

                    </p>
                    <p>
                        <span className="clientDetailLabel">CELULAR OI:</span>
                        {this.showAttribute(this.props.client.phone1)}

                    </p>
                    <p>
                        <span className="clientDetailLabel">CELULAR VIVO:</span>
                        {this.showAttribute(this.props.client.phone2)}

                    </p>
                    <p>
                        <span className="clientDetailLabel">CELULAR CLARO:</span>
                        {this.showAttribute(this.props.client.phone3)}

                    </p>
                    <p>
                        <span className="clientDetailLabel">CELULAR NEXTEL:</span>
                        {this.showAttribute(this.props.client.phone4)}

                    </p>
                    <p>
                        <span className="clientDetailLabel">Whatsapp:</span>
                        {this.showAttribute(this.props.client.whatsapp)}

                    </p>
                    <p>
                        <span className="clientDetailLabel">E-MAIL:</span>
                        {this.showAttribute(this.props.client.email)}
                    </p>
                    <p>
                        <span className="clientDetailLabel">E-MAIL SECUNDÁRIO:</span>
                        {this.showAttribute(this.props.client.email2)}
                    </p>
                    <hr/>
                        <p>
                            <span className="clientDetailLabel">ENDEREÇO: </span>
                            {this.showAttribute(this.props.client.address)}
                        </p>
                        <p>
                            <span className="clientDetailLabel">BAIRRO:</span>
                            {this.showAttribute(this.props.client.suburb)}
                        </p>
                        <p>
                            <span className="clientDetailLabel">MUNICÍPIO:</span>
                            {this.showAttribute(this.props.client.city)}
                        </p>
                        <p>
                            <span className="clientDetailLabel">ESTADO:</span>
                            {this.showAttribute(this.props.client.state)}
                        </p>
                        <p>
                            <span className="clientDetailLabel">CEP:</span>
                            {this.showAttribute(this.props.client.zipCode)}
                        </p>
                        <p>
                            <span className="clientDetailLabel">CAIXA POSTAL: </span>
                            {this.showAttribute(this.props.client.poBox)}
                        </p>
                    <p>
                        <span className="clientDetailLabel">BANCO PREFERIDO: </span>
                        {this.showAttribute(this.props.client.bankPreference)}
                    </p>
                </div>
            )
        }
        else{
            return ""
        }
    }

    render() {
        return (
            <Draggable>
            <div className="right">

                {this.showDetails()}
            </div>
            </Draggable>
        );
    }
}