import Client from "./Client";
import Item from "./Item";
import Produto from "./Produto";
import ListaDeClientes from "./ListaDeClientes";
import Stock from "./Stock";
import {RequestForQuote} from "./RequestForQuote";
export class Quotation{
    constructor(cotacao){
        this.itens=[];
        this.cliente=new Client();
        this.quotationId=' ';
        this.transportador=' ';
        this.valorTransporte=0;
        this.desconto=0;
        this.observacoes=' ';
        this.emissao=' ';
        this.validade=' ';
        this.contaBancaria=' ';
        this.clienteResponsavelPeloFrete=true;
        this.quoteStatus=' ';
        this.bank={bankId: "No bank selected"}
        if(cotacao){
            if("itens" in cotacao){
                if(cotacao.itens.length>0){
                    for(let i=0;i<cotacao.itens;i++){
                        this.itens.push(new Item(cotacao.itens[i]))
                    }
                }
                this.itens=cotacao.itens
            }
            if("cliente" in cotacao){this.cliente=new Client(cotacao.cliente)}
            if("quotationId" in cotacao){this.quotationId=cotacao.quotationId}
            if("transportador" in cotacao){this.transportador=cotacao.transportador}
            if("valorTransporte" in cotacao){this.valorTransporte=cotacao.valorTransporte}
            if("desconto" in cotacao){this.desconto=cotacao.desconto}
            if("observacoes" in cotacao){this.observacoes=cotacao.observacoes}
            if("validade" in cotacao){this.validade=cotacao.validade}
            if("contaBancaria" in cotacao){this.contaBancaria=cotacao.contaBancaria}
            if("clienteResponsavelPeloFrete" in cotacao){this.clienteResponsavelPeloFrete=cotacao.clienteResponsavelPeloFrete}
            if("quoteStatus" in cotacao){this.quoteStatus=cotacao.quoteStatus}
            if("requestId" in cotacao){this.requestId=cotacao.requestId}
            if("requestForQuote" in cotacao){this.requestForQuote=new RequestForQuote(cotacao.requestForQuote)}
        }


    }

    //not sure I am using this anymore
    static buildQuote(cotacao,listaDeClientes,estoque){
        let cliente=null;

        try{
            cliente=new Client(cotacao.cliente)
        }catch(err){console.log(err)}
        if(!cliente){
            cliente=new Client()
        }
        let itens=null
        let newCotacao=null
        try{
            newCotacao=new Quotation(cliente,
                cotacao.getTransportador(),cotacao.getValorTransporte(),
                cotacao.getDesconto(),cotacao.getObservacoes(),cotacao.getEmissao(),
                cotacao.getValidade(),cotacao.getContaBancaria,
                cotacao.isClientPayingFreight(),cotacao.getStatus(),cotacao.getQuotationId(),cotacao.getItens());
        }catch(err){
            newCotacao=new Quotation(cliente)
        }
        try{
            itens=cotacao.getItens()
        }catch(err){
            itens=[]
        }


        for(let i=0;i<itens.length;i++){
            let productId=itens[i].produto.productId
            let price = itens[i].produto.price
            //copies product from stock, ensuring price is the
            //same as originally set when sending the quote
            let produto= Produto.buildProduct(productId,estoque,price)
            let quantity=cotacao.itens[i].quantity
            let item=new Item(produto,quantity)
            newCotacao.addItem(item)
        }
        if(newCotacao){
            return newCotacao
        }
        else{
            return (new Quotation())
        }
    }

    parseQuote(cotacao){
        try{
            let itens=[]
            for(let i=0;i<cotacao.itens.length;i++){
                let produto=new Produto(cotacao.itens[i].produto)
                let quantity=cotacao.itens[i].quantity
                let item = new Item(produto,quantity)
                itens.push(item)
            }
            this.itens=itens
        }catch(e){
            this.itens=[]
        }
        try{
            let cliente=new Client(cotacao.cliente)
            this.cliente=cliente
        }catch(e){
            this.cliente=new Client()
        }
        try{this.transportador=cotacao.transportador}catch(e){
            this.transportador=null
        }
        try{this.valorTransporte=cotacao.valorTransporte}catch(e){
            this.valorTransporte=null
        }
        try{this.desconto=cotacao.desconto}catch(e){this.desconto=null};
        try{this.observacoes=cotacao.observacoes}catch(e){this.observacoes=null};
        try{this.emissao=cotacao.emissao}catch(e){this.emissao=null};
        try{this.validade=cotacao.validade}catch(e){this.validade=null};
        try{this.contaBancaria=cotacao.contaBancaria}catch(e){this.contaBancaria=null};
        try{this.clienteResponsavelPeloFrete=cotacao.clienteResponsavelPeloFrete}catch(e){this.clienteResponsavelPeloFrete=true};
        try{this.quoteStatus=cotacao.quoteStatus}catch(e){this.quoteStatus=null}
        try{this.quotationId=cotacao.quotationId}catch(e){this.quotationId=null}
        if("requestForQuote" in cotacao){this.requestForQuote=new RequestForQuote(cotacao.requestForQuote)}
        if("requestId" in cotacao){this.requestId=cotacao.requestId}
        if("bank" in cotacao){this.bank=cotacao.bank}
    }

    copy(cotacao){
        this.itens=cotacao.getItens();
        this.cliente=cotacao.getClient();
        this.transportador=cotacao.getTransportador();
        this.valorTransporte=cotacao.getValorTransporte();
        this.desconto=cotacao.getDesconto();
        this.observacoes=cotacao.getObservacoes();
        this.emissao=cotacao.getEmissao();
        this.validade=cotacao.getValidade();
        this.contaBancaria=cotacao.getContaBancaria();
        this.clienteResponsavelPeloFrete=cotacao.isClientPayingFreight();
        if("requestForQuote" in cotacao){this.requestForQuote=new RequestForQuote(cotacao.requestForQuote)}
        if("requestId" in cotacao){this.requestId=cotacao.requestId}
        if("bank" in cotacao){this.bank=cotacao.bank}
    }
    //setters
    setQuotationId(quotationId){
        this.quotationId=quotationId
    }
    setItens(itens){
        this.itens=itens
    }

    getBankName(){
        if("bank" in this){
            if("bankName" in this.bank){
                return this.bank.bankName
            }
        }
    }


    addItem(item){
        //uncomment below if duplicate not to be allowed
        // for(let i=0;i<this.itens.length;i++){
        //     if(item.isDuplicate(this.itens[i])){
        //         return "Error. Item is duplicate"
        //     }
        // }

        //if sheet is sold in pieces, increase by 10%
        if("type" in item.produto){
            if(item.produto.type)
            {
                if(item.produto.type.toLowerCase()==='sheet'){
                    if(item.quantity<1){
                        let price=item.produto.price*1.1
                        item.produto.setPrice(price)

                    }
                }
            }
        }
        let itens=this.itens;
        itens.push(item);
        this.itens=itens;
    }
    setClient(cliente){
        this.cliente=new Client(cliente)
    }
    setTransportador(transportador){
        this.transportador=transportador;
    }
    removeItem(productId){
        let itens=[];
        for(let i=0;i<this.itens.length;i++){
            if(!(this.itens[i].getProduto().getProductId()===productId)){
                itens.push(this.itens[i])
            }
        }
        this.itens=itens;
    }

    //will match both name, description and quantities
    removeSpecificItem(item){
        let itens=[]
        for(let i=0;i<this.itens.length;i++){
            if(
                (this.itens[i].produto.productId!==item.produto.productId)||
                (this.itens[i].produto.name!==item.produto.name)||
                (this.itens[i].quantity.toString()!==item.quantity.toString())||
                (this.itens[i].produto.description!==item.produto.description)
            ){
                itens.push(this.itens[i])
            }

        }
        this.itens=itens;
    }


    setValorDoTransporte(custo){
        this.valorTransporte=custo
    }
    setResponsibleForFreight(responsible){
        if(responsible==="cliente"){
            this.clienteResponsavelPeloFrete=true
        }
        else{
            this.clienteResponsavelPeloFrete=false
        }
    }
    setDesconto(desconto){
        this.desconto=desconto
    }

    //getters
    getSubtotalDosProdutos(){
        let subtotal=0;
        for(let i=0;i<this.itens.length;i++){
            subtotal=(subtotal*1+(this.itens[i].produto.price*1)*((this.itens[i].quantity*1)))*1
        }
        return subtotal
    }
    getTotal(){
        let total=this.getSubtotalDosProdutos();
        if(this.clienteResponsavelPeloFrete){
            total=(total*1+this.valorTransporte*1)*1;
        }
        if(this.desconto){
            total=(total*1-this.desconto*1)*1;
        }

        return total;
    }
    getQuotationId(){
        if("quotationId" in this){
            return this.quotationId
        }
        return null
    }
    getItens(){
        if("itens" in this){
            if(this.itens){
            return this.itens}
        }
        return []
    }
    getClient(){
        if(this.cliente){
        return this.cliente}
        return null
    }
    getClientName(){
        if("cliente" in this){
            if("name" in this.cliente){
                return this.cliente.name
            }
        }
            return ""

    }
    setBank(bank){
        this.bank=bank
    }

    getTransportador(){
        if("transportador" in this){
            return this.transportador
        }
        return null
    }
    getTransportadorName(){
        if("transportador" in this){
            return this.transportador.getNome()
        }
        return null
    }
    getValorTransporte(){
        if("valorTransporte" in this){
            return this.valorTransporte
        }
        return null
    }
    getDesconto(){
        if("desconto" in this){
            return this.desconto
        }
        return null
    }
    getObservacoes(){
        if("observacoes" in this){
            return this.observacoes
        }
        return ""
    }
    getEmissao(){
        if("emissao" in this){
            return this.emissao
        }
        return null
    }
    getValidade(){
        if("validade" in this){
            return this.validade
        }
        return null
    }
    getExpiryDate(){
        if("emissao" in this){
            if("validade in this"){
                return this.emissao+this.validade
            }
        }
        return null;
    }
    getContaBancaria(){
        if("contaBancaria" in this){
            return this.contaBancaria
        }
        return null
    }
    isClientPayingFreight(){
        if("clienteResponsavelPeloFrete" in this){
            return this.clienteResponsavelPeloFrete
        }
        return null
    }

    whoPays(){
        if(this.isClientPayingFreight())
        {
            return "Cliente"
        }
            return "Aerotubos"

    }

    getSituacao(){
        if("quoteStatus" in this){
            return this.quoteStatus
        }
        return null
    }

    isReadyToSubmit(){
        if(!this.itens){return false}
        if(this.itens.length===0){return false}
        if(!("cliente" in this)){return false}
        if(!("clientId" in this.cliente)){return false}
        return true
    }

    setOriginatingRequest(requestForQuote){
        this.requestId=requestForQuote.requestId
        this.requestForQuote=requestForQuote
        //try and set client too, search by email
        if("email" in requestForQuote){
            let email=requestForQuote.email
            let listaDeClientes=new ListaDeClientes()
            let client=listaDeClientes.getClientByEmail(email)
            if(client){
                this.cliente=client;
                this.clientId=client.getClientId()
            }
        }
        //try and add the product items
        let stock=new Stock()
        let requestItems=requestForQuote.items
        for(let i=0;i<requestItems.length;i++){
            let requestItem=requestItems[i]
            let productId=requestItem.productId
            let product=stock.getProduct(productId)
            let quantity=requestItem.quantity
            let item=new Item(product,quantity)
            this.itens.push(item)
        }

    }

    getRequestId(){
        if("requestId" in this){return this.requestId}
    }


}