import React from 'react';
import {FaAddressCard} from 'react-icons/fa'
import {sortByKey} from "../../functions";
import '../../css/form.css';
import config from '../../config.js'
const API_URL = config.api_endpoint
const plane2 = require('../../assets/flying2.gif');
const plane3 = require('../../assets/flying4.gif');
export default class NewTransportadorForm extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            Nome: '',
            freightCompanyId:'',
            Consignado: '',
            CPF_CNPJ: '',
            Telefone: '',
            Endereco: '',
            Bairro:'',
            CEP: '',
            Municipio: '',
            Estado: '',
            IE: '',
            Email: '',
            TelefoneTim: '',
            TelefoneVivo:'',
            TelefoneOi: '',
            TelefoneClaro: '',
            Celular:'',
            CaixaPostal: '',
            PreferenciaBancaria:'',
            Email2: '',
            isUpdating:false,
            hasUpdated:false,
            updateSuccessful:false,
            authorizationToken:this.props.token
        }
        this.incluir=this.incluir.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.clear=this.clear.bind(this)
    }

    clear(){
        this.setState({
            Nome: '',
            freightCompanyId:'',
            Consignado: '',
            CPF_CNPJ: '',
            Telefone: '',
            Endereco: '',
            Bairro:'',
            CEP: '',
            Municipio: '',
            Estado: '',
            IE: '',
            Email: '',
            TelefoneTim: '',
            TelefoneVivo:'',
            TelefoneOi: '',
            TelefoneClaro: '',
            Celular:'',
            CaixaPostal: '',
            PreferenciaBancaria:'',
            Email2: ''
        })
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });

    }

    incluir(e, transportador){
        e.preventDefault()
        this.props.toggleIsUpdating(e)
        fetch(API_URL+"/freightCompanies/", {
            method: "POST",
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body:JSON.stringify({
                "Nome": transportador.Nome,
                "Consignado": transportador.Consignado,
                "CPF_CNPJ": transportador.CPF_CNPJ,
                "Telefone": transportador.Telefone,
                "Endereco": transportador.Endereco,
                "Bairro":transportador.Bairro,
                "CEP": transportador.Bairro,
                "Municipio": transportador.Municipio,
                "Estado": transportador.Estado,
                "IE": transportador.IE,
                "Email": transportador.Email,
                "TelefoneTim": transportador.TelefoneTim,
                "TelefoneVivo":transportador.TelefoneVivo,
                "TelefoneOi": transportador.TelefoneOi,
                "TelefoneClaro": transportador.TelefoneClaro,
                "Celular":transportador.Celular,
                "CaixaPostal": transportador.CaixaPostal,
                "PreferenciaBancaria":transportador.PreferenciaBancaria,
                "Email2": transportador.Email2,
                authorizationToken:this.props.token
            })
        })
            .then(response => response.json())
            .then(response => {
                if (response){
                    transportador["freightCompanyId"]=response
                    this.setState({
                        freightCompanyId:response,
                        isUpdating:false,
                        updated:true,
                        updateSuccessful:true

                    })

                    this.clear()
                    //add the transport company to the localStorage array
                    let transportadores = JSON.parse(localStorage.getItem("transportadores"))
                    if(!transportadores){
                        transportadores=[]
                    }
                    transportadores.push(transportador)
                    transportadores=sortByKey(transportadores,"Nome")
                    localStorage.setItem("transportadores",JSON.stringify(transportadores))
                    this.props.toggleUpdated(e)
                    this.props.updateFreightCompanies(transportador)
                }

            })
            .catch(error => {
                if(transportador.freightCompanyId.includes("AERT")){
                    this.clear()
                    //add the transport company to the localStorage array
                    let transportadores = JSON.parse(localStorage.getItem("transportadores"))
                    if(!transportadores){
                        transportadores=[]
                    }
                    transportadores.push(transportador)
                    transportadores=sortByKey(transportadores,"Nome")
                    localStorage.setItem("transportadores",JSON.stringify(transportadores))
                    this.props.updateFreightCompanies(transportador)
                    this.setState({
                        isUpdating:false,
                        updated:true,
                        updateSuccessful:true})
                }
                else{
                    alert('Transportador não foi incluído no banco de dados. Tente novamente.')}

                console.error('Error:', error)
            })


    }

    addId(freightCompanyId){
        return {
            "freightCompanyId": freightCompanyId,
            "Nome": this.state.Nome,
            "Consignado": this.state.Consignado,
            "CPF_CNPJ": this.state.CPF_CNPJ,
            "Telefone": this.state.Telefone,
            "Endereco": this.state.Endereco,
            "Bairro":this.state.Bairro,
            "CEP": this.state.Bairro,
            "Municipio": this.state.Municipio,
            "Estado": this.state.Estado,
            "IE": this.state.IE,
            "Email": this.state.Email,
            "TelefoneTim": this.state.TelefoneTim,
            "TelefoneVivo":this.state.TelefoneVivo,
            "TelefoneOi": this.state.TelefoneOi,
            "TelefoneClaro": this.state.TelefoneClaro,
            "Celular":this.state.Celular,
            "CaixaPostal": this.state.CaixaPostal,
            "PreferenciaBancaria":this.state.PreferenciaBancaria,
            "Email2": this.state.Email2
        }
    }

    showDetails(){
        return(
            <div className="formDetails">
                <p>
                    <p className="formLabel">Company:</p>
                    <input
                        name="Nome"
                        className="formDetailInput"
                        placeholder="Type the company name"
                        style={{height: 40}}
                        onChange={
                            (e) =>this.handleChange(e)
                        }

                    />
                </p>
                <p className="formLabel">Consigned:
                    <input
                        name="Contato:"
                        onChange={
                            (e) =>this.handleChange(e)
                        }
                        className="formDetailInput"
                        placeholder="Contact name"
                        style={{height: 40}}
                    />
                </p>
                <p>
                    <span className="formLabel">Business number:</span>
                    <input
                        className="formDetailInput"
                        placeholder="Type the business number"
                        style={{height: 40}}
                        onChange={
                            (e) =>this.handleChange(e)
                        }
                        name="CPF_CNPJ"

                    />
                </p>
                <p>
                    <span className="formLabel">State Registration:</span>
                    <input
                        name="IE"
                        className="formDetailInput"
                        placeholder="Type the state registration number"
                        style={{height: 40}}
                        onChange={
                            (e) =>this.handleChange(e)
                        }

                    />

                </p>
                <span className="formLabel"><br/>
                    E-mail:
                    <input
                        name="Email"
                        className="formDetailInput"
                        placeholder="Type the primary email address"
                        style={{height: 40}}
                        onChange={
                            (e) =>this.handleChange(e)
                        }

                    />
                </span>
                <span className="formLabel"><br/>
                    E-mail (sec):
                    <input
                        name="Email2"
                        className="formDetailInput"
                        placeholder="Enter an optional email address"
                        style={{height: 40}}
                        onChange={
                            (e) =>this.handleChange(e)
                        }

                    />
                </span>
                <p>
                        <span className="formLabel">Tel:
                        <input
                            name="Landline"
                            className="formDetailInput"
                            placeholder={this.state.Telefone}
                            style={{height: 40}}
                            onChange={
                                (e) =>this.handleChange(e)
                            }

                        />
                        </span>
                    <span className="formLabel">
                             Phone 1</span>
                    <input
                        name="TelefoneTim"
                        className="formDetailInput"
                        placeholder={this.state.TelefoneTim}
                        style={{height: 40, borderColor: 'gray', borderWidth: 1}}
                        onChange={
                            (e) =>this.handleChange(e)
                        }

                    />
                </p>
                <p>
                    <span> Phone 2
                        <input
                            name="TelefoneVivo"
                            className="formDetailInput"
                            placeholder={this.state.TelefoneVivo}
                            style={{height: 40, borderColor: 'gray', borderWidth: 1}}
                            onChange={
                                (e) =>this.handleChange(e)
                            }

                        />
                    </span>
                    <span> Phone 3
                        <input
                            name="TelefoneOi"
                            className="formDetailInput"
                            placeholder={this.state.TelefoneOi}
                            style={{height: 40, borderColor: 'gray', borderWidth: 1}}
                            onChange={
                                (e) =>this.handleChange(e)
                            }

                        />
                    </span></p>
                <p> Phone 4
                    <input
                        name="TelefoneClaro"
                        className="formDetailInput"
                        placeholder={this.state.TelefoneClaro}
                        style={{height: 40, borderColor: 'gray', borderWidth: 1}}
                        onChange={
                            (e) =>this.handleChange(e)
                        }

                    />
                    Mobile
                    <input
                        name="Celular"
                        className="formDetailInput"
                        placeholder={this.state.Celular}
                        style={{height: 40, borderColor: 'gray', borderWidth: 1}}
                        onChange={
                            (e) =>this.handleChange(e)
                        }

                    />

                </p><hr/>
                <p>
                    <FaAddressCard/> Address:
                    <input
                        name="Endereco"
                        className="formDetailInput"
                        placeholder={this.state.Endereco}
                        style={{height: 40, borderColor: 'gray', borderWidth: 1}}
                        onChange={
                            (e) =>this.handleChange(e)
                        }

                    />
                </p>
                <p>
                    <span className="clientDetailLabel">Suburb:</span>
                    <input
                        name="Bairro"
                        className="formDetailInput"
                        placeholder={this.state.Bairro}
                        style={{height: 40, borderColor: 'gray', borderWidth: 1}}
                        onChange={
                            (e) =>this.handleChange(e)
                        }

                    />
                </p>
                <p>
                    <span className="clientDetailLabel">City:</span>
                    <input
                        name="Municipio"
                        className="formDetailInput"
                        placeholder={this.state.Municipio}
                        style={{height: 40, borderColor: 'gray', borderWidth: 1}}
                        onChange={
                            (e) =>this.handleChange(e)
                        }

                    />
                </p>
                <p>
                    <span className="clientDetailLabel">State:</span>
                    <input
                        name="Estado"
                        className="formDetailInput"
                        placeholder={this.state.Estado}
                        style={{height: 40, borderColor: 'gray', borderWidth: 1}}
                        onChange={
                            (e) =>this.handleChange(e)
                        }

                    />
                </p>
                <p>
                    <span className="clientDetailLabel">Zip Code:</span>
                    <input
                        name="CEP"
                        className="formDetailInput"
                        placeholder={this.state.CEP}
                        style={{height: 40, borderColor: 'gray', borderWidth: 1}}
                        onChange={
                            (e) =>this.handleChange(e)
                        }

                    />
                </p>
                <p>
                    <span className="clientDetailLabel">PO BOX: </span>
                    <input
                        name="CaixaPostal"
                        className="formDetailInput"
                        placeholder={this.state.CaixaPostal}
                        style={{height: 40, borderColor: 'gray', borderWidth: 1}}
                        onChange={
                            (e) =>this.handleChange(e)
                        }

                    />
                </p>
                <div className="buttonWrapper">
                    <button
                        onClick={
                            (e)=>{
                                e.preventDefault()
                                this.incluir(e, this.state)
                            }
                        }
                    >Add</button>
                </div>
                <br/>

            </div>
        )

    }

    render() {
        if(this.state.hasUpdated){
            return(
                <div>
                    <div className="updating">
                        <h3>Freight Company added</h3>
                        <img src={plane3} aria-hidden alt="background image"/>
                        <p>
                            Obrigado!
                        </p>
                    </div></div>
            )
        }
        else{
            if(this.state.isUpdating){
                return (
                    <div >
                        <div className="updating">
                            <h3>Adding company...</h3>
                            <img src={plane2} aria-hidden alt="background image"/>
                            <p>
                                Por favor, aguarde!
                            </p>
                        </div>
                    </div>
                )
            }
            else{
                return (
                    <div className="NewSupplierForm">
                    <span>
                    {this.showDetails()}
                    </span>

                    </div>
                );}
        }
    }
}