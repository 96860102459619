import Fornecedor from "./Fornecedor";
import {sortByKey} from "../functions";
export default class SupplierList{

    constructor(){
        this.fornecedores=[];
        let temp=JSON.parse(localStorage.getItem("fornecedores"));

        if(temp){
            for(let i=0;i<temp.length;i++){
                delete temp.refs;
                delete temp.updater;
            }
            if(temp.length>0){
                for(let i=0;i<temp.length;i++){
                    let supplier=new Fornecedor(temp[i])
                    let supplierId=supplier.supplierId
                    if(supplierId){
                        this.addFornecedor(temp[i])
                    }
                }
            }
        }


    }

    //setters
    addFornecedor(fornecedor){
        try{
        let existent=false;
        for(let i=0;i<this.fornecedores.length;i++){
            let fornecedor2=this.fornecedores[i]
            if(fornecedor.supplierId===fornecedor2.supplierId){
                existent=true;
                break;
            }
        }
        if(!existent){
            let fornecedores=this.fornecedores;
            fornecedores.push(new Fornecedor(fornecedor))
            fornecedores=sortByKey(fornecedores,"name")
            this.fornecedores=fornecedores
            localStorage.setItem("fornecedores",JSON.stringify(fornecedores))
        }}catch(err){console.log(err)}

    }
    changeSupplierData(supplier){
        if(supplier){
            if("supplierId" in supplier){
                let currentList=this.fornecedores
                let newList=[]
                for(let i=0;i<currentList.length;i++){
                    if(currentList[i].supplierId===supplier.supplierId){
                        newList.push(new Fornecedor(supplier))
                    }
                    else{
                        newList.push(currentList[i])
                    }
                }
                this.fornecedores=newList
                localStorage.setItem("fornecedores",JSON.stringify(newList))
            }
        }
    }
    addFornecedores(fornecedores){
        if(fornecedores){
            if(fornecedores.length>0){
                for(let i=0;i<fornecedores.length;i++){
                    this.addFornecedor(fornecedores[i])
                }
            }
        }

    }
    //getters
    getOptions(){
        let options=[]
        for(let i=0;i<this.fornecedores.length;i++){
            try{
                let fornecedor=this.fornecedores[i]
                let option={
                    value:fornecedor,
                    label:this.fornecedores[i].name
                }
                options.push(option)
            }
            catch(err){
                console.log(err)
            }
        }
        return options;
    }
    getFornecedor(fornecedorId){
        let fornecedor=null
        for(let i=0;i<this.fornecedores.length;i++){
            if(this.fornecedores[i].supplierId===fornecedorId){
                return this.fornecedores[i]
            }
        }
        return fornecedor
    }
    getFornecedores(){
        return this.fornecedores
    }
    replicate(){
        let newList=new SupplierList()
        for(let i=0;i<this.fornecedores.length;i++){
            let fornecedor=this.fornecedores[i]
            newList.addFornecedor(fornecedor)
        }
        return newList;
    }
}
