import React from 'react';
import Select from 'react-select';
import '../../../../css/cotacao.css'

export default class QuoteClient extends React.Component {

    showSelectBank(){

        if(this.props.banksList){
            let placeholder=null;
            try{
                placeholder=this.props.cotacao.bank.bankName+" / "+this.props.cotacao.bank.accountHolder
            }catch(err){
                placeholder="Select a client"
            }
            return(
                <div>
                    <div className="clientSelectionTitle">
                        Selecione o banco
                    </div>
                    <Select
                        placeholder={placeholder}
                        options={this.props.banksList.getOptions()}
                        onChange={this.props.setBank}
                    />
                </div>
            )
        }
    }



    render() {
        let placeholder=null;

        try{
            placeholder=this.props.cotacao.cliente.name
        }catch(err){
            placeholder="Select a client"
        }


        return (
            <div className="clientSelection">
                <div className="clientSelectionTitle">
                    Selecione o cliente para o qual a cotação será enviada.
                </div>
                <br/>
                <Select
                    placeholder={placeholder}
                    options={this.props.options}
                    onChange={this.props.setClient}
                />
                {this.showSelectBank()}



            </div>
        )
    }
}