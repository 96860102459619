import React from 'react';
import '../../../css/visualizarCotacoes.css'

export default class NewQuotationInstructions extends React.Component {
    constructor(props) {
        super(props);
        this.state={

        }

    }


    render() {
        return(
            <div className="quotationBuilderInstructions">
                <h3>Quotation Builder Instructions</h3>
                <ul>
                    <li>Select a Client from the Client menu bar</li>
                    <li>Select the Products you want to add in this quote from the Products menu bar</li>
                    <li>Select a transport company and add freight charges from the Transport section (optional)</li>
                    <li>Review quote for final submission from the Review Section. You can add a discount in this section before submission</li>
                </ul>
                <h4>Note: If you arrived here from the 'Convert to Quote' tool in the request section, please go straight to Transport and Review sections so that you do not change the client.</h4>
                <h5>If the request is coming from a client who is not in the database yet (this will be shown in the bottom screen of the Review page), please add the client before submtting a quote. You can go back to requests and add the new client from there.</h5>
            </div>
        )

    }
}