import React from 'react';
import '../../../css/visualizarCotacoes.css'
import QuotationProductDetails from "./quote_tab_components/QuotationProductDetails";
import EnviarProdutos from "./OpenQuoteComponents/EnviarProdutos";
import {Quotation} from "../../../model/Quotation";
import Client from "../../../model/Client";
import config from '../../../config.js'
import Invoices from "../../../model/Invoices";
import Select from "react-select/";

const API_URL = config.api_endpoint;
export default class ReadyToShip extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            links:this.showLinks(),
            quoteDetails:'',
            selectedQuote: null,
            enviarProdutos:false,
            atualizarPagamento:false,
            enviando:false,
            shipped:false
        }
        this.hideActions=this.hideActions.bind(this)
        this.toggleEnviando=this.toggleEnviando.bind(this)
        this.shipProducts=this.shipProducts.bind(this)
    }

    toggleEnviando(e){
        e.preventDefault();
        this.setState({
            enviando:!this.state.enviando
        })
        this.hideActions(e)
    }

    handleChange = (selectedOption) => {
        console.log(selectedOption)
        let quotation=selectedOption.value
        this.setState({
            selectedQuote:quotation,
            quoteDetails:
                <div className="openQuoteContainer">

                    <table className="openQuoteClientTable">
                        <tbody>
                        <tr>
                            <th className="label">ID</th>
                            <th className="label">Name</th>
                            <th className="label">Email</th>
                            <th className="label">Owing balance</th>
                        </tr>
                        <tr>

                            <td className="value">{quotation.cliente.clientId}</td>
                            <td className="value">{quotation.cliente.name}</td>
                            <td className="value">{quotation.cliente.email}</td>
                            <td className="value">{this.props.listaDeClientes.getClientBalance(quotation.cliente.clientId)}</td>
                        </tr>
                        </tbody>
                    </table>
                    <p>
                        {quotation.quotationId}
                    </p>
                    <QuotationProductDetails
                        quotation={new Quotation(quotation)}
                    />
                </div>,
            enviarProdutos:false,
            atualizarPagamento:false
        })

    }

    componentWillMount(){
        this.setState({
            links:this.showLinks(),
            enviarProdutos:false,
            atualizarPagamento:false
        })
    }

    showLinks(){
        console.log('links')
        console.log(this.props.quotes.quotations)
        return (this.props.quotes.quotations.map(function(item, i){
            return(
                <a
                    href="/#"
                    key={i}
                    onClick={(e)=>{
                        e.preventDefault();
                        this.setState({
                            selectedQuote:item.quotationId,
                            quoteDetails:
                                <div className="openQuoteContainer">

                                    <table className="openQuoteClientTable">
                                        <tbody>
                                        <tr>
                                            <th className="label">ID</th>
                                            <th className="label">Name</th>
                                            <th className="label">Email</th>
                                            <th className="label">Owing balance</th>
                                        </tr>
                                        <tr>
                                            <td className="value">{item.cliente.clientId}</td>
                                            <td className="value">{item.cliente.name}</td>
                                            <td className="value">{item.cliente.email}</td>
                                            <td className="value">{this.props.listaDeClientes.getClientBalance(item.cliente.clientId)}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <p>
                                        {item.quotationId}
                                    </p>
                                    <QuotationProductDetails
                                        quotation={new Quotation(item)}
                                    />
                                </div>,
                            enviarProdutos:false,
                            atualizarPagamento:false
                        })
                    }}
                >
                    {item.quotationId}
                </a>
            )
        },this))
    }

    showEnviarProdutos(){

        if(!this.state.enviarProdutos){
            return(
                <button
                    onClick={
                        (e)=>{
                            e.preventDefault();
                            this.setState({
                                enviarProdutos:true,
                                atualizarPagamento:false
                            })
                        }
                    }
                >
                    Enviar Produtos
                </button>
            )
        }
        else{
            return
        }
    }
    showActions(){
        if(this.state.selectedQuote){
            return (
                <div className="actionContent">

                    {this.showEnviarProdutos()}
                </div>
            )
        }
        else return
    }

    hideActions(e){
        e.preventDefault();
        this.setState({
            enviarProdutos:false,
            atualizarPagamento:false
        });
    }

    showAction(){
        if(this.state.enviarProdutos){
            return <EnviarProdutos
                token={this.props.token}
                hideActions={this.hideActions}
                openQuotes={this.props.openQuotes}
                shipProducts={this.shipProducts}
                quotationId={this.state.selectedQuote.quotationId}
                enviando={this.state.enviando}
                setContent={this.props.setContent}
                toggleEnviando={this.toggleEnviando}
            />
        }

        return

    }
    //remember to sync localStorage with server responses
    shipProducts(shippingInfo){
        if(this.state.selectedQuote){
            let quotationId=this.state.selectedQuote.quotationId
            //need to call an API to handle this update....
            //API should:
            //update quote state with shipping info
            //generate an invoice for client
            //add transaction to client history
            //upon receiving code 200 from server, update localStorageArrays
            this.setState({loading:true})
            console.log(quotationId)
            console.log(shippingInfo)
            let data = {
                "shippingInformation": {
                    "shippingDate": shippingInfo.shippingDate,
                    "cost": shippingInfo.cost,
                    "trackingNumber": shippingInfo.trackingNumber,
                    "shippingTime":shippingInfo.shippingTime,
                    "quotationId":shippingInfo.quotationId
                },
                authorizationToken:this.props.token
            }
            fetch(API_URL+"/quotations/"+quotationId+"/ship", {
                method: "POST",
                mode: "cors",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body:JSON.stringify(data)
            }).then(response => response.json()).then(response => {
                if("quotation" in response){
                    try{
                        let cotacao=new Quotation(response.quotation)
                        //response has both info about quotation and updated
                        //info about the client from the server
                        //need to update localStorage accordingly
                        let listaDeClientes=this.props.listaDeClientes
                        let cotacoes=this.props.allQuotes
                        listaDeClientes.changeClientData(new Client(response.client))
                        cotacoes.updateQuote(cotacao)
                        //to update localStorage
                        let invoice=response.invoice
                        let invoices=new Invoices()
                        invoices.addOrUpdateInvoice(invoice)
                        this.props.updateTabs(cotacoes.quotations)
                        this.props.setContent('shipped')
                    }catch(err){
                        console.log(err)
                    }


                    this.setState({shipped:true,enviando:false})
                    this.props.setContent('shipped')
                }


            })
                .catch(error => {
                    console.error('Error:', error)
                })
        }

    }

    render() {
        if(this.state.enviando){
            return "ENVIANDO..."
        }
        else{
            return(
                <div>
                    <p id="quoteTitle">Payment Pending / Not shipped quotes</p>
                    <div id="selectContainer">
                        <Select
                            classNamePrefix={"leftContent"}
                            onChange={this.handleChange}
                            options={this.props.options}
                        />
                    </div>
                    <div id="quoteDetailsContainer">
                        {this.showAction()}
                        {this.showActions()}
                        {this.state.quoteDetails}
                    </div>

                </div>
            )
        }

    }
}