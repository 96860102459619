import {RequestForQuote} from "./RequestForQuote";
export class Requests{
    constructor(requests) {
        this.requests=[]
        if(requests){
            for(let i=0;i<requests.length;i++){
                this.requests.push(new RequestForQuote(requests[i]))
            }
        }

    }

}