import React from 'react';
import Select from 'react-select';
import '../../css/App.css';
import NewProductForm from "./NewProductForm";
import ProductDetails from "./ProductDetails";
import {sortByKey} from "../../functions";
import ProductUpdatingWindow from "./miscelaneous/ProductUpdatingWindow";
export default class Produtos extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            selectedOption: null,
            produtos:this.props.produtos,
            isEditing:false,
            isNewProduct:false,
            isUpdating:false,
            options:'',
            isMounted:false,
            updated:false,
            leftMaximised:true,
            token:this.props.token,
            newPrice:null
        }
        this.setDisplay=this.setDisplay.bind(this)
        this.cancelAddProduct=this.cancelAddProduct.bind(this)
        this.addProduct=this.addProduct.bind(this)
        this.toggleEditing=this.toggleEditing.bind(this)
        this.toggleIsUpdating=this.toggleIsUpdating.bind(this)
        this.toggleUpdated=this.toggleUpdated.bind(this)
        this.showPriceEdit=this.showPriceEdit.bind(this)
        this.showButton=this.showButton.bind(this)
    }
    newClientAdded(){
        this.setState({newProductAdded:true})
    }

    toggleIsUpdating(e){
        e.preventDefault()
        this.setState({isUpdating:!this.state.isUpdating})
    }
    toggleUpdated(e){
        e.preventDefault()
        this.setState(
            {
            updated:!this.state.updated,
            isUpdating:false})
    }


    toggleEditing(e){
        e.preventDefault()
        if(!this.state.isEditing){
            this.setState({leftMaximised:false})
        }
        console.log(this.state.isEditing)
        this.setState({
            isEditing:!this.state.isEditing,
            isNewClient:false
        })
    }

    handleInputChange = (e) => {
        e.preventDefault();
        this.setState({[e.target.name]:e.target.value})
        console.log(this.state.newPrice)
    }

    showButton(){
        if(this.state.newPrice){
            if(this.state.newPrice>0){
                return(
                    <div className="buttonOutterWrapper">
                        <div className="buttonWrapper">
                        <button>
                            Alterar Preço
                        </button>
                        </div>
                    </div>
                )
            }
        }
    }

    showPriceEdit(){
        if(this.state.selectedOption){
            return(
                <div>
                    <div className="newSalesPriceLabel">
                        Novo preço para venda
                    </div>
                    <input
                        type="number"
                        name="newPrice"
                        onChange={this.handleInputChange}
                    />
                    {
                        this.showButton()
                    }
                </div>
            )
        }
    }

    addProduct(produto){
        let produtos=[]

        for(let i=0;i<this.state.produtos.length;i++){
            if(this.state.produtos[i].productId!==produto.productId)
                produtos.push(this.state.produtos[i])
        }
        produtos.push(produto)
        produtos=sortByKey(produtos,"description")
        this.setState(
            {
                produtos:produtos}
        )
        localStorage.setItem("produtos",JSON.stringify(produtos))



    }

    cancelAddProduct(e){
        e.preventDefault()
        this.setState({
            isNewProduct:false
        })
    }

    setDisplay(e,item){
        e.preventDefault()
        switch(item){
            case "isEditing":
                this.setState({
                    isEditing:true,
                    isNewProduct:false
                })
                break;
            case "isNewProduct":
                this.setState({
                    isEditing:false,
                    isNewProduct:true
                })
                break;
            default:
                break;
        }
    }


    handleChange = (selectedOption) => {
        console.log(selectedOption)
        this.setState({ selectedOption });
        this.setState({
            isEditing:false,
            isNewProduct:false,
            updated:false,
            leftMaximised:false
        })

    }

    setOptions(){
        this.setState({
            isEditing:false
        })
        let products=this.state.produtos
        //make sure produtos is not null if loading from this component..
        if(products.length<1){
            products=JSON.parse(localStorage.getItem("produtos"))
        }
        if(!products){
            products=[]
        }
        let options=[]
        for(let i=0;i<products.length;i++){
            let value=products[i]
            let label=products[i].name + " - "+products[i].description
            options.push({
                value:value,
                label:label.toString()
            })
        }
        this.setState({
            options:options
        })
        this.setState({
            isMounted:true
        })
    }

    componentWillMount(){
        localStorage.setItem("content","produtos")
        if(!this.props.produtos){
            this.props.getProducts()
            localStorage.setItem("content","")
        }
        this.setOptions()
    }

    showDetails(){
        if(this.state.selectedOption){
            return(
                <ProductDetails
                    produto={this.state.selectedOption.value}
                />
            )
        }
        else{
            return ""
        }
    }

    showCard(){
        if(this.state.isNewProduct)
        {
            return (
                <span>
                    <NewProductForm
                        token={this.props.token}
                        cancelAddProduct={this.cancelAddProduct}
                        addProduct={this.addProduct}
                        updateProductList={this.props.updateProductList}
                        toggleIsUpdating={this.toggleIsUpdating}
                        toggleUpdated={this.toggleUpdated}
                    />
                </span>
            )
        }
        if(this.state.isEditing){
            return (
                <span>
                <h1>Feature under development</h1></span>
            )
        }
        else{
            return this.showDetails()
        }
    }

    showImage(){
        if(this.state.selectedOption){
            let product=this.state.selectedOption.value
            if(product){
                if("image" in product){
                    let url=product.image;
                    if(url){
                        return(
                            <div className="productImage">
                                <img src={url} alt="product"/>
                            </div>
                        )
                    }
                }
            }

        }
    }


    showWithLeftMaximised(){
        return (
            <div className="clientContainer">

                <div className="content">
                    <span className="stockTitle">
                        <h5>Procurar</h5>
                    </span>
                    <span className="left">


                    <div className="leftContent">
                        <Select
                            classNamePrefix={"leftContent"}
                            placeholder="Procure o produto aqui"
                            onChange={this.handleChange}
                            label="Single select"
                            options={this.state.options}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 15,
                                minMenuHeight:100,
                                colors: {
                                    ...theme.colors,
                                    text: 'orangered',
                                    primary25: 'pink',
                                    primary: 'black',
                                    borderRadius:15
                                },
                            })
                            }
                        />
                        {this.showPriceEdit()}
                        {this.showImage()}
                    </div>
                </span>
                    <span>
                    {this.showCard()}
                    </span>
                </div>

            </div>

        );
    }


    render() {
        if(this.state.updated){
            return(
                <ProductUpdatingWindow
                    headerType={"updated"}
                />)
        }
        if(this.state.isUpdating){
            return(
                <ProductUpdatingWindow
                    headerType={"updating"}
            />)
        }

        if(this.props.produtos){
            if(!this.state.isNewProduct) {
                return this.showWithLeftMaximised()
            }
            else{
                return this.showCard()
            }
        }
        else{
            return "Could not load products"
        }


    }

}