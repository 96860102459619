import React from 'react';
import Item from "../../model/Item";
import '../../css/unpaidInvoicesQuotationDetails.css'
import {Quotation} from "../../model/Quotation";

export default class QuotationDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state={

        }
        this.getRows=this.getRows.bind(this)
    }

    getRows(){
        let quotation = this.props.quotations.getQuote(this.props.invoice.quotationId)

        let items =quotation.itens
        return items.map((i,index)=>{
            let item = new Item(i.produto,i.quantity)
            return (
                <tr key={index}>
                    <td>{item.produto.name + " - "+item.produto.description}</td>
                    <td>{item.produto.unity}</td>
                    <td>{(item.quantity*1).toFixed(4)}</td>
                    <td>{(item.produto.price*1).toFixed(2)}</td>
                    <td>{item.getSubtotal().toFixed(2)}</td>
                </tr>
            )
        })
    }



    render() {
        let quotation = new Quotation(this.props.quotations.getQuote(this.props.invoice.quotationId))
        return(
            <div className="quotationItems">
                <div className="bar"></div>
                <table>
                    <tbody>
                    <tr>
                        <th>Produto</th>
                        <th>Unidade</th>
                        <th>Qtd</th>
                        <th>Preço/un R$</th>
                        <th>Subtotal R$</th>
                    </tr>
                    {this.getRows()}
                    </tbody>
                </table>
                <table>
                    <tbody>
                        <tr>
                            <th>Subtotal R$</th>
                            <td>{quotation.getSubtotalDosProdutos().toFixed(2)}</td>
                        </tr>
                        <tr>
                            <th>Transporte R$</th>
                            <td>{quotation.getValorTransporte().toFixed(2)}</td>
                        </tr>
                        <tr>
                            <th>Quem paga o transporte?</th>
                            <td>{quotation.whoPays()}</td>
                        </tr>
                        <tr>
                            <th>Desconto</th>
                            <td>{quotation.getDesconto().toFixed(2)}</td>
                        </tr>
                        <tr>
                            <th>Total R$</th>
                            <td>{quotation.getTotal().toFixed(2)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }

}