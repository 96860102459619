import React from 'react';
const rio = require('../../../../assets/arcos.jpg');
export default class QuoteSent extends React.Component {

    render() {
        return (
            <div className="appLoading">
                <h3>QUOTATION SENT</h3>
                <div className="rio">
                    <img
                        className="rio"
                        src={rio}
                        aria-hidden
                        alt="background image"
                    />
                </div>


                <div className="aerotubos">
                    <img
                        className="aerotubos"
                        aria-hidden
                        alt="background image"
                    />

                </div>

                <div className="appLoadingText">

                    <h1>Quotation was sent successfully</h1>

                    <br/>
                    <br/><br/><br/><br/><br/><br/>
                    <br/><br/><br/><br/><br/><br/>
                    <p>
                        Developed by Cloud Computing Team
                    </p>
                    <p>
                        Royal Melbourne Institute of Technology
                    </p>
                    <p>
                        Versão: 1.0
                    </p>
                </div>


            </div>
        )
    }
}


