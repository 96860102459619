import React from 'react';
import Select from 'react-select';
import '../../../css/App.css';
import ClientDetails from "./ClientDetails";
import {FaEdit} from "react-icons/fa";
import EditClientDetails from "./EditClientDetails";
import NewClient from "./NewClient"
import ClientUpdatingWindow from "./miscelaneous/ClientUpdatingWindow";
export default class Search extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            selectedOption: null,
            selectedClient:null,
            selectedClientId:null,
            isEditing:false,
            isNewClient:false,
            isMounted:false,
            hasUpdated:false,
            leftMaximised:true,
            newClientAdded:false,
            isUpdating:false
        }
        this.toggleEditing=this.toggleEditing.bind(this)
        this.toggleUpdated=this.toggleUpdated.bind(this)
        this.toggleIsUpdating=this.toggleIsUpdating.bind(this)
        this.showCard=this.showCard.bind(this)
        this.newClientAdded=this.newClientAdded.bind(this)
    }

    newClientAdded(){
        this.setState({newClientAdded:true})
    }

    toggleIsUpdating(e){
        e.preventDefault()
        this.setState({isUpdating:!this.state.isUpdating})
    }
    toggleUpdated(e){
        e.preventDefault()
        this.setState({updated:!this.state.updated})
    }


    toggleEditing(e){
        e.preventDefault()
        if(!this.state.isEditing){
            this.setState({leftMaximised:false})
        }
        console.log(this.state.isEditing)
        this.setState({
            isEditing:!this.state.isEditing,
            isNewClient:false
        })
    }


    handleChange = (selectedOption) => {
        console.log(selectedOption)
        this.setState({ selectedOption });
        try{
            this.setState({
                isEditing:false,
                isNewClient:false,
                updated:false,
                leftMaximised:false,
                selectedClientId:selectedOption.value.clientId,
                selectedClient:selectedOption.value
            })
        }
        catch(error){console.log(error)}

    }

    componentWillMount(){
        localStorage.setItem("content","clients")
        if("clients" in this.props){
            if(this.props.clientes.length===0){
                this.props.loadClients()
            }
        }

        if(this.state.selectedClientId){
            if(this.state.selectedClientId.includes("AER")||this.state.selectedClientId.includes("CL")){
                for(let i=0; i<this.props.clientes.length;i++){
                    if(this.state.props[i].clientId===this.state.selectedClientId){
                        let data={
                            "value":this.props.clientes[i],
                            "label": this.props.clientes[i].Nome
                        }
                        this.setState({selectedOption:data})
                        break;
                    }
                }
            }
        }

    }

    showDetails(){
        if(this.state.selectedOption){
            return(
                <ClientDetails
                    client={this.state.selectedOption.value}
                />
            )
        }
        else{
            return ""
        }
    }

    showIcons(){
        if(this.state.selectedOption!==null){
            return(
                <div className="toolBar">
                    <span className="icone">
                        <button onClick={(e)=>this.toggleEditing(e)} >
                            <FaEdit className="iconElement" onClick={(e)=>this.toggleEditing(e)}/>
                            <div className="iconLabel">Edit</div>
                        </button>
                    </span>
                </div>
            )
        }
    }


    showEdit(){
        if(this.state.selectedOption!==null){
            return(

                    <button
                        onClick={(e)=>this.toggleEditing(e)}
                    >
                        <FaEdit onClick={(e)=>this.toggleEditing(e)}/>
                    </button>
            )
        }
    }

    showWithLeftMaximised(){
        let placeholder= "Digite o nome do cliente..."
        if(localStorage.getItem("selectedClientName")!=='undefined'){
            placeholder=localStorage.getItem("selectedClientName")
        }
        return (

            <div className="clientContainer">
                <div id="searchClientLabel">
                    <h5>Search / View client details</h5>
                </div>
                {this.showIcons()}
                    <span className="left">
                        <div>
                            <Select
                                classNamePrefix={"leftContent"}
                                placeholder={placeholder}
                                onChange={this.handleChange}
                                label="Single select"
                                options={this.props.options}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 15,
                                    minMenuHeight:100,
                                    colors: {
                                        ...theme.colors,
                                        text: 'orangered',
                                        primary25: 'pink',
                                        primary: 'black',
                                        borderRadius:15
                                    },
                                })
                                }
                            />
                        </div>
                    </span>
                <span>
                    {this.showCard()}
                    </span>
            </div>

        );
    }
    showCard(){
        if(this.state.isUpdating){
            return "Updating client. Please wait"
        }
        if(this.state.isNewClient)
        {
            return (
                <span className={"right"}>
                        <NewClient
                            newClientAdded={this.newClientAdded}
                            setContent={this.props.setContent}
                            updateClientList={this.props.updateClientList}
                            token={this.props.token}
                        />
                    </span>
            )
        }
        if(this.state.isEditing){
            return (
                <span>
                <EditClientDetails
                    client={this.state.selectedOption.value}
                    updateClientList={this.props.updateClientList}
                    editing={this.state.isEditing}
                    isUpdating={this.state.isUpdating}
                    toggleIsUpdating={this.toggleIsUpdating}
                    hasUpdated={this.state.hasUpdated}
                    toggleEditing={this.toggleEditing}
                    toggleUpdated={this.toggleUpdated}
                    token={this.props.token}
                />
                </span>
            )
        }
        else{
            return this.showDetails()
        }
    }

    render() {
        if(this.state.isUpdating){
            return <ClientUpdatingWindow
                clientName={this.state.selectedClient.Nome}
                headerType='updating'
            />
        }
        if(this.state.newClientAdded){
            return "Client adicionado com sucesso"
        }
        return this.showWithLeftMaximised()

    }
}