import React from 'react';
import '../../../css/cotacao.css'

export default class BankAccountDetail extends React.Component {

    showId(){
        if(this.props.quotation.bank.type==='business'){
            return (
                <div>
                    CNPJ: {this.props.quotation.bank.id}
                </div>
            )
        }
        if(this.props.quotation.bank.type==='individual'){
            return (
                <div>
                    CPF: {this.props.quotation.bank.id}
                </div>
            )

        }
    }

    bankInfoNote(){
        if(this.props.quotation.bank.isThirdPartyAccount){
            return(
                <div className="accountWarning">
                    Atenção: Conta bancária de terceiro
                </div>
            )
        }
    }

    render() {
        console.log(this.props.quotation)
        return (
            <div className="bankDetails">
                <div className="bankDetail">
                    <div>{this.props.quotation.bank.accountHolder}</div>
                    <div>{this.showId()}</div>
                    <div>{this.props.quotation.bank.bankName}</div>
                    <div>AGÊNCIA: {this.props.quotation.bank.branch}</div>
                    <div>Conta: {this.props.quotation.bank.accountNumber}</div>
                </div>
                <div className="bankAccountInfo">
                    {this.bankInfoNote()}
                </div>
            </div>
        )
    }
}