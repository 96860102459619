
export class RequestForQuote{
    constructor(req) {
        if(req){
            if("requestId" in req){
                this.requestId=req.requestId
            }
            if ("email" in req) {
                this.email = req.email
            }
            if ("name" in req) {
                this.name = req.name
            }
            if ("requestedDate" in req) {
                this.requestedDate = req.requestedDate
            }
            if ("landlineNumber" in req) {
                this.landlineNumber = req.landlineNumber
            }
            if ("isRequestProcessed" in req) {
                this.isRequestProcessed = req.isRequestProcessed
            }
            if ("items" in req) {
                this.items = req.items
            }
        }

    }
        isValidRequest(){
            if(!("email" in this)){return false}
            if(!("items" in this)){return false}
            for(let i=0;i<this.items.length;i++){
                let item=this.items[i]
                if(!("productId" in item)){return false}
                if(!("quantity" in item)){return false}
            }
        }

        areAllItensInStock(stock){
            if(!this.isValidRequest()){
                return false
            }
            for(let i=0;i<this.items.length;i++){
                let item=this.items[i]
                if(!stock.isInStock(item.productId)){return false}
            }
            return true
        }



}