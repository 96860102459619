import React, {Component} from 'react';
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import './desktop/css/ComponentStyles.css'
import {FaDownload,FaSync, FaWarehouse, FaWpforms, FaTruckMoving, FaCartPlus, FaMoneyBillAlt } from "react-icons/fa";
import { TiGroup } from "react-icons/ti";
import { GiExitDoor } from "react-icons/gi";

class SideNavigation extends Component {

    constructor(){
        super();
        this.state={expanded:false}
    }
    render() {

        return (

            <div>
                <React.Fragment>
                    <SideNav id={"admin-nav"}
                    >
                        <SideNav.Nav>
                            <NavItem eventKey="home" >
                                <NavIcon>
                                    <FaWarehouse className="sideNavIcon"/>
                                    <div className="sideNavInfo">
                                        <FaWarehouse className="sideNavIcon"/>
                                        <span className="sideNavHoverText">Estoque
                                        </span>
                                        <SideNav className="subnav">
                                            <NavItem
                                                className="subnavItem"
                                                onClick={(e)=>{
                                                    e.preventDefault()
                                                    this.props.setContent('New Product')
                                                    this.setState({expanded:false})
                                                }}
                                            >
                                                <NavText>Adicionar item</NavText>
                                            </NavItem>
                                            <NavItem
                                                className="subnavItem"
                                                onClick={(e)=>{
                                                    e.preventDefault()
                                                    this.props.setContent('New Tube')
                                                    this.setState({expanded:false})
                                                }}
                                            >
                                                <NavText>Adicionar tubo</NavText>
                                            </NavItem>
                                            <NavItem
                                                className="subnavItem"
                                                onClick={(e)=>{
                                                    e.preventDefault()
                                                    this.props.setContent('New Purchase')
                                                    this.setState({expanded:false})
                                                }}
                                            >
                                                <NavText>Compra de Materiais</NavText>
                                            </NavItem>
                                            <NavItem
                                                className="subnavItem"
                                                onClick={(e)=>{
                                                    e.preventDefault()
                                                    this.props.setContent('Stock')
                                                    this.setState({expanded:false})
                                                }}
                                            >
                                                <NavText>Visualizar</NavText>
                                            </NavItem>

                                        </SideNav>
                                    </div>

                                </NavIcon>
                                <NavText>
                                    Estoque
                                </NavText>
                            </NavItem>
                            <NavItem eventKey="Clients">
                                <NavIcon>
                                    <TiGroup className="sideNavIcon"/>
                                    <div className="sideNavInfo">
                                        <TiGroup className="sideNavIcon"/>
                                        <span className="sideNavHoverText">Clientes</span>
                                        <SideNav className="subnav">
                                            <NavItem
                                                className="subnavItem"
                                                onClick={(e)=>{
                                                    e.preventDefault()
                                                    this.props.setContent('Add new client')
                                                    this.setState({expanded:false})
                                                }}
                                            >
                                                <NavText>Novo</NavText>
                                            </NavItem>
                                            <NavItem
                                                className="subnavItem"
                                                onClick={(e)=>{
                                                    e.preventDefault()
                                                    this.props.setContent('Clients')
                                                    this.setState({expanded:false})
                                                }}
                                            >
                                                <NavText>Procurar</NavText>
                                            </NavItem>

                                        </SideNav>
                                    </div>
                                </NavIcon>
                                <NavText>
                                    CLIENTS
                                </NavText>
                            </NavItem>
                            <NavItem
                                eventKey="Freight Companies"
                                onClick={(e)=>{
                                    e.preventDefault()
                                    this.props.setContent('Freight Companies')
                                    this.setState({expanded:false})
                                }}
                            >
                                <NavIcon>
                                    <FaTruckMoving className="sideNavIcon"/>
                                    <div className="sideNavInfo">
                                        <FaTruckMoving className="sideNavIcon"/>
                                        <span className="sideNavHoverText">Transportadores</span>
                                    </div>
                                </NavIcon>
                                <NavText>
                                    Transportadores
                                </NavText>
                            </NavItem>
                            <NavItem
                                eventKey="Suppliers"
                                onClick={(e)=>{
                                    e.preventDefault()
                                    this.props.setContent('Suppliers')
                                    this.setState({expanded:false})
                                }}
                            >
                                <NavIcon>
                                    <TiGroup className="sideNavIcon"/>
                                    <div className="sideNavInfo">
                                        <TiGroup className="sideNavIcon"/>
                                        <span className="sideNavHoverText"> Fornecedores</span>
                                    </div>
                                </NavIcon>
                                <NavText>
                                    Fornecedores
                                </NavText>
                            </NavItem>
                            <NavItem eventKey="Quotes">
                                <NavIcon>
                                    <FaWpforms className="sideNavIcon"/>
                                    <div className="sideNavInfo"><FaWpforms className="sideNavIcon"/>
                                        <span className="sideNavHoverText">Visualizar Cotações</span>
                                        <SideNav className="subnav">
                                            <NavItem
                                                className="subnavItem"
                                                onClick={(e)=>{
                                                    e.preventDefault()
                                                    this.props.setContent('Open Quotes')
                                                    this.setState({expanded:false})
                                                }}
                                            >
                                                <NavText>Abertas</NavText>
                                            </NavItem>
                                            <NavItem
                                                className="subnavItem"
                                                onClick={(e)=>{
                                                    e.preventDefault()
                                                    this.props.setContent('Payment Pending')
                                                    this.setState({expanded:false})
                                                }}
                                            >
                                                <NavText>Pagamento Pendente</NavText>
                                            </NavItem>
                                            <NavItem
                                                className="subnavItem"
                                                onClick={(e)=>{
                                                    e.preventDefault()
                                                    this.props.setContent('Payment Pending / Not shipped')
                                                    this.setState({expanded:false})
                                                }}
                                            >
                                                <NavText>Pag. pendente / não enviada</NavText>
                                            </NavItem>
                                            <NavItem
                                                className="subnavItem"
                                                onClick={(e)=>{
                                                    e.preventDefault()
                                                    this.props.setContent('Ready to ship')
                                                    this.setState({expanded:false})
                                                }}
                                            >
                                                <NavText>Pronta para enviar</NavText>
                                            </NavItem>
                                            <NavItem
                                                className="subnavItem"
                                                onClick={(e)=>{
                                                    e.preventDefault()
                                                    this.props.setContent('Finalised')
                                                    this.setState({expanded:false})
                                                }}
                                            >
                                                <NavText>Finalizadas</NavText>
                                            </NavItem>
                                        </SideNav>
                                    </div>
                                </NavIcon>
                                <NavText>
                                    Visualizar Cotações

                                </NavText>
                            </NavItem>
                            <NavItem eventKey="New Quote"
                                     onClick={(e)=>{
                                         e.preventDefault()
                                         this.props.setContent('New Quote')
                                         this.setState({expanded:false})
                                     }}
                            >
                                <NavIcon>
                                    <FaCartPlus className="sideNavIcon"/>
                                    <div className="sideNavInfo">
                                        <FaCartPlus className="sideNavIcon"/>
                                        <span className="sideNavHoverText">Nova Cotação</span>
                                    </div>
                                </NavIcon>
                                <NavText>
                                    Nova Cotação
                                </NavText>
                            </NavItem>
                            <NavItem eventKey="Payments">
                                <NavIcon>
                                    <FaMoneyBillAlt className="sideNavIcon"/>
                                    <div className="sideNavInfo">
                                        <FaMoneyBillAlt className="sideNavIcon"/>
                                        <span className="sideNavHoverText">Pagamentos</span>
                                        <SideNav className="subnav">
                                            <NavItem
                                                className="subnavItem"
                                                onClick={(e)=>{
                                                    e.preventDefault()
                                                    this.props.setContent('Allocate Payments')
                                                    this.setState({expanded:false})
                                                }}
                                            >
                                                <NavText>Alocar Pagamentos</NavText>
                                            </NavItem>
                                            <NavItem
                                                className="subnavItem"
                                                onClick={(e)=>{
                                                    e.preventDefault()
                                                    this.props.setContent('Transactions')
                                                    this.setState({expanded:false})
                                                }}
                                            >
                                                <NavText>Transações</NavText>
                                            </NavItem>
                                            <NavItem
                                                className="subnavItem"
                                                onClick={(e)=>{
                                                    e.preventDefault()
                                                    this.props.setContent('Delayed Payment Invoices')
                                                    this.setState({expanded:false})
                                                }}
                                            >
                                                <NavText>Faturas em atraso</NavText>
                                            </NavItem>
                                        </SideNav>
                                    </div>
                                </NavIcon>
                                <NavText>
                                    Pagamentos
                                </NavText>
                            </NavItem>
                            <NavItem
                                eventKey="Requests"
                                onClick={(e)=>{
                                    e.preventDefault()
                                    this.props.setContent('Requests')
                                    this.setState({expanded:false})
                                }}
                            >
                                <NavIcon>
                                    <FaDownload className="sideNavIcon"/>
                                    <div className="sideNavInfo">
                                        <FaDownload className="sideNavIcon"/>
                                        <span className="sideNavHoverText">Requisições de Cotações</span>
                                    </div>
                                </NavIcon>
                                <NavText>
                                    Requisições de Cotações
                                </NavText>
                            </NavItem>

                            <NavItem
                                eventKey="Refresh"
                                onClick={(e)=>{
                                    e.preventDefault()
                                    this.props.setContent('Refresh')
                                    this.setState({expanded:false})
                                }}
                            >
                                <NavIcon>
                                    <FaSync className="sideNavIcon"/>
                                    <div className="sideNavInfo">
                                        <FaSync className="sideNavIcon"/>
                                        <span className="sideNavHoverText">Recarregar recursos</span>
                                    </div>
                                </NavIcon>
                                <NavText>
                                    Recarregar recursos
                                </NavText>
                            </NavItem>





                            <NavItem
                                eventKey="Logout"
                                onClick={(e)=>{
                                    e.preventDefault()
                                    this.props.setContent('Logout')
                                    this.setState({expanded:false})
                                }}
                            >
                                <NavIcon>
                                    <GiExitDoor className="sideNavIcon"/>
                                </NavIcon>
                                <div className="sideNavInfo">

                                    Logout
                                </div>
                                <NavText>
                                    LOG OUT
                                </NavText>
                            </NavItem>
                        </SideNav.Nav>
                    </SideNav>

                </React.Fragment>
            </div>
        )

    }
}

export default SideNavigation;