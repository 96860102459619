import React from 'react';
import '../css/tabs.css'
export default class TabContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            selectedTab: this.props.selectedTab
        }
        this.selectTab=this.selectTab.bind(this)
        this.getTabClassName=this.getTabClassName.bind(this)
        this.getTabContent=this.getTabContent.bind(this)
    }

    selectTab(tabLabel){
        this.setState({selectedTab:tabLabel})
    }

    getTabClassName(label){
        if(label===this.state.selectedTab){
            return "activeTab"
        }
        else{
            return "inactiveTab"
        }
    }

    //remember to sync localStorage with server responses

    getTabContent(){
        let tabs=this.props.tabs
        for(let i=0;i<tabs.length;i++){
            if(tabs[i].label===this.state.selectedTab){
                return tabs[i].content
            }
        }
        return this.props.initialContent
    }
    render() {
        return (
            <div className="tabsOutterContainer">
                <div className="navigation">
                    <div className="navigationTab">
                        <ul>
                            {this.props.tabs.map(function(tab,i){
                            return (
                                <li key={i}>
                                    <a href="/#">
                                    <span
                                        className={this.getTabClassName(tab.label)}
                                        onClick={
                                            (e)=>
                                            {
                                                e.preventDefault();
                                                this.selectTab(tab.label)

                                            }
                                        }
                                    >
                                        {tab.label}
                                        </span>
                                    </a>
                                </li>)
                        },this)
                            }
                        </ul>
                    </div>
                </div>

                <div className="tabContent">
                    {this.getTabContent()}
                </div>
            </div>
        )
    }
}