//to create a Product object as per database data
import {Component} from 'react'
const API_URL = "https://ek2lzvoz71.execute-api.sa-east-1.amazonaws.com/dev/"
class Transportador extends Component {

    constructor(freightCompany){
        super();
        try{this.freightCompanyId=freightCompany.freightCompanyId}catch(err){console.log(err)};
        try{this.Bairro=freightCompany.Bairro}catch(err){console.log(err)};
        try{this.CEP=freightCompany.CEP}catch(err){console.log(err)};
        try{this.CPF_CNPJ=freightCompany.CPF_CNPJ}catch(err){console.log(err)};
        try{this.Celular=freightCompany.Celular}catch(err){console.log(err)};
        try{this.Consignado=freightCompany.Consignado}catch(err){console.log(err)};
        try{this.IE=freightCompany.IE}catch(err){console.log(err)};
        try{this.Email=freightCompany.Email}catch(err){console.log(err)};
        try{this.Email2=freightCompany.Email2}catch(err){console.log(err)};
        try{this.Endereco=freightCompany.Endereco}catch(err){console.log(err)};
        try{this.Estado=freightCompany.Estado}catch(err){console.log(err)};
        try{this.Municipio=freightCompany.Municipio}catch(err){console.log(err)};
        try{this.Nome=freightCompany.Nome}catch(err){console.log(err)};
        try{this.PreferenciaBancaria=freightCompany.PreferenciaBancaria}catch(err){console.log(err)};
        try{this.Telefone=freightCompany.Telefone}catch(err){console.log(err)};
        try{this.TelefoneClaro=freightCompany.TelefoneClaro}catch(err){console.log(err)};
        try{this.TelefoneOi=freightCompany.TelefoneOi}catch(err){console.log(err)};
        try{this.TelefoneTim=freightCompany.TelefoneTim}catch(err){console.log(err)};
        try{this.TelefoneVivo=freightCompany.TelefoneVivo}catch(err){console.log(err)};
        try{this.Saldo=freightCompany.Saldo}catch(err){console.log(err)};
        try{this.Transactions=freightCompany.Transactions}catch(err){console.log(err)};

    }

    //setters


    //getters
    getNome(){
        if(this.Nome){
            return this.Nome
        }
        else{
            return " "
        }
    }
    getTransportadorId(){
        return this.freightCompanyId
    }

    addTransportador(freightCompany){
        fetch(API_URL+"freightCompanyes/", {
            method: "POST",
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: freightCompany
        })
            .then(response => response.json())
            .then(response => {
            })
            .catch(error => {
                console.error('Error:', error)

            });
    }

    static getTransportadores(){
        fetch(API_URL+"freightCompanyes/", {
            method: "GET",
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                localStorage.setItem("freightCompanyes",JSON.stringify(response))
            })
            .catch(error => {
                console.error('Error:', error)

            });
    }

}

export default Transportador;