
//to create a Product object as per database data
import Produto from "./Produto";

export default class Item{

    constructor(produto, quantity){
        this.produto=new Produto(produto);
        this.quantity=parseFloat(quantity);
        this.totalWeight=((produto.kgPerUnit*1)*(quantity*1))*1
        this.subtotal=(((parseFloat(produto.price))*1)*(parseFloat(quantity)*1))*1
    }

    //setters
    setQuantity(newQuantity){
        this.quantity=newQuantity;
        this.totalWeight=((this.produto.kgPerUnit*1)*(newQuantity*1))*1
        this.subtotal=((parseFloat(this.produto.price)*1)*(parseFloat(newQuantity)*1))*1

    }
    //getters

    getWeight(){
        let totalWeight=(this.quantity*1)*(this.produto.getKgPerUnit()*1);
        if(isNaN(totalWeight)){
            return 0
        }
        return totalWeight
    }

    getQuantity(){return this.quantity}
    getSubtotal(){
        return (this.quantity*1)*(this.produto.getPrice()*1)
    }
    getProduto(){
        return this.produto
    }

    getProductId(){
        return this.produto.getProductId()
    }

    containsProduct(produto){
        if(this.produto.getProductId()===produto.getProductId()){
            return true;
        }
        return false;
    }

    isDuplicate(item){
        if(this.produto.getProductId()===item.getProduto().getProductId()){
            return true;
        }
        return false;
    }


}
