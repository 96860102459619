import React from 'react';
import {FaAddressCard} from 'react-icons/fa'
import {sortByKey} from "../../functions";
import '../../css/form.css';
import config from '../../config.js'
const API_URL = config.api_endpoint
const plane2 = require('../../assets/flying2.gif');
const plane3 = require('../../assets/flying4.gif');
export default class NewSupplierForm extends React.Component {

    constructor(props){
        super(props)

        this.state = {
           supplierAdded:false,
            attemptedToAdd:false,
            name: this.props.name,
            supplierId:' ',
            consigned: ' ',
            id: ' ',
            landlineNumber: this.props.landlineNumber,
            address: ' ',
            suburb:' ',
            zipCode: ' ',
            city: ' ',
            state: ' ',
            stateRegistration: ' ',
            email: this.props.email,
            phone1: ' ',
            phone2:' ',
            phone3: ' ',
            phone4: ' ',
            mobileNumber:this.props.mobileNumber,
            poBox: ' ',
            bankPreference:' ',
            email2: ' ',
            isUpdating:false,
            authorizationToken:this.props.token,
            hasUpdated:false,
            updateSuccessful:false
        }
        this.incluir=this.incluir.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.clear=this.clear.bind(this)
    }

    clear(){
        this.setState({
            supplierAdded:false,
            attemptedToAdd:false,
            name: '',
            supplierId:' ',
            consigned: ' ',
            id: ' ',
            landlineNumber: this.props.landlineNumber,
            address: ' ',
            suburb:' ',
            zipCode: ' ',
            city: ' ',
            state: ' ',
            stateRegistration: ' ',
            email: this.props.email,
            phone1: ' ',
            phone2:' ',
            phone3: ' ',
            phone4: ' ',
            mobileNumber:this.props.mobileNumber,
            poBox: ' ',
            bankPreference:' ',
            email2: ' ',
            isUpdating:false,
            hasUpdated:false,
            updateSuccessful:false
        })
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });

    }

    incluir(e, fornecedor){
        e.preventDefault()
        this.setState(
            {isUpdating:true}
        )
        fetch(API_URL+"/suppliers/", {
            method: "POST",
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body:JSON.stringify({
                name: fornecedor.name,
                consigned: fornecedor.consigned,
                id: fornecedor.id,
                landlineNumber: fornecedor.landlineNumber,
                address: fornecedor.address,
                suburb:fornecedor.suburb,
                zipCode: fornecedor.zipCode,
                city: fornecedor.city,
                state: fornecedor.state,
                stateRegistration: fornecedor.stateRegistration,
                email: fornecedor.email,
                phone1: fornecedor.phone1,
                phone2:fornecedor.phone2,
                phone3: fornecedor.phone3,
                phone4: fornecedor.phone4,
                mobileNumber:fornecedor.mobileNumber,
                poBox: fornecedor.poBox,
                bankPreference:fornecedor.bankPreference,
                email2: fornecedor.email2,
                authorizationToken:this.props.token
            })
        })
            .then(response => response.json())
            .then(response => {
                if (response){
                    fornecedor["supplierId"]=response
                    this.setState({
                        supplierId:response,
                        isUpdating:false,
                        updated:true,
                        updateSuccessful:true

                    })

                    this.clear()
                    //add the client to the localStorage array
                    let fornecedores = JSON.parse(localStorage.getItem("fornecedores"))
                    if(!fornecedores){
                        fornecedores=[]
                    }
                    fornecedores.push(fornecedor)
                    fornecedores=sortByKey(fornecedores,"name")
                    localStorage.setItem("fornecedores",JSON.stringify(fornecedores))
                    this.props.updateFornecedor(fornecedor)
                }

            })
            .catch(error => {
                if(fornecedor.supplierId.includes("AERS")){
                    this.clear()
                    //add the client to the localStorage array
                    let fornecedores = JSON.parse(localStorage.getItem("fornecedores"))
                    if(!fornecedores){
                        fornecedores=[]
                    }
                    fornecedores.push(fornecedor)
                    fornecedores=sortByKey(fornecedores,"name")
                    localStorage.setItem("fornecedores",JSON.stringify(fornecedores))
                    this.props.updateFornecedor(fornecedor)
                    this.setState({
                        isUpdating:false,
                        updated:true,
                        updateSuccessful:true})
                }
                else{
                    alert('Client não foi incluído no banco de dados. Tente novamente.')}

                console.error('Error:', error)
            })


    }

    addId(supplierId){
        return {
            "supplierId": supplierId,
            name: this.state.name,
            consigned: this.state.consigned,
            id: this.state.id,
            landlineNumber: this.state.landlineNumber,
            address: this.state.address,
            suburb:this.state.suburb,
            zipCode: this.state.zipCode,
            city: this.state.city,
            state: this.state.state,
            stateRegistration: this.state.stateRegistration,
            email: this.state.email,
            phone1: this.state.phone1,
            phone2:this.state.phone2,
            phone3: this.state.phone3,
            phone4: this.state.phone4,
            mobileNumber:this.state.mobileNumber,
            poBox: this.state.poBox,
            bankPreference:this.state.bankPreference,
            email2: this.state.email2,
        }
    }

    showDetails(){
        return(
            <div className="formDetails">
                <p>
                    <p className="formLabel">Company:</p>
                    <input
                        name="name"
                        className="formDetailInput"
                        placeholder="Type the company name"
                        style={{height: 40}}
                        onChange={
                            (e) =>this.handleChange(e)
                        }

                    />
                </p>
                <p className="formLabel">Consigned:
                    <input
                        name="consigned:"
                        onChange={
                            (e) =>this.handleChange(e)
                        }
                        className="formDetailInput"
                        placeholder="Type the contact person's name"
                        style={{height: 40}}
                    />
                </p>
                <p>
                    <span className="formLabel">Business number:</span>
                    <input
                        className="formDetailInput"
                        placeholder="Type the business number"
                        style={{height: 40}}
                        onChange={
                            (e) =>this.handleChange(e)
                        }
                        name="id"

                    />
                </p>
                <p>
                    <span className="formLabel">State Regitration:</span>
                    <input
                        name="stateRegistration"
                        className="formDetailInput"
                        placeholder="Type the state registration"
                        style={{height: 40}}
                        onChange={
                            (e) =>this.handleChange(e)
                        }

                    />

                </p>
                <span className="formLabel"><br/>
                    E-mail:
                    <input
                        name="email"
                        className="formDetailInput"
                        placeholder="Type the primary email"
                        style={{height: 40}}
                        onChange={
                            (e) =>this.handleChange(e)
                        }

                    />
                </span>
                <span className="formLabel"><br/>
                    E-mail (sec):
                    <input
                        name="email2"
                        className="formDetailInput"
                        placeholder="Provide an optional email"
                        style={{height: 40}}
                        onChange={
                            (e) =>this.handleChange(e)
                        }

                    />
                </span>
                <p>
                        <span className="formLabel">Landline:
                        <input
                            name="Telefone"
                            className="formDetailInput"
                            placeholder={this.state.landlineNumber}
                            style={{height: 40}}
                            onChange={
                                (e) =>this.handleChange(e)
                            }

                        />
                        </span>

                </p>
                <p>
                    <span> Phone 1
                        <input
                            name="phone1"
                            className="formDetailInput"
                            placeholder={this.state.phone1}
                            style={{height: 40, borderColor: 'gray', borderWidth: 1}}
                            onChange={
                                (e) =>this.handleChange(e)
                            }

                        />
                    </span>
                    <span> Phone 2
                        <input
                            name="phone2"
                            className="formDetailInput"
                            placeholder={this.state.phone2}
                            style={{height: 40, borderColor: 'gray', borderWidth: 1}}
                            onChange={
                                (e) =>this.handleChange(e)
                            }

                        />
                    </span>
                    <span> Phone 3
                        <input
                            name="phone3"
                            className="formDetailInput"
                            placeholder={this.state.phone3}
                            style={{height: 40, borderColor: 'gray', borderWidth: 1}}
                            onChange={
                                (e) =>this.handleChange(e)
                            }

                        />
                    </span></p>
                <p> Phone 4
                    <input
                        name="phone4"
                        className="formDetailInput"
                        placeholder={this.state.phone4}
                        style={{height: 40, borderColor: 'gray', borderWidth: 1}}
                        onChange={
                            (e) =>this.handleChange(e)
                        }

                    />
                    Mobile
                    <input
                        name="mobileNumber"
                        className="formDetailInput"
                        placeholder={this.state.mobileNumber}
                        style={{height: 40, borderColor: 'gray', borderWidth: 1}}
                        onChange={
                            (e) =>this.handleChange(e)
                        }

                    />

                </p><hr/>
                <p>
                    <FaAddressCard/> Address:
                    <input
                        name="address"
                        className="formDetailInput"
                        placeholder={this.state.address}
                        style={{height: 40, borderColor: 'gray', borderWidth: 1}}
                        onChange={
                            (e) =>this.handleChange(e)
                        }

                    />
                </p>
                <p>
                    <span className="clientDetailLabel">Suburb:</span>
                    <input
                        name="suburb"
                        className="formDetailInput"
                        placeholder={this.state.suburb}
                        style={{height: 40, borderColor: 'gray', borderWidth: 1}}
                        onChange={
                            (e) =>this.handleChange(e)
                        }

                    />
                </p>
                <p>
                    <span className="clientDetailLabel">City:</span>
                    <input
                        name="city"
                        className="formDetailInput"
                        placeholder={this.state.city}
                        style={{height: 40, borderColor: 'gray', borderWidth: 1}}
                        onChange={
                            (e) =>this.handleChange(e)
                        }

                    />
                </p>
                <p>
                    <span className="clientDetailLabel">State:</span>
                    <input
                        name="state"
                        className="formDetailInput"
                        placeholder={this.state.state}
                        style={{height: 40, borderColor: 'gray', borderWidth: 1}}
                        onChange={
                            (e) =>this.handleChange(e)
                        }

                    />
                </p>
                <p>
                    <span className="clientDetailLabel">Zip Code:</span>
                    <input
                        name="zipCode"
                        className="formDetailInput"
                        placeholder={this.state.zipCode}
                        style={{height: 40, borderColor: 'gray', borderWidth: 1}}
                        onChange={
                            (e) =>this.handleChange(e)
                        }

                    />
                </p>
                <p>
                    <span className="clientDetailLabel">PO Box: </span>
                    <input
                        name="poBox"
                        className="formDetailInput"
                        placeholder={this.state.poBox}
                        style={{height: 40, borderColor: 'gray', borderWidth: 1}}
                        onChange={
                            (e) =>this.handleChange(e)
                        }

                    />
                </p>
                <div className="buttonWrapper">
                    <button
                        onClick={
                            (e)=>{
                                e.preventDefault()
                                this.incluir(e, this.state)
                            }
                        }
                    >Incluir</button>
                </div>
                <br/>

            </div>
        )

    }

    render() {
        if(this.state.hasUpdated){
            return(
                <div>
                    <div className="updating">
                        <h3>Supplier added</h3>
                        <img src={plane3} aria-hidden alt="background image"/>
                        <p>
                            Thank you!
                        </p>
                    </div></div>
            )
        }
        else{
            if(this.state.isUpdating){
                return (
                    <div >
                        <div className="updating">
                            <h3>Adding supplier...</h3>
                            <img src={plane2} aria-hidden alt="background image"/>
                            <p>
                                Please wait!
                            </p>
                        </div>
                    </div>
                )
            }
            else{
                return (
                    <div className="NewSupplierForm">
                    <span>
                    {this.showDetails()}
                    </span>

                    </div>
                );}
        }
    }
}