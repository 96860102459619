import React from 'react';
export default class Refreshed extends React.Component {

    render() {
        return (
            <div id="refreshed">
                <span className={"title"}>Resources refreshed!</span>
                <br/>
                <br/>
                <span>Thank you</span>
            </div>
        )
    }
}


