import React from 'react';
import '../../css/requests.css'
import Modal from "react-responsive-modal";
import Stock from "../../model/Stock";
import {Quotation} from "../../model/Quotation";
import Item from "../../model/Item";
import ListaDeClientes from "../../model/ListaDeClientes";
import NewClient from "../desktop/clientes/NewClient";

export default class CustomerRequests extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            modalOpen:false,
            selectedItems:null,
            selectedRequest:null,
            newClientModal:false,
            clientName:'',
            clientLandlineNumber:'',
            clientMobileNumber:'',
            clientEmail:'',
            newClientModalContent:'',
            convertButton:''
        }
        this.showRequests=this.showRequests.bind(this)
        this.createQuotation=this.createQuotation.bind(this)
        this.showSelectedRequestButton=this.showSelectedRequestButton.bind(this)
        this.toggleModal=this.toggleModal.bind(this)
        this.closeNewClientModal=this.closeNewClientModal.bind(this)
        this.showNewClientModal=this.showNewClientModal.bind(this)
        this.showConvertToQuoteButton=this.showConvertToQuoteButton.bind(this)
    }

    showNewClientModal(e,requestForQuote){
        e.preventDefault()
        let newClientModalContent=<Modal
            open={this.state.newClientModal}
            onClose={this.closeNewClientModal}>
            <NewClient

                email={requestForQuote.email}
                mobileNumber={requestForQuote.mobileNumber}
                landlineNumber={requestForQuote.landlineNumber}
                name={requestForQuote.name}
            />
        </Modal>
        this.setState({
            newClientModalContent:newClientModalContent,
            newClientModal:true
        })
    }

    closeNewClientModal(e){
        e.preventDefault()
        this.setState({
            newClientModal:false,
            newClientModalContent:''
        })
    }
    showSelectedRequestButton(requestForQuote){
        let email=requestForQuote.email
        let listaDeClintes=new ListaDeClientes()
        let c=listaDeClintes.getClientByEmail(email)
        if(!c){
            return(
                <div className="buttonWrapper2">
                    <button
                        onClick={(e)=>
                        {
                            e.preventDefault()
                            this.showNewClientModal(e,requestForQuote)

                        }}
                    >Add Client</button>
                </div>
            )
        }
    }

    clientStatus(email){
        let listaDeClientes=new ListaDeClientes()
        let c=listaDeClientes.getClientByEmail(email)
        if(c){
            return (
                <div className="existingClientInfo">
                    This client is already in the database
                </div>
            )
        }
        else{
            return(
                <div className="nonExistingClientInfo">
                    Please note that this client is not in the database. Before converting this request to a quotation we recommend registering the client with the supplied e-mail address and then going back to this page to convert it to a quotation.
                </div>
            )
        }
    }



    createQuotation(e,req){
        e.preventDefault()
        //create itens
        let stock=new Stock()
        let itens=[]
        for(let i=0;i<req.items.length;i++){
            let productId=req.items[i].productId
            let product=stock.getProduct(productId)
            let quantity=req.items[i].quantity
            let item = new Item(product,quantity)
            itens.push(item)
        }
        let quotation=new Quotation()
        quotation.setOriginatingRequest(req)
        //quotation.setItens(itens)
        let email=req.email
        let client=this.props.listaDeClientes.getClientByEmail(email)
        if(client){
            quotation.setClient(client)
        }
        localStorage.setItem("novaCotacao",JSON.stringify(quotation))
        this.props.setQuoteBuilderQuote(e,quotation)
        this.props.setContent('New Quote')
        //alert('RequestForQuote was exported. You can finish it by going to the Quotation builder')
    }

    showConvertToQuoteButton(req){
        let cotacao=this.props.quoteBuilderQuote
        if(cotacao){
            if('requestId' in cotacao){
                if(cotacao.requestId===req.requestId){
                    return <div className="buttonWrapper3">
                            <button>Added to Quote builder</button>
                    </div>

                }

            }
             return <div className="buttonWrapper2">
                        <button
                            onClick={
                                (e)=>{this.createQuotation(e,req)}
                            }
                        >Convert to quote
                        </button>
                    </div>
        }
    }

    showRequests(){
        let requests=this.props.requests.requests
        let requestList= requests.map(function(req,i){
            return(
                <tr key={i}>
                    <td>{req.requestId}</td>
                    <td>{req.name}</td>
                    <td>{req.email}</td>
                    <td>{req.requestedDate}</td>
                    <td>
                        <a
                            href="/#"
                            onClick={(e)=>
                            {
                                e.preventDefault();
                                this.setState({
                                    selectedItems:req.items,
                                    modalOpen:true,
                                    selectedRequest:req
                                })
                            }
                            }
                        >{req.items.length} (Click to view)</a>
                        </td>
                    <td>
                        {this.showConvertToQuoteButton(req)}
                        {this.showSelectedRequestButton(req)}
                    </td>
                </tr>
            )
        },this)

        return(
            <table>
                <tbody>
                    <tr>
                        <th>Request ID</th>
                        <th>Name</th>
                        <th>E-mail</th>
                        <th>Date</th>
                        <th>Number of itens</th>
                        <th></th>
                    </tr>
                    {requestList}
                </tbody>
            </table>
        )

    }

    toggleModal(e){
        e.preventDefault()
        this.setState({newClientModal:!this.state.newClientModal})
    }

    showItens(){
        if(this.state.selectedItems){
            if(this.state.selectedRequest){
                let requestForQuote=this.state.selectedRequest
                let quotation=new Quotation()
                quotation.setOriginatingRequest(requestForQuote)
                let items=quotation.getItens()
                let email=requestForQuote.email
                let component= (
                    items.map(function(item,i){
                        let product=item.produto
                        return (
                            <tr key={i}>
                                <td>{product.productId}</td>
                                <td>{product.getDescription()}</td>
                                <td>{product.getUnity()}</td>
                                <td>{product.getPrice()}</td>
                                <td>{item.quantity}</td>
                                <td>{item.getSubtotal()}</td>
                            </tr>
                        )
                    },this)
                )
                return (
                    <div>
                        <h3>Request Information</h3>
                        <table>
                            <tbody>
                            <tr>
                                <th>Product Id</th>
                                <th>Description</th>
                                <th>Unity</th>
                                <th>Price</th>
                                <th>Quantity</th>
                                <th>Subtotal</th>
                            </tr>
                            {component}
                            </tbody>
                        </table>
                        <br/>
                        <table>
                            <tbody>
                            <tr>
                                <th>Total</th>
                            </tr>
                            <tr>
                                <td>
                                    {quotation.getTotal()}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <br/>
                        {this.clientStatus(email)}
                    </div>
                )
            }

        }
    }

    render() {

        return(
            <div className="requestsContainer">
                <p>Double click on "Add Client" button to add a new client</p>
                {this.showRequests()}
                <Modal
                    open={this.state.modalOpen}
                    onClose={(e)=>{
                        e.preventDefault();
                        this.setState({modalOpen:false})
                    }}>
                    {this.showItens()}
                </Modal>
                {this.state.newClientModalContent}
            </div>
        )
    }
}