import React from 'react';
const rio = require('../assets/rio.jpg');
export default class NotShipped extends React.Component {

    render() {
        return (
            <div className="appLoading">
                <div className="appLoadingInner">
                    <h1>ERROR - SHIPMENT NOT ADDED</h1>
                    <div className="rio">
                        <img
                            className="rio"
                            src={rio}
                            aria-hidden
                            alt="background image"
                        />
                    </div>



                    <div className="appLoadingText">

                        <h3>Unfortunately we had an error when processing shipment information.</h3>

                        <p>Please refresh resources from the main menu.</p>
                        <p>If this was originally an open quote, please check if an invoice was generated and the clients transaction history is correct. When you ship products, a debit transaction appears in the clients history. If you plan to re-submit a new quotation for invoice, you should audit client's history before determining if a new invoicing should be generated. If you cannot troubleshoot the issue, please email the system administrator at admin@leonardomartins.com.au</p>

                    </div>
                    <div className="bottomNote">
                        <p>
                            Developed by Cloud Computing Team
                        </p>
                        <p>
                            Royal Melbourne Institute of Technology
                        </p>
                        <p>
                            Versão: 1.0
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}


