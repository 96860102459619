import React from 'react';
import Logomarca from "../view/Logomarca";
export default class Welcome extends React.Component {

    render() {
        return (
            <div id="refreshed">

                <span className={"title"}>Aerotubos</span>
                <br/>
                <br/>
                <p className="welcomeParagraph">You are now authenticated</p>
                <br/><br/><br/>
                <div>
                    <Logomarca/>
                </div>

            </div>
        )
    }
}


