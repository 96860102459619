import React from 'react';
const rio = require('../../../assets/rio.jpg');
export default class ProductUpdatingWindow extends React.Component {

    constructor(props){
        super(props)
        this.showHeader=this.showHeader.bind(this)
        this.showAction=this.showAction.bind(this)
        this.showTextIfUpdated=this.showTextIfUpdated.bind(this)
    }

    showHeader(){
        if(this.props.headerType==="updating"){
            return ("Please wait . . . ")
        }
        if(this.props.headerType==="updated"){
            return ("Thank you")
        }
    }

    showAction(){
        if(this.props.headerType==="updating"){
            return ("We are updating the freight companies list")
        }
        if(this.props.headerType==="updated"){
            return ("Freight companies list updated")
        }
    }

    showTextIfUpdated(){
        if(this.props.headerType==='updated'){
            return "Press escape to return to main panel"
        }
    }

    render() {
        return (
            <div className="appLoading">
                <h3>{this.showHeader()}</h3>
                <div className="rio">
                    <img
                        className="rio"
                        src={rio}
                        aria-hidden
                        alt="background image"
                    />
                </div>


                <div className="aerotubos">
                    <img
                        className="aerotubos"
                        aria-hidden
                        alt="background image"
                    />

                </div>

                <div className="appLoadingText">

                    <h1>{this.showAction()}</h1>
                    <br/><br/><br/><br/>
                    <p>{this.showTextIfUpdated()}</p>
                    <br/>
                    <p>
                        Developed by Cloud Computing Team
                    </p>
                    <p>
                        Royal Melbourne Institute of Technology
                    </p>
                    <p>
                        Versão: 1.0
                    </p>
                </div>


            </div>
        )
    }
}


