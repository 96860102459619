import React from 'react';
import styles from '../../css/compraDeMaterial.css';
import Modal from 'react-responsive-modal';
import Select from 'react-select';
import { FaCheck} from "react-icons/fa";
import ProductList from "./ProductList";
import config from '../../config.js'
import Stock from "../../model/Stock";
const API_URL = config.api_endpoint
export default class CompraDeMaterial extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            fornecedor: '',
            isSubmitting:false,
            hasSubmitted:false,
            submittedSuccessfully:false,
            supplierId:'',
            produtos:[],
            transportadorId:'',
            transportAmount: 0,
            taxAmount: 0,
            listaDeProdutos:JSON.parse(localStorage.getItem("produtos")),
            supplierModal:false,
            productModal:false,
            transportModal:false,
            taxModal:false,
            fornecedores:JSON.parse(localStorage.getItem("fornecedores")),
            supplierOptions: this.props.supplierList.getOptions(),
            productOptions: '',
            selectedProduct:'',
            selectedProductId:'',
            precoNovoProduto: 0,
            moedaNovoProduto: 'R$',
            cotacaoDolar:'',
            quantidadeNovoProduto: 0,
            receiptReference:null,
            receiptDate:null,
            authorizationToken:this.props.token
        }
        this.incluir=this.incluir.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleSelectSupplier=this.handleSelectSupplier.bind(this)
        this.handleSelectProduct=this.handleSelectProduct.bind(this)
        this.onOpenSupplierModal=this.onOpenSupplierModal.bind(this)
        this.onCloseSupplierModal=this.onCloseSupplierModal.bind(this)
        this.onOpenProductModal=this.onOpenProductModal.bind(this)
        this.onCloseProductModal=this.onCloseProductModal.bind(this)
        this.onOpenTransportModal=this.onOpenTransportModal.bind(this)
        this.onCloseTransportModal=this.onCloseTransportModal.bind(this)
        this.onOpenTaxModal=this.onOpenTaxModal.bind(this)
        this.onCloseTaxModal=this.onCloseTaxModal.bind(this)
        this.addProductInfo=this.addProductInfo.bind(this)
        this.addProduct=this.addProduct.bind(this)
        this.subtotal=this.subtotal.bind(this)
        this.total=this.total.bind(this)
        this.submitPurchase=this.submitPurchase.bind(this)
        this.deleteProduct=this.deleteProduct.bind(this)
    }

    deleteProduct(e,productId){
        e.preventDefault();
        let produtos=[]
        for(let i=0;i<this.state.produtos.length;i++){
            if(this.state.produtos[i].productId!==productId)
            {
                produtos.push(this.state.produtos[i])
            }
        }
        this.setState({produtos:produtos})
        localStorage.setItem("newPurchaseProduct",JSON.stringify(produtos))
    }

    submitPurchase(e){
        e.preventDefault();
        this.setState({isSubmitting:true})
        let produtos=this.state.produtos
        for(let i=0;i<this.state.produtos.length;i++){
            delete produtos[i].unity;

        }
        let data={
            "supplierId": this.state.supplierId,
            "freightCompanyId": "000",
            "transportAmount": this.state.transportAmount,
            "taxAmount": this.state.taxAmount,
            "products": produtos,
            "receiptReference":this.state.receiptReference,
            "receiptDate":this.state.receiptDate,
            authorizationToken:this.props.token
        }
        console.log(JSON.stringify(data))
        fetch(API_URL+"/purchases/", {
            method: "POST",
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body:JSON.stringify(data)
        })
            .then(response => response.json())
            .then(response => {
                console.log(JSON.stringify(response))
               this.setState(
                   {
                       hasSubmitted:true,
                       isSubmitting:false,
                       submittedSuccessfully:true
                   })
                //update product list
                let products=response.products
                let newStock=new Stock()
                for(let i=0;i<products.length;i++){
                    newStock.changeProductData(products[i])
                }

            })
            .catch(error => {
                console.log(JSON.stringify(error))
                this.setState(
                    {
                        hasSubmitted:true,
                        isSubmitting:false,
                        submittedSuccessfully:false
                    })
            })
    }

    subtotal(){
        if(!(this.state.produtos)){
            return 0
        }
        let subtotal = 0;
        for(let i=0;i<this.state.produtos.length;i++){
            subtotal = subtotal +this.state.produtos[i].quantity*(this.state.produtos[i].price)
        }
        return subtotal
    }
    total(){
        let total=parseFloat(this.subtotal())+
            parseFloat(this.state.transportAmount)+
            parseFloat(this.state.taxAmount)
        return total
    }
    addProductInfo(e){
        e.preventDefault();
        this.setState({[e.target.name]:e.target.value});

    }
    addProduct(e){
        e.preventDefault();
        let produto={
            productId:this.state.selectedProductId,
            quantity:this.state.quantidadeNovoProduto,
            currency: this.state.moedaNovoProduto,
            price:this.state.precoNovoProduto,
            unity:this.state.selectedProduct.unity,
            description:this.state.selectedProduct.description
        }
        //validate product before adding to array
        if(produto.productId===""){
            alert("É selecionar um produto antes de adicionar.")
            return
        }
        //if product already in list need to abort
        if(this.state.produtos){
            for(let i=0;i<this.state.produtos.length;i++){
                if(this.state.produtos[i].productId===produto.productId){
                    alert("Operação ilegal. Não é permitido o mesmo item duas vezes")
                    return
                }
            }
        }

        if(produto.quantity<=0){
            alert("É necessário incluir a quantidade do produto")
            return
        }
        if(produto.price<=0){
            alert("Necessário incluir o preço por unidade do produto")
            return
        }
        let products=[]
        if(this.state.produtos) {
            products = this.state.produtos
        }

        products.push(produto)
        localStorage.setItem("newPurchaseProduct",JSON.stringify(products))
        this.setState(
            {
                produtos:products,
                productModal:false,
                selectedProduct:'',
                selectedProductId:'',
                precoNovoProduto: '',
                moedaNovoProduto: 'R$',
                cotacaoDolar:'',
                quantidadeNovoProduto: ''
            })
    }
    handleSelectProduct = (selectedOption) => {
        try{
            this.setState({
                selectedProduct:selectedOption.value,
                selectedProductId:selectedOption.value.productId
            })
        }
        catch(error){
            console.log(error)
        }
        console.log(this.state.selectedProductId)
    }
    handleSelectSupplier = (selectedOption) => {
        try{
            this.setState({
                fornecedor:selectedOption.value,
                supplierId:selectedOption.value.supplierId,
                supplierModal:false
            })
        }
        catch(error){
            console.log(error)
        }
    }


    componentWillMount(){
        //set options
        try{

            //products
            let products=JSON.parse(localStorage.getItem("produtos"))
            console.log(products)
            let productOptions=[]
            for(let i=0;i<products.length;i++){
                let value=products[i]
                let label=products[i].name
                productOptions.push({
                    value:value,
                    label:label.toString()
                })
            }
            this.setState({
                productOptions:productOptions,
                produtos:JSON.parse(localStorage.getItem("newPurchaseProduct"))

            })
        }
        catch(error){
            console.log(error)
        }


    }
    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });

    }
    incluir(e){
        e.preventDefault()
        this.setState({
            isUpdating:true,
            updated:false
        })
        let produto={authorizationToken:this.props.token}
        if(this.state.description){
            produto["description"]=this.state.description
        }
        else{
            alert("Não é possível adicionar produto sem descrição")
            return
        }
        if(this.state.unity){
            produto["unity"]=this.state.unity
        }
        else{
            alert("Não é possível adicionar produto sem indicar a unidade de medida")
            return
        }
        if(this.state.partNumber){
            produto["partNumber"]=this.state.partNumber
        }
        if(this.state.currency){
            produto["currency"]=this.state.currency
        }
        if(this.state.gramsPerSquareCentimeter){
            produto["gramsPerCentimeter"]=this.state.gramsPerSquareCentimeter
        }
        if(this.state.kgPerMeter){
            produto["kgPerMeter"]=this.state.kgPerMeter
        }
        if(this.state.price){
            produto["price"]=this.state.price
        }

        console.log(JSON.stringify(produto))
    }

    showDetails(){
        return(
            <div className="container">
                <span className="dica" role="img" aria-label="Lightbulb">
                        💡
                </span>
                <span className="mostrar-dica">
                    <p>
                        Faça o cadastro de um novo produto aqui.
                    </p>
                    <p >
                        A quantidade disponível será atualizada quando você informar uma compra.
                    </p>
                </span>
                <div className="row">
                    <div className="col-25">Descrição:
                    </div>
                    <div className="col-75">
                        <input
                            name="description"
                            className="editClientDetailsInput"
                            placeholder="Nome do produto"
                            style={{height: 40}}
                            onChange={
                                (e) =>this.handleChange(e)
                            }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-25">Part Number:
                    </div>
                    <div className="col-75">
                        <input
                            name="partNumber"
                            className="editClientDetailsInput"
                            placeholder="Digite o PN"
                            style={{height: 40}}
                            onChange={
                                (e) =>this.handleChange(e)
                            }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-25">Moeda:
                    </div>
                    <div className="col-75">
                        <input
                            name="currency"
                            className="editClientDetailsInput"
                            placeholder="Digite a moeda (BRA, USD, etc)"
                            style={{height: 40}}
                            onChange={
                                (e) =>this.handleChange(e)
                            }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-25">Unidade:
                    </div>
                    <div className="col-75">
                        <input
                            name="unity"
                            className="editClientDetailsInput"
                            placeholder="Digite a unidade (m, kg, un)"
                            style={{height: 40}}
                            onChange={
                                (e) =>this.handleChange(e)
                            }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-25">Peso (kg):
                    </div>
                    <div className="col-75">
                        <input
                            name="kgPerUnit"
                            className="editClientDetailsInput"
                            placeholder="Digite o nome do cliente"
                            style={{height: 40}}
                            onChange={
                                (e) =>this.handleChange(e)
                            }
                        />
                    </div>

                </div>
                <div className="row-center">
                    <span className="center">
                        <button
                            className="button-cancel"
                            onClick={
                                (e)=>this.props.cancelAddProduct(e)
                            }
                        >Cancelar
                        </button>
                    </span>
                    <span className="center">
                        <button
                            onClick={(e)=>this.incluir(e)}
                            className="button"
                        >Incluir
                        </button>
                    </span>
                </div>
            </div>
        )

    }

    render() {
        if(this.state.isSubmitting===true){
            return (
                <div className="info">
                    <p className="saving">
                        Enviando
                        <span>.</span>
                        <span>.</span>
                        <span>.</span>
                    </p>
                </div>
            )
        }
        else{
            if(this.state.hasSubmitted===true){
                if(this.state.submittedSuccessfully===true){
                    return (

                        <div className="info">
                            <p>Dados atualizados.</p>
                            <p>Obrigado</p>

                        </div>
                    )
                }
                else{
                    return (
                        <div className="info">
                            Dados não foram atualizados.
                            Desculpe.
                        </div>
                    )
                }
            }
            else{
                return this.renderNewPurchaseForm()
            }
        }




    }

    renderNewPurchaseForm(){
        return (
            <div>
                <div className="compraDeMaterial">
                            <span className="item">
                                <span className="button-container">
                                    <button
                                        className={this.getStatus("fornecedor")}
                                        onClick={this.onOpenSupplierModal}
                                    >Fornecedor
                                    </button>
                                </span>
                            </span>
                    <span className="item">
                                <span className="button-container">
                                    <button
                                        className={this.getStatus("produtos")}
                                        onClick={this.onOpenProductModal}
                                    >Produtos
                                    </button>
                                </span>
                            </span>
                    <span className="item">
                                <span className="button-container">
                                    <button
                                        className={this.getStatus("transporte")}
                                        onClick={this.onOpenTransportModal}
                                    >Transporte
                                    </button>
                                </span>
                            </span>
                    <span className="item">
                                <span className="button-container">
                                    <button
                                        className={this.getStatus("imposto")}
                                        onClick={this.onOpenTaxModal}
                                    >Imposto
                                    </button>
                                </span>
                            </span>
                    <div className="nf">
                        <label for="receiptReference">NF #:</label>
                        <input
                            id="receiptReference"
                            type="text"
                            name="receiptReference"
                            onChange={this.addProductInfo}
                        />
                        <label htmlFor="receiptDate">Data:</label>
                        <input
                            id="receiptDate"
                            type="date"
                            name="receiptDate"
                            onChange={this.addProductInfo}
                        />
                    </div>
                    {this.displaySubmitPurchaseButton()}

                    <Modal
                        open={this.state.supplierModal}
                        onClose={this.onCloseSupplierModal} center
                        classNames={{
                            overlay: styles.customOverlay,
                            modal: styles.customModal,
                        }}
                    >
                        <h2>Selecione o fornecedor</h2>
                        <Select

                            classNamePrefix={"leftContent"}
                            placeholder="Selecione"
                            onChange={this.handleSelectSupplier}
                            label="Single select"
                            options={this.props.supplierOptions}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 15,
                                minMenuHeight:100,
                                colors: {
                                    ...theme.colors,
                                    text: 'orangered',
                                    primary25: 'pink',
                                    primary: 'black',
                                    borderRadius:15
                                },
                            })
                            }
                        />
                    </Modal>
                    <Modal
                        open={this.state.productModal}
                        onClose={this.onCloseProductModal}
                    >
                        <h2>Adicione um produto</h2>
                        <Select
                            classNamePrefix={"leftContent"}
                            placeholder="Selecione"
                            onChange={this.handleSelectProduct}
                            label="Single select"
                            options={this.state.productOptions}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 15,
                                minMenuHeight:100,
                                colors: {
                                    ...theme.colors,
                                    text: 'orangered',
                                    primary25: 'pink',
                                    primary: 'black',
                                    borderRadius:15
                                },
                            })
                            }
                        />
                        <div className="unidade">
                            Unidade: {this.state.selectedProduct.unity}
                        </div>
                        <div className="todosDados">
                                    <span className="dadosProduto">
                                        <p>Selectione a moeda:</p>
                                        <select
                                            name="moedaNovoProduto"
                                            onChange={this.addProductInfo}
                                            className="currency">
                                            <option>R$</option>
                                            <option>US$</option>
                                        </select>
                                    </span>
                            {this.showDollarQuotation()}
                            <span className="dadosProdutoPreco">
                                            <p>Preço/un:</p>
                                            <input
                                                onChange={this.addProductInfo}
                                                className="formDetailInput"
                                                type="number"
                                                step="0.01"
                                                name="precoNovoProduto"
                                            >

                                            </input>
                                    </span>

                        </div>

                        <span className="dadosProduto">
                                            Quantidade:
                                            <input
                                                onChange={this.addProductInfo}
                                                className="formDetailInput"
                                                type="number"
                                                step={0.01}
                                                name="quantidadeNovoProduto"
                                            >

                                            </input>
                                    </span>
                        <div className="purchaseAddProductButtons">
                            <buton
                                className="adicionarProduto"
                                onClick={()=>alert("selecionou limpar")}>
                                Limpar
                            </buton>
                            <buton
                                className="adicionarProduto"
                                onClick={(e)=>this.addProduct(e)}>
                                Adicionar
                            </buton>
                        </div>

                    </Modal>
                    <Modal
                        open={this.state.transportModal}
                        onClose={this.onCloseTransportModal} center
                        classNames={{
                            overlay: styles.customOverlay,
                            modal: styles.customModal,
                        }}
                    >
                        <div className="todosDados">
                                    <span className="dadosProduto">
                                        <p>Custo de transporte (R$) </p>
                                            <input
                                                placeholder={this.state.transportAmount}
                                                onChange={this.addProductInfo}
                                                className="formDetailInput"
                                                type="number"
                                                step="0.01"
                                                name="transportAmount"
                                            >

                                            </input>
                                    </span>
                        </div>
                    </Modal>
                    <Modal
                        open={this.state.taxModal}
                        onClose={this.onCloseTaxModal} center
                        classNames={{
                            overlay: styles.customOverlay,
                            modal: styles.customModal,
                        }}
                    >
                        <div className="todosDados">
                                    <span className="dadosProduto">
                                        <p>Impostos (R$) </p>
                                            <input
                                                placeholder={this.state.taxAmount}
                                                onChange={this.addProductInfo}
                                                className="formDetailInput"
                                                type="number"
                                                step="0.01"
                                                name="taxAmount"
                                            >

                                            </input>
                                    </span>
                        </div>
                    </Modal>
                    {this.detalhesDaCompra()}
                </div>
            </div>

        )
    }

    getStatusIcon(component){
        switch(component) {
            case "fornecedor":
                if (this.state.supplierId.includes("AER")) {
                    return "V"
                }
                else {
                    return "X"
                }
            case "produtos" :
                if(this.state.produtos.length<1){
                    return "X"
                }
                else{
                    return "V"
                }
            default:
                break;
        }
    }

    getStatus(component){
        switch(component) {
            case "fornecedor":
                if (this.state.supplierId.includes("AERS")) {

                    return "selected"
                }
                else {
                    return "not-selected"
                }
            case "produtos" :
                if(this.state.produtos){
                    if(this.state.produtos.length<1){
                        return "not-selected"
                    }
                    else{
                        return "selected"
                    }
                }

                else{
                    return "not-selected"
                }
            case "transporte" :
                if(this.state.transportAmount>0){
                    return "selected"
                }
                else{
                    return "not-selected"
                }
            case "imposto" :
                if(this.state.taxAmount>0){
                    return "selected"
                }
                else{
                    return "not-selected"
                }
            default:
                break;
        }
    }

    onOpenProductModal(){
        this.setState({productModal:true})
    }
    onCloseProductModal(){
        this.setState({productModal:false})
    }

    onOpenSupplierModal(){
        this.setState({ supplierModal: true });
    };

    onCloseSupplierModal(){
        this.setState({ supplierModal: false });
    };
    onOpenTransportModal(){
        this.setState({ transportModal: true });
    };

    onCloseTransportModal(){
        this.setState({ transportModal: false });
    };
    onOpenTaxModal(){
        this.setState({ taxModal: true });
    };

    onCloseTaxModal(){
        this.setState({ taxModal: false });
    };

    displaySubmitPurchaseButton(){
        if(this.state.fornecedor!==""){
            if(this.state.produtos){
                if(this.state.produtos.length>0){
                    if(this.state.receiptReference){
                        if(this.state.transportAmount){
                            if(this.state.taxAmount){
                                if(this.state.receiptDate){
                                    return (
                                        <span className="item">
                                        <span className="button-container">
                                            <button
                                                className="submitPurchase"
                                                onClick={(e)=>this.submitPurchase(e)}
                                            ><FaCheck/>
                                            </button>
                                        </span>
                                    </span>
                                    )
                                }
                            }

                        }
                    }
                }
                else{
                    return ""
                }
            }


        }
        return ""
    }
    detalhesDaCompra(){
        if(this.state.produtos){
            return(
                <div>
                    <div className="detalhesDaCompra">
                        {this.showFornecedor()}

                        <ProductList
                            stock={this.props.stock}
                            produtos={this.state.produtos}
                            imposto={this.state.taxAmount}
                            transportAmount={this.state.transportAmount}
                            subtotal={this.subtotal()}
                            total={this.total()}
                            deleteProduct={this.deleteProduct}
                        />
                        <br/>
                    </div >
                    <table className="subtotalCompra">
                        <tr>
                            <th>Subtotal dos produtos:</th>
                            <td>{this.subtotal()}</td>
                        </tr>
                        <tr>
                            <th>Transporte: </th>
                            <td>{this.state.transportAmount}</td>
                        </tr>
                        <tr>
                            <th>Impostos: </th>
                            <td>{this.state.taxAmount}</td>
                        </tr>
                        <tr className="totalCompra">
                            <th>TOTAL: </th>
                            <td>{this.total()}</td>
                        </tr>
                    </table>
                </div>
            )
        }

    }

    showDollarQuotation(){
        if(this.state.moedaNovoProduto==='US$'){
            return (
                <span  className="dadosProduto">
                    <p>Cotação do dólar (R$/US$)</p>
                    <input
                        className="formDetailInput"
                        type="number"
                        step="0.001"
                    />
                </span>
            )
        }
        else{
            return ""
        }

    }

    showFornecedor(){
        if(this.state.fornecedor){
            return (
                <div>
                    Fornecedor: {"("+this.state.fornecedor.supplierId+") - " + this.state.fornecedor.name}
                </div>
            )
        }
        else{
            return ""
        }
    }
}