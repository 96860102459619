
export default class BankAccount {

    constructor(bank) {
        if(bank){
            if("bankId" in bank){this.bankId=bank.bankId}
            if("accountNumber" in bank){this.accountNumber=bank.accountNumber}
            if("accountHolder" in bank){this.accountHolder=bank.accountHolder}
            if("bankName" in bank){this.bankName=bank.bankName}
            if("branch" in bank){this.branch=bank.branch}
            if("id" in bank){this.id=bank.id}
            if("isThirdPartyAccount" in bank){this.isThirdPartyAccount=bank.isThirdPartyAccount}
            if("type" in bank){this.type=bank.type}
        }
    }
}