import React from 'react';
import '../../css/form.css';
import { MdAddBox } from "react-icons/md";
import {FaSearchPlus, FaHome} from "react-icons/fa";
import Select from 'react-select';
import FornecedoresDetails from "./FornecedoresDetails";
import NewSupplierForm from "./NewSupplierForm";
import {sortByKey} from "../../functions";
import Fornecedores from '../../model/Fornecedores'
import config from '../../config.js'
const API_URL = config.api_endpoint
export default class FornecedoresScreen extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            content:this.icones(),
            isInsertingNew:false,
            selectedOption: null,
            fornecedores:this.props.fornecedores,
            isEditing:false,
            isNewSupplier:false,
            options:'',
            isMounted:false,
            hasUpdated:false,
            leftMaximised:true,
            icons:true
        }
        this.handleChange=this.handleChange.bind(this)
        this.getSuppliers=this.getSuppliers.bind(this)
        this.showOptions=this.showOptions.bind(this)
    }

    showOptions(e){
        e.preventDefault()
        this.setState({
            content:this.icones()
        })
    }

    getSuppliers(){
        return fetch(API_URL+"/suppliers/", {
            method: "GET",
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(response => {
                if (!("errorMessage" in response)){
                    let fornecedores=sortByKey(response,"Nome")
                    this.setState({ fornecedores: new Fornecedores(fornecedores)})
                    localStorage.setItem("fornecedores",JSON.stringify(fornecedores))
                }
            })
            .catch(error => {
                console.error('Error:', error)
            });
    }

    componentWillMount(){
        if(!this.state.fornecedores)
        {
            let fornecedores = JSON.parse(localStorage.getItem("fornecedores"))
            if(!fornecedores){
                this.getSuppliers();
            }
            else{
                this.setState({
                    fornecedores:fornecedores
                })
            }
        }
        this.setOptions()
        if(localStorage.getItem('selectedSupplierId')){
            if(localStorage.getItem('selectedSupplierId').includes("AER")||localStorage.getItem('selectedSupplierId').includes("SUP")){
                for(let i=0; i<this.state.fornecedores.length;i++){
                    if(this.state.fornecedores[i].supplierId===localStorage.getItem('selectedSupplierId')){
                        let data={
                            "value":this.state.fornecedores[i],
                            "label": this.state.fornecedores[i].Nome
                        }
                        this.setState({selectedOption:data})
                        break;
                    }
                }
            }
        }


        document.addEventListener('keydown',
            (event) => {

                if(event.ctrlKey&&
                    event.altKey&&
                    (event.code==='KeyN'))
                {
                    this.setState({
                        isInsertingNew:true
                    })
                }

                else{
                    return ""
                }
            }
        )
    }


    setOptions(){

        let suppliers=this.state.fornecedores
        let options=[]
        for(let i=0;i<suppliers.length;i++){
            let value=suppliers[i]
            let label=suppliers[i].Nome
            options.push({
                value:value,
                label:label.toString()
            })
        }
        this.setState({
            options:options
        })
        this.setState({
            isMounted:true
        })
    }


    handleChange = (selectedOption) => {
        console.log(selectedOption)
        this.setState({ selectedOption });
        localStorage.setItem("selectedSupplierName",selectedOption.value.Nome)
        localStorage.setItem("selectedSupplierId",selectedOption.value.supplierId)
        localStorage.setItem("selectedSupplier",selectedOption)
        this.setState({
            content:this.showWithLeftMaximised()
        })
    }

    render() {
        return this.state.content
    }

    icones(){
        return (
            <div className="titulo">
                <h1>SUPPLIERS</h1>
                <span className="iconeContainer">
                    <span className="icone">
                        <button onClick={(e)=>
                            this.props.setContent(e,"")
                        }>
                            <FaHome/>
                            <div className="iconLabel">
                                Home
                            </div>
                        </button>
                    </span>
                    <span className="icone">
                        <button onClick={()=>
                            this.setState({
                                content:<NewSupplierForm
                                    token={this.props.token}
                                    showOptions={this.showOptions}
                                    updateFornecedor={this.props.updateFornecedor}
                                />
                            })
                        }>
                            <MdAddBox/>
                            <div className="iconLabel">
                                New
                            </div>
                        </button>
                    </span>
                    <span className="icone">
                        <button onClick={(e)=>
                            this.setState({
                                content:this.showWithLeftMaximised()
                            })
                        }>
                            <FaSearchPlus/>
                            <div className="iconLabel">
                                Search
                            </div>
                        </button>
                </span>
                </span>

            </div>
        )
    }


    showWithLeftMaximised(){
        let placeholder= "Digite o nome do fornecedor..."
        if(localStorage.getItem("selectedSupplierName")!=='undefined'){
            placeholder=localStorage.getItem("selectedSupplierName")
        }
        let fornecedores = JSON.parse(localStorage.getItem("fornecedores"))
        if(fornecedores){
            this.setOptions()
        }
        else{
            this.getSuppliers()
        }
        return (
            <div className="clientContainer">
                <span className="left">
                        <div>
                            <Select

                                classNamePrefix={"leftContent"}
                                placeholder={placeholder}
                                onChange={this.handleChange}
                                label="Single select"
                                options={this.props.supplierList.getOptions()}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 15,
                                    minMenuHeight:100,
                                    colors: {
                                        ...theme.colors,
                                        text: 'orangered',
                                        primary25: 'pink',
                                        primary: 'black',
                                        borderRadius:15
                                    },
                                })
                                }
                            />
                        </div>
                    </span>
                <span>
                    {this.showCard()}
                    </span>
            </div>

        );
    }
    showCard(){
        return this.showDetails()
    }

    showDetails(){
        if(this.state.selectedOption){
            return(
                <FornecedoresDetails
                    fornecedor={this.state.selectedOption.value}
                />
            )
        }
        else{
            return "Nothing selected"
        }


    }
}