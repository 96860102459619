import React from 'react';
import '../../../../css/cotacao.css'
import QuoteProductDetails from "./QuotationProductDetails";
import BankAccountDetail from "../../../desktop/banks/BankAccountDetail";

export default class QuoteSummary extends React.Component {
    constructor(props) {
        super(props);
        this.state={
        }
        this.requestInfo=this.requestInfo.bind(this)
        this.showSubmitButton=this.showSubmitButton.bind(this)
    }

    requestInfo(){
        let quotation=this.props.quotation
        if("requestId" in quotation){
            if(quotation.requestId){
                let requestForQuote=quotation.requestForQuote
                return (
                    <div className="quoteFromRequest">
                        Esta cotação foi originalmente exportada a partir da requisição online {requestForQuote.requestId} feita por {requestForQuote.name} - {requestForQuote.email}. Quando você enviar esta cotação, a requisição será processada e não aparecerá na seção de requisições.
                    </div>
                )
            }

        }
    }

    showSubmitButton(){
        if(this.props.quotation.isReadyToSubmit()){
            return(
                <div className="center">
                    <button
                        className="button-send"
                        onClick={
                            (e)=>{
                                this.props.emitir(e)
                            }
                        }
                    >Enviar
                    </button>
                </div>
            )
        }
    }

    render() {
        return (
            <div className="quoteSummary">
                <span>Cliente:
                    {this.props.quotation.getClientName()}
                    </span>
                <QuoteProductDetails
                    quotation={this.props.quotation}
                />
                <div className="summaryDiscountInput">
                    <label for="transportCostSummary">Desconto:</label>
                    <input
                        className="transportCostSummaryInput"
                        id={"transportCostSummary"}
                        placeholder={this.props.quotation.getDesconto()}
                        onChange={(e)=>this.props.setDesconto(e.target.value)}
                        type="number"
                        step={0.01}
                        name="transportCostSummary">
                    </input>
                </div>
                <BankAccountDetail quotation={this.props.quotation}/>
                {this.requestInfo()}
                {this.showSubmitButton()}

            </div>
        )
    }
}