import React from 'react';
import '../../../../css/envioDeProdutosForm.css'
import {FaCalendarAlt, FaClock} from "react-icons/fa";

export default class EnviarProdutos extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            shippingDate:null,
            shippingTime:null,
            trackingNumber:null,
            cost:null,
            quotationId:this.props.quotationId,
            authorizationToken:this.props.token
        }

        this.setValue=this.setValue.bind(this)
        this.checkForm=this.checkForm.bind(this)
        this.showConfirmButton=this.showConfirmButton.bind(this)
    }
    checkForm()
    {
        if(!this.state.shippingDate){
            console.log('null date')
            return false
        }
        if(!this.state.shippingTime){
            console.log('null shipping time')
            return false
        }
        // regular expression to match required date format
        let re = /^\d{4}-\d{1,2}-\d{1,2}$/;

        if(this.state.shippingDate !== '' && !this.state.shippingDate.match(re)) {
            console.log("Invalid date format: " + this.state.shippingDate);
            return false;
        }

        // regular expression to match required time format
        re = /^\d{1,2}:\d{2}([ap]m)?$/;

        if(this.state.shippingTime !== '' && !this.state.shippingTime.match(re)) {
            console.log("Invalid time format: " + this.state.shippingTime);
            return false;
        }

        if(!this.state.trackingNumber){
            console.log('no tracking number provided')
            return false
        }
        if(!this.state.cost){
            console.log('no cost provided')
            return false
        }
        if(isNaN(this.state.cost)){
            return false
        }
        return true;
    }

    setValue(e){
        e.preventDefault();
        this.setState({[e.target.name]:e.target.value})
        console.log(this.state)
        this.checkForm()
    }

    showConfirmButton(){
        if(this.checkForm()){
            return(
                <button
                    onClick={
                        ((e)=>{
                            e.preventDefault();
                            this.props.toggleEnviando(e)
                            this.props.shipProducts(this.state);

                        })
                    }
                >
                    Confirm
                </button>
            )
        }
    }

    //remember to sync localStorage with server responses

    render() {
        if(this.props.enviando){
            return "ENVIANDO..."
        }
        else{
            return(
                <div className="enviarProdutosOutterContainer">
                    <div className="enviarProdutosContainer">
                        <div className="label">
                            <h4>Shipment Info</h4>
                        </div>

                        <div className="inputElement">
                            <label for="shippingDate"><FaCalendarAlt/></label>
                        <input
                        className="dateInput"
                        type="date"
                        name="shippingDate"
                        onChange={this.setValue}
                    />
                </div>
                        <div className="inputElement">
                            <label for="shippingTime"><FaClock/></label>
                    <input
                        className="inputValue"
                        type="time"
                        name="shippingTime"
                        onChange={this.setValue}
                    />
                </div>
                        <br/>
                        <span className="inputElement">
                    <span className="label">Tracking #:</span>
                    <input
                        className="inputValue"
                        type="text"
                        name="trackingNumber"
                        onChange={this.setValue}
                    />
                </span>
                        <span className="inputElement">
                    <span className="label">Transport Cost:</span>
                    <input
                        className="inputValue"
                        type="number"
                        name="cost"
                        onChange={this.setValue}
                    />
                </span>
                        <div className="buttons">
                            <div className="actionContent">
                                <button
                                    onClick={this.props.hideActions}
                                >
                                    Cancel
                                </button>
                                {this.showConfirmButton()}
                            </div>
                        </div>

                    </div>
                </div>
            )
        }


    }
}