import React from 'react';
import '../../../../css/quoteForPrint.css'
import Produto from "../../../../model/Produto";
export default class QuotationProductDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state={
        }
    }

    showTransportRows(){
        if(this.props.quotation.isClientPayingFreight()){
            return(
                <tr className="blankRow">
                    <td colSpan="6">
                    </td>
                    <td className="subtotalLabel">Transporte: </td>
                    <td className="subtotalValue">
                        R$ {this.props.quotation.getValorTransporte()}
                    </td>
                </tr>
            )}
            else{
                return ""
            }
        }


    showDescontoRows(){
        if(this.props.quotation.getDesconto()>0){
            return(
                <tr className="blankRow">
                    <td colSpan="6">
                    </td>
                    <td className="subtotalLabel">Desconto: </td>
                    <td className="subtotalValue">
                        $ {this.props.quotation.getDesconto()}
                    </td>
                </tr>
            )}
        else{
                return ""
            }

    }

    //remember to sync localStorage with server responses

    render() {
        return (
            <div className="dadosProdutoCotacao">
                <table>
                    <tbody>
                    <tr>
                        <th>ITEM</th>
                        <th>DESCRIÇÃO</th>
                        <th>UN</th>
                        <th>PESO/UN (kg)</th>
                        <th>R$ / UN</th>
                        <th>Qtd</th>
                        <th>PESO TOTAL (kg)</th>
                        <th>TOTAL - R$</th>

                    </tr>
                    {this.props.quotation.getItens().map(function(item, i){
                        //let thisItem=new Item(item.produto,item.quantity)
                        return(
                            <tr key={i}>
                                <td>{i+1}</td>
                                <td>{(new Produto(item.produto)).getNameAndDescription()}</td>
                                <td>{item.produto.unity}</td>
                                <td>{(new Produto(item.produto)).getKgPerUnit().toFixed(2)}</td>
                                <td>{(new Produto(item.produto)).getPrice().toFixed(2)}</td>
                                <td>{item.quantity.toFixed(2)}</td>
                                <td>{(item.totalWeight*1).toFixed(2)}</td>
                                <td>{(item.subtotal*1).toFixed(2)}</td>
                            </tr>
                        )
                    },this)}
                    <tr className="blankRow">
                        <td colSpan="8">
                        </td>
                    </tr>
                    <tr className="blankRow">
                        <td colSpan="6">
                        </td>
                        <td className="subtotalLabel">Subtotal: </td>
                        <td className="subtotalValue">
                            R$ {this.props.quotation.getSubtotalDosProdutos().toFixed(2)}
                        </td>
                    </tr>
                    {this.showTransportRows()}
                    {this.showDescontoRows()}
                    <tr className="blankRow">
                        <td colSpan="6">
                        </td>
                        <td className="totalLabel">Total: </td>
                        <td className="totalValue">
                            R$ {this.props.quotation.getTotal().toFixed(2)}
                        </td>
                    </tr>
                    </tbody>
                </table>
                <br/>

            </div>
        )
    }
}