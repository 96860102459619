import React from 'react';
import '../../../css/visualizarCotacoes.css'
import QuotationProductDetails from "./quote_tab_components/QuotationProductDetails";
import {Quotation} from "../../../model/Quotation";
import Select from 'react-select';

export default class Finalized extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            links:this.showLinks(),
            quoteDetails:'',
            selectedQuote: null,
            enviarProdutos:false,
            atualizarPagamento:false
        }
        this.hideActions=this.hideActions.bind(this)
    }

    componentWillMount(){
        this.setState({
            links:this.showLinks(),
            enviarProdutos:false,
            atualizarPagamento:false
        })
    }

    showLinks(){
        console.log('links')
        let c=this.props.quotes.quotations
        console.log(c)

        return (this.props.quotes.quotations.map(function(item, i){
            return(
                <a
                    href="/#"
                    key={i}
                    onClick={(e)=>{
                        e.preventDefault();
                        this.setState({
                            selectedQuote:item.quotationId,
                            quoteDetails:
                                <div className="openQuoteContainer">

                                    <table className="openQuoteClientTable">
                                        <tbody>
                                        <tr>
                                            <th className="label">CLIENT ID</th>
                                            <th className="label">Name</th>
                                            <th className="label">E-MAIL</th>
                                            <th className="label">OWING AMOUNT</th>
                                        </tr>
                                        <tr>

                                            <td className="value">{item.cliente.clientId}</td>
                                            <td className="value">{item.cliente.name}</td>
                                            <td className="value">{item.cliente.email}</td>
                                            <td className="value">{this.props.listaDeClientes.getBalance(item.cliente.clientId)}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <p>
                                        Quotation ID: {item.quotationId}
                                    </p>
                                    <QuotationProductDetails
                                        quotation={new Quotation(item)}
                                    />
                                </div>,
                            enviarProdutos:false,
                            atualizarPagamento:false
                        })
                    }}
                >
                    {item.quotationId}
                </a>
            )
        },this))
    }


    hideActions(e){
        e.preventDefault();
        this.setState({
            atualizarPagamento:false
        });
    }

    handleChange = (selectedOption) => {
        console.log(selectedOption)
        let quotation=selectedOption.value
        this.setState({
            selectedQuote:quotation,
            quoteDetails:
                <div className="openQuoteContainer">

                    <table className="openQuoteClientTable">
                        <tbody>
                        <tr>
                            <th className="label">ID</th>
                            <th className="label">Name</th>
                            <th className="label">Email</th>
                            <th className="label">Owing balance</th>
                        </tr>
                        <tr>

                            <td className="value">{quotation.cliente.clientId}</td>
                            <td className="value">{quotation.cliente.name}</td>
                            <td className="value">{quotation.cliente.email}</td>
                            <td className="value">{this.props.listaDeClientes.getClientBalance(quotation.cliente.clientId)}</td>
                        </tr>
                        </tbody>
                    </table>
                    <p>
                        {quotation.quotationId}
                    </p>
                    <QuotationProductDetails
                        quotation={new Quotation(quotation)}
                    />
                </div>,
            enviarProdutos:false,
            atualizarPagamento:false
        })

    }

    render() {
        return(
            <div>
                <p id="quoteTitle">Select a FINALISED quote to view</p>
                <div id="selectContainer">
                    <Select
                        classNamePrefix={"leftContent"}
                        placeholder="Search quotation"
                        options={this.props.options}
                        onChange={this.handleChange}
                    />
                </div>
                <br/>
                <div id="quoteDetailsContainer">
                    {this.state.quoteDetails}
                </div>

            </div>
        )
    }

}