import React from 'react';
import './css/App.css';
import {FaLightbulb, FaHome} from "react-icons/fa";
import { TiGroup } from "react-icons/ti";
import ClientUpdatingWindow from './view/desktop/clientes/miscelaneous/ClientUpdatingWindow'
import Produtos from "./view/products/Produtos";
import Stock from './model/Stock'
import {sortByKey} from "./functions";
import Search from "./view/desktop/clientes/Search";
import FornecedoresScreen from "./view/Suppliers/FornecedoresScreen";
import NewQuotation from "./view/desktop/Quotation/NewQuotation";
import ListaDeClientes from "./model/ListaDeClientes";
import Client from "./model/Client";
import ListaDeTransportadores from "./model/ListaDeTransportadores";
import TransportadoresScreen from "./view/FreightCompanies/TransportadoresScreen";
import VisualizarCotacoes from "./view/desktop/Quotation/VisualizarCotacoes";
import Quotations from "./model/Quotations";
import {Quotation} from "./model/Quotation";
import OpenQuotes from "./view/desktop/Quotation/OpenQuotes";
import AwaitingPaymentQuotes from "./view/desktop/Quotation/AwaitingPaymentQuotes";
import Fornecedores from "./model/Fornecedores";
import ProductUpdatingWindow from "./view/products/miscelaneous/ProductUpdatingWindow";
import FreightCompanyUpdatingWindow from './view/FreightCompanies/miscelaneous/FreightCompanyUpdatingWindow'
import SupplierList from "./model/SupplierList";
import config from './config.js'
import Invoices from "./model/Invoices";
import PaymentsPanel from "./view/Payments/PaymentsPanel";
import PaymentAllocation from "./view/Payments/PaymentAllocation";
import QuotationPanelInstructions from "./view/desktop/Quotation/OpenQuoteComponents/QuotationPanelInstructions";
import PaymentsAllocationInstructions from "./view/Payments/PaymentsAllocationInstructions";
import Updated from "./view/Payments/Updated";
import AwaitingForPaymentNotShipped from "./view/desktop/Quotation/AwaitingForPaymentNotShipped";
import ReadyToShip from "./view/desktop/Quotation/ReadyToShip";
import Finalized from "./view/desktop/Quotation/Finalized";
import Shipped from "./ui_components/Shipped";
import NotShipped from "./ui_components/NotShipped";
import LoadingResources from "./ui_components/LoadingResources";
import Transactions from "./view/Payments/Transactions";
import {Requests} from "./model/Requests";
import CustomerRequests from "./view/requests/CustomerRequests";
import SideNavigation from "./view/SideNavigation";
import QuoteSent from "./view/desktop/Quotation/quote_tab_components/QuoteSent";
import NewProductForm from "./view/products/NewProductForm";
import NewClient from "./view/desktop/clientes/NewClient";
import Refreshed from "./ui_components/Refreshed";
import Welcome from "./ui_components/Welcome";
import NewTubeForm from "./view/products/NewTubeForm";
import Unpaid from "./view/invoices/Unpaid";
import CompraDeMaterial from "./view/products/CompraDeMaterial";
import BankAccounts from "./model/BankAccounts";
import BankAccount from "./model/BankAccount";
const API_URL = config.api_endpoint
export default class LandingPage extends React.Component {

    constructor(){
        super()
        this.state={
            mounted:false,
            content:<Welcome/>,
            clientes:[],
            produtos:[],
            requests:new Requests(),
            visualizarCotacoesTabs:[],
            estoque:new Stock(),
            quotations: new Quotations(JSON.parse(localStorage.getItem('quotations'))),
            openQuotes:(new Quotations()).getOpenQuotes(),
            supplierList:new SupplierList(),
            listaDeClientes: new ListaDeClientes(),
            listaDeTransportadores:new ListaDeTransportadores(),
            fornecedores:new Fornecedores(),
            cotacao: NewQuotation.retrieveSavedQuote(),
            homeTabIcon: null,
            quotationContent:null,
            quotationElements:{
                quotations:{
                    all: null,
                    open: null,
                    awaitingPayment:null,
                    expired: null,
                    finalised:null,
                },
                tabs:[]
            },
            icons:[],
            invoices:new Invoices(),
            paymentTabs:[],
            loading:true

        }
        this.showIconeCotacoes=this.showIconeCotacoes.bind(this)
        this.handler = this.handler.bind(this)
        this.getClients=this.getClients.bind(this)
        this.getProducts=this.getProducts.bind(this)
        this.getSuppliers=this.getSuppliers.bind(this)
        this.updateClientList=this.updateClientList.bind(this)
        this.setContent=this.setContent.bind(this)
        this.shipProducts=this.shipProducts.bind(this)
        this.updateFreightCompanies=this.updateFreightCompanies.bind(this);
        this.updateVisualizarCotacoesTabs=this.updateVisualizarCotacoesTabs.bind(this)
        this.loadStock=this.loadStock.bind(this)
        this.loadClients=this.loadClients.bind(this)
        this.loadTransportCompanies=this.loadTransportCompanies.bind(this)
        this.loadQuotations=this.loadQuotations.bind(this)
        this.loadInvoices=this.loadInvoices.bind(this)
        this.loadSuppliers=this.loadSuppliers.bind(this)
        this.loadBanks=this.loadBanks.bind(this)
        this.loadResources=this.loadResources.bind(this)
        this.addListeners=this.addListeners.bind(this)
        this.updateProductList=this.updateProductList.bind(this)
        this.updateFornecedor=this.updateFornecedor.bind(this)
        this.setPaymentTabs=this.setPaymentTabs.bind(this)
        this.setQuoteBuilderQuote=this.setQuoteBuilderQuote.bind(this)
    }

    loadResources(){
        let start=(new Date()).getTime()
        this.loadClients().then(
            this.loadStock().then(
                this.loadTransportCompanies().then(
                    this.loadQuotations().then(
                        this.loadInvoices().then(
                            this.loadSuppliers().then(
                                this.loadRequests().then(
                                    ()=>{
                                        let end=(new Date()).getTime()
                                        console.log('took ',(end-start),' milliseconds')
                                        this.setState({
                                            loading:false,
                                            quotationsComponent:<VisualizarCotacoes
                                                quotations={this.state.quotations}
                                                openQuotes={this.state.openQuotes}
                                                listaDeClientes={this.state.listaDeClientes}
                                                setContent={this.setContent}
                                                shipProducts={this.shipProducts}
                                                tabs={this.state.quotationElements.tabs}
                                                updateTabs={this.updateVisualizarCotacoesTabs}
                                                token={this.props.token}
                                            />
                                        })
                                    }
                                    ).then(
                                    ()=>{this.loadBanks()}
                                )
                        )
                    )
                )
            )
        ))

        this.setPaymentTabs();
    }

    async loadRequests(){
        await fetch(API_URL+"/requests/unprocessed", {
            method: "GET",
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                authorizationToken:this.props.token
            }
        })
            .then(response => response.json())
            .then(response => {
                if (!("errorMessage" in response)){
                    let r=new Requests(response)
                    this.setState({requests:r})

                }
            })
            .catch(error => {
                console.error('Error:', error)
                return null
            });
    }

    async loadStock(){
        let estoque=new Stock();
        //stock may have 0 or more products if products stored in localStorage

        if(estoque.getProducts().length>0){
            this.setState(
                {
                    estoque:estoque,
                }
                )
            return estoque
        }
            //calling API in case there is no product
            await fetch(API_URL+"/products/", {
                method: "GET",
                mode: "cors",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    authorizationToken:this.props.token
                }
            })
                .then(response => response.json())
                .then(response => {
                    if (!("errorMessage" in response)){
                        //sort product list by the product description
                        let produtos=sortByKey(response,"name")
                        //to keep track of last viewed page
                        localStorage.setItem("content","produtos")
                        //to ensure products are saved into localStorage
                        //this reduces unecessery calls to the backend
                        localStorage.setItem("produtos",JSON.stringify(produtos))
                        let estoque = new Stock();
                        this.setState({
                            estoque:estoque})
                        return response

                    }
                })
                .catch(error => {
                    console.error('Error:', error)
                    return null
                });

}
    addListeners(){
        document.addEventListener('keydown',
                (event) => {
                    var ESCAPE_KEY = 'Escape'
                    const keyName = event.key;
                    if(keyName===ESCAPE_KEY){

                        this.loadResources()
                    }
                }
            )
    }

    async loadBanks(){
        //ensure we try and load banks list from localStorage first
        let banks=JSON.parse(localStorage.getItem("banks"))
        if(!banks){banks=[]}
        if(banks){
            if(banks.length>0){
                let banksList=new BankAccounts()
                for(let i=0;i<banks.length;i++){
                    if(!('bankId' in banks[i])){continue;}
                    let bank=new BankAccount(banks[i])
                    banksList.addBank(bank)
                }
                if(banksList.getBanks().length>0){
                    this.setState(
                        {
                            banksList:banksList,
                            banksOptions:banksList.getOptions(),
                        }
                    )
                    return banks;}
            }
        }
        await fetch(API_URL+"/banks/", {

            method: "GET",
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                authorizationToken: this.props.token,
            },
        })
            .then(response => response.json())
            .then(response => {
                if (!("errorMessage" in response)){
                    let bankAccountsList=new BankAccounts(response);
                    let bankAccounts=sortByKey(bankAccountsList.getBanks(),"bankName")
                    localStorage.setItem("banks",JSON.stringify(bankAccounts))
                    bankAccountsList=new BankAccounts()
                    this.setState({
                        banksList:bankAccountsList,
                        banksOptions:bankAccountsList.getOptions()
                    })
                    return response

                }
            })
            .catch(error => {
                console.error('Error:', error)
                return null

            })

    }

    async loadClients(){
        //ensure we try and load banks list from localStorage first
        let clients=JSON.parse(localStorage.getItem("clients"))
        if(!clients){clients=[]}
        if(clients){
            if(clients.length>0){
                let listaDeClientes=new ListaDeClientes()
                for(let i=0;i<clients.length;i++){
                    if(!('clientId' in clients[i])){continue;}
                    let client=new Client(clients[i])
                    listaDeClientes.addClient(client)
                }
                if(listaDeClientes.getClients().length>0){
                    this.setState(
                        {
                            listaDeClientes:listaDeClientes,
                            clientListOptions:listaDeClientes.getOptions(),
                        }
                        )
                    this.setPaymentTabs()
                    return clients;}
            }
        }
            await fetch(API_URL+"/clients/", {

                method: "GET",
                mode: "cors",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    authorizationToken: this.props.token,
                },
            })
                .then(response => response.json())
                .then(response => {
                    if (!("errorMessage" in response)){
                        let listaDeClientes=new ListaDeClientes(response);
                        //listaDeClientes.addClients(response)
                        let clientes=sortByKey(listaDeClientes.getClients(),"name")
                        localStorage.setItem("clients",JSON.stringify(clientes))
                        listaDeClientes=new ListaDeClientes()
                        this.setState({
                            listaDeClientes:listaDeClientes,
                            clientListOptions:listaDeClientes.getOptions(),
                        })
                        this.setPaymentTabs()
                        return response

                    }
                })
                .catch(error => {
                    console.error('Error:', error)
                    return null

                })

    }
    async loadTransportCompanies(){
        let transportadores = new ListaDeTransportadores();
        if(transportadores.getTransportadores().length>0){
            this.setState(
                {
                    listaDeTransportadores:transportadores}
                )
            return transportadores

        }
        await fetch(API_URL+"/freightCompanies/", {
            method: "GET",
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                authorizationToken:this.props.token
            }
        })
            .then(response => response.json())
            .then(response => {
                let listaDeTransportadores=new ListaDeTransportadores();
                listaDeTransportadores.addTransportadores(response)
                this.setState(
                    {
                        listaDeTransportadores:listaDeTransportadores}
                    )
                return response

            })
            .catch(error => {
                console.error('Error:', error)
                return null
            });
    }
    async loadQuotations(){
        let quotations=JSON.parse(localStorage.getItem("quotations"))
        if(!quotations){
            quotations=[]
        }
        if(quotations){
            if(quotations.length>0){
                let cotacoes=[]
                for(let i=0;i<quotations.length;i++){
                    let quotation=new Quotation()
                    quotation.parseQuote(quotations[i])
                    //updateClientBalance in quotation, for convenience
                    quotation.cliente.Balance=this.state.listaDeClientes.getBalance(quotation.cliente.clientId)
                    cotacoes.push(quotation)

                }
                let c=new Quotations(cotacoes)
                let openQuotes=c.getOpenQuotes()
                this.setState(
                    {
                        quotations:new Quotations(cotacoes),
                        openQuotes:openQuotes
                    })
                this.updateVisualizarCotacoesTabs(cotacoes)

                return quotations
            }
        }

        await fetch (API_URL+"/quotations/", {
            method: "GET",
            mode:"cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                authorizationToken:this.props.token
            }
        })
            .then(response => response.json())
            .then(response => {
                let cotacoes=[]
                for(let i=0;i<response.length;i++){
                    let cotacao=new Quotation()
                    cotacao.parseQuote(response[i])
                    cotacoes.push(cotacao)
                }
                let c=new Quotations(cotacoes)
                let openQuotes=c.getOpenQuotes()
                localStorage.setItem("quotations",JSON.stringify(response))
                this.setState(
                    {
                        quotations:new Quotations(cotacoes),
                        openQuotes:openQuotes
                    }
                    )
                this.updateVisualizarCotacoesTabs(new Quotations(cotacoes))
                return response

            })
            .catch(error => {
                console.error('Error:', error)
                return null
            });
    }
    async loadInvoices(){
        let invoices=new Invoices()
        if(invoices.getInvoices().length>0){
            this.setState({invoices:invoices})
            return invoices
        }
        await fetch(API_URL+"/invoices/", {
            method: "GET",
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                authorizationToken:this.props.token
            }
        })
            .then(response => response.json())
            .then(response => {
                if (!("errorMessage" in response)){
                    let invoices=new Invoices(response)
                    localStorage.setItem("invoices",JSON.stringify(invoices))
                    this.setState({
                        invoices:invoices
                    })
                    this.setPaymentTabs()
                    return response

                }
            })
            .catch(error => {
                console.error('Error:', error)
                return null
            })
    }
    async loadSuppliers(){
        let supplierList= new SupplierList()
        if(supplierList.getFornecedores().length>0){
            //localStorage may already have data, do not
            //need to make unecessary calls to server
            this.setState(
                {
                    supplierList:supplierList,
                })

            return supplierList
        }

        await fetch(API_URL+"/suppliers/", {
            method: "GET",
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                authorizationToken:this.props.token
            }
        })
            .then(response => response.json())
            .then(response => {
                if (!("errorMessage" in response)){
                    let supplierList=new SupplierList()
                    let suppliers=sortByKey(response,"name")
                    supplierList.addFornecedores(suppliers)
                    this.setState(
                        {
                            supplierList:supplierList
                        })
                    return response

                }
            })
            .catch(error => {
                console.error('Error:', error)
                return null

            })
    }
    //sets the relevant tabs for the Quotation Visualization panel
    //This function needs to be called within loadQuotations() and
    //by the children whenever there is a change in the state of the
    //quotation
    updateVisualizarCotacoesTabs(quotations){
        let c=JSON.parse(localStorage.getItem('quotations'))
        c=new Quotations(c)
        this.setState({quotations:c})

        let homeTab=({
            content:'',
            label: <FaHome onClick={(e)=>{
                e.preventDefault();
                this.setContent('home')
            }}/>,
            selected:false
        })
        this.setState({homeTab:homeTab})
        let tabs=[]
        tabs.push(homeTab)
        let openQuotes=this.state.quotations.getQuotesBySituation('OPEN')
        //openQuotes=new Quotations(openQuotes)
        if(openQuotes){
                tabs.push(
                    {
                        content:<OpenQuotes
                            quotes={openQuotes}
                            options={openQuotes.getOptions()}
                            listaDeClientes={this.state.listaDeClientes}
                            setContent={this.setContent}
                            updateTabs={this.updateVisualizarCotacoesTabs}
                            allQuotes={this.state.quotations}
                            token={this.props.token}


                        />,
                        label: 'OPEN',
                        selected:true
                    }
                )

        }
        let awaitingPayment=this.state.quotations.getQuotesBySituation('AWAITINGFORPAYMENT')
        if(awaitingPayment){
            tabs.push(
                {
                    content:<AwaitingPaymentQuotes
                        quotes={awaitingPayment}
                        listaDeClientes={this.state.listaDeClientes}
                        updateTabs={this.updateVisualizarCotacoesTabs}
                        token={this.props.token}

                    />,
                    label: 'PAYMENT PENDING',
                    selected:false
                }
            )
        }
        let awaitingPaymentNotShipped=this.state.quotations.getQuotesBySituation('AWAITINGFORPAYMENTNOTSHIPPED')
        if(awaitingPaymentNotShipped){
            tabs.push(
                {
                    content:<AwaitingForPaymentNotShipped
                        quotes={awaitingPaymentNotShipped}
                        allQuotes={this.state.quotations}
                        listaDeClientes={this.state.listaDeClientes}
                        setContent={this.setContent}
                        updateTabs={this.updateVisualizarCotacoesTabs}
                        token={this.props.token}
                    />,
                    label: 'PAYMT PENDING/NOT SHIPPED',
                    selected:false
                }
            )
        }
        let readyToShip=this.state.quotations.getQuotesBySituation('READYTOSHIP')
        if(readyToShip){
            tabs.push(
                {
                    content:<ReadyToShip
                        quotes={readyToShip}
                        listaDeClientes={this.state.listaDeClientes}
                        setContent={this.setContent}
                        allQuotes={this.state.quotations}
                        updateTabs={this.updateVisualizarCotacoesTabs}
                        token={this.props.token}
                    />,
                    label: 'READY TO SHIP',
                    selected:false
                }
            )
        }
        let finalised=this.state.quotations.getQuotesBySituation('FINALIZED')
        if(finalised){
            tabs.push(
                {
                    content:<Finalized
                        token={this.props.token}
                        options={finalised.getOptions()}
                        quotes={finalised}
                        listaDeClientes={this.state.listaDeClientes}
                    />,
                    label: 'FINALISED',
                    selected:false
                }
            )
        }

        let expired=c.getQuotesBySituation('EXPIRED')
        if(expired){
            tabs.push(
                {
                    content:"Content to be developed",
                    label: 'EXPIRED',
                    selected:false
                }
            )
        }

        tabs.push(
            {
                content:<QuotationPanelInstructions/>,
                label: <FaLightbulb className="lightbulb"/>,
                selected:false

            }
        )


        let quotationElements=this.state.quotationElements
        quotationElements.tabs=tabs
        this.setState({
            quotationElements:quotationElements,
            quotations:c,
            openQuotes:c.getOpenQuotes()
        })
    }


    setPaymentTabs(){
        let tabs=[
            {
                content:'',
                label: <FaHome onClick={(e)=>{
                    e.preventDefault();
                    this.setContent('home')
                }}/>,
                selected:false
            }
        ]
        tabs.push(

            {
                content:<PaymentAllocation
                    token={this.props.token}
                    listaDeClientes={this.state.listaDeClientes}
                    quotations={this.state.quotations}
                    invoices={this.state.invoices}
                    setContent={this.setContent}
                />,
                label: 'PAYMENT ALLOCATIONS',
                selected:true
            },
            {
                content:<Transactions
                    token={this.props.token}
                    listaDeClientes={this.state.listaDeClientes}/>,
                label: 'TRANSACTIONS',
                selected:true
            },
            {
                content:<PaymentsAllocationInstructions/>,
                label: <span role="img" aria-label="Lightbulb">
                    💡
                </span>,
                selected:false

            }

        )
        this.setState({paymentTabs:tabs})
    }

    componentWillMount(){
        this.loadResources()
        this.addListeners()

    }

    setQuoteBuilderQuote(e,quotation){
        e.preventDefault()
        this.setState({cotacao:new Quotation(quotation)})
    }

    render(){

        if(this.state.loading){
            return <LoadingResources/>
        }
        else{
            return(
                <div>
                <SideNavigation setContent={this.setContent}/>
                    {this.showContent()}
                </div>
            )
            // return
        }


    }


    setContent(component){
        //ensure quotations as not empty array
        let content=""
        switch(component) {
            case 'Add new client':
                content=<NewClient
                    setContent={this.setContent}
                    updateClientList={this.updateClientList}
                    token={this.props.token}
                />
                this.setState({content:content})
                break;
            case 'Transactions':
                content=<Transactions
                    token={this.props.token}
                    listaDeClientes={this.state.listaDeClientes}/>
                this.setState({content:content})
                break;
            case 'Allocate Payments':
                content=<PaymentAllocation
                    token={this.props.token}
                    options={this.state.listaDeClientes.getOptions()}
                    listaDeClientes={this.state.listaDeClientes}
                    quotations={this.state.quotations}
                    invoices={this.state.invoices}
                    setContent={this.setContent}
                />
                this.setState({content:content})
                break;
            case 'Payments':
                content=<PaymentsPanel
                    listaDeClientes={this.state.listaDeClientes}
                    invoices={this.state.invoices}
                    tabs={this.state.paymentTabs}
                    setContent={this.setContent}
                    updateTabs={this.setPaymentTabs}
                    token={this.props.token}
                />;
                this.setState({content:content})
                break;
            case 'Clients':
                content = <Search
                    clientes={this.state.listaDeClientes.getClients()}
                    options={this.state.listaDeClientes.getOptions()}
                    updateClientList={this.updateClientList.bind(this)}
                    setContent={this.setContent}
                    loadClients={this.loadClients}
                    restoreInitialListeners={this.addListeners}
                    token={this.props.token}
                />
                this.setState({content: content})
                break;
            case 'New Product':
                content=<NewProductForm
                    token={this.props.token}
                    updateProductList={this.updateProductList}
                />
                this.setState({content:content})
                break;
            case 'Delayed Payment Invoices':
                content=<Unpaid
                    token={this.props.token}
                    updateProductList={this.updateProductList}
                    clients={this.state.listaDeClientes}
                    quotations={this.state.quotations}
                />
                this.setState({content:content})
                break;
            case 'New Tube':
                content=<NewTubeForm
                    token={this.props.token}
                    updateProductList={this.updateProductList}
                />
                this.setState({content:content})
                break;
            case 'New Purchase':
                content=<CompraDeMaterial
                    produtos={this.state.estoque.getProducts()}
                    stock={this.state.estoque}
                    token={this.props.token}
                    updateProductList={this.updateProductList}
                    supplierList={this.state.supplierList}
                    supplierOptions={this.state.supplierList.getOptions()}
                />
                this.setState({content:content})
                break;
            case 'Stock':
                content=<Produtos
                    produtos={this.state.estoque.getProducts()}
                    stock={this.state.estoque}
                    componentChange={this.handler}
                    onChange={this.addProduct}
                    setContent={this.setContent}
                    getProducts={this.getProducts}
                    loadStock={this.loadStock}
                    updateProductList={this.updateProductList}
                    token={this.props.token}
                />
                this.setState({content: content})
                break;
            case 'Freight Companies':
                content = <TransportadoresScreen
                    listaDeTransportadores={this.state.listaDeTransportadores}
                    setContent={this.setContent}
                    token={this.props.token}
                    updateFreightCompanies={this.updateFreightCompanies}
                    options={this.state.listaDeTransportadores.getOptions()}
                />
                this.setState({content: content})
                break;
            case 'Suppliers':
                content = <FornecedoresScreen
                    fornecedores={this.state.fornecedores}
                    setContent={this.setContent}
                    supplierList={this.state.supplierList}
                    updateFornecedor={this.updateFornecedor}
                    token={this.props.token}
                />
                this.setState({content: content})
                break;
            case 'Quotation Panel':
                content = <VisualizarCotacoes
                    quotations={this.state.quotations}
                    openQuotes={this.state.openQuotes}
                    listaDeClientes={this.state.listaDeClientes}
                    setContent={this.setContent}
                    shipProducts={this.shipProducts}
                    tabs={this.state.quotationElements.tabs}
                    updateTabs={this.updateVisualizarCotacoesTabs}
                    token={this.props.token}
                />
                this.setState({content:content});
                break;
            case 'home':
                localStorage.setItem("content","")
                this.loadResources();
                this.addListeners()
                this.setState({content: ''});
                break;
            case 'New Quote':
                content = <NewQuotation
                    banksList={this.state.banksList}
                    token={this.props.token}
                    refreshQuotations={this.loadQuotations}
                    setContent={this.setContent}
                    listaDeClientes={this.state.listaDeClientes}
                    produtos={this.state.produtos}
                    estoque={this.state.estoque}
                    options={this.state.estoque.getOptions()}
                    clientOptions={this.state.listaDeClientes.getOptions()}
                />
                this.setState({content: content})
                break;
            case 'updated':
                content = <Updated/>
                this.updateVisualizarCotacoesTabs()
                this.setState({content: content})
                break;
            case 'shipped':
                content=<Shipped/>
                this.setState({content:content});
                break;
            case 'notshipped':
                content=<NotShipped/>
                this.setState({content:content});
                break;
            case 'Requests':
                content= <CustomerRequests
                    token={this.props.token}
                    quoteBuilderQuote={this.state.cotacao}
                    setContent={this.setContent}
                    setQuoteBuilderQuote={this.setQuoteBuilderQuote}
                    requests={this.state.requests}
                    stock={this.state.produtos}
                    listaDeClientes={this.state.listaDeClientes}

                />
                this.setState({content:content});
                break;
            case 'Refresh':
                //remove localStorage items
                localStorage.removeItem('quotations')
                localStorage.removeItem('clients')
                localStorage.removeItem('invoices')
                localStorage.removeItem('produtos')
                localStorage.removeItem('transportadores')
                this.loadResources()
                this.setState({content:<Refreshed/>})
                break;
            case 'Logout':
                localStorage.removeItem('authToken')
                this.props.logout()
                break;
            case 'quoteSent':
                this.updateVisualizarCotacoesTabs()
                content= <QuoteSent/>
                this.setState({content:content});
                break;
            case 'Open Quotes':
                let openQuotes=this.state.quotations.getQuotesBySituation('OPEN')
                //openQuotes=new Quotations(openQuotes)
                content=<OpenQuotes
                                quotes={openQuotes}
                                listaDeClientes={this.state.listaDeClientes}
                                setContent={this.setContent}
                                updateTabs={this.updateVisualizarCotacoesTabs}
                                allQuotes={this.state.quotations}
                                token={this.props.token}
                                options={openQuotes.getOptions()}


                            />
                this.setState({content:content});
                break;
            case 'Payment Pending':
                let awaitingPayment=this.state.quotations.getQuotesBySituation('AWAITINGFORPAYMENT')
                content=<AwaitingPaymentQuotes
                                quotes={awaitingPayment}
                                options={awaitingPayment.getOptions()}
                                listaDeClientes={this.state.listaDeClientes}
                                updateTabs={this.updateVisualizarCotacoesTabs}
                                token={this.props.token}

                            />
                this.setState({content:content})
                break;
            case 'Payment Pending / Not shipped':
                let awaitingPaymentNotShipped=this.state.quotations.getQuotesBySituation('AWAITINGFORPAYMENTNOTSHIPPED')
                content=<AwaitingForPaymentNotShipped
                                quotes={awaitingPaymentNotShipped}
                                options={awaitingPaymentNotShipped.getOptions()}
                                allQuotes={this.state.quotations}
                                listaDeClientes={this.state.listaDeClientes}
                                setContent={this.setContent}
                                updateTabs={this.updateVisualizarCotacoesTabs}
                                token={this.props.token}
                            />
                this.setState({content:content})
                break;
            case 'Ready to ship':
                let readyToShip=this.state.quotations.getQuotesBySituation('READYTOSHIP')
                content=<ReadyToShip
                    quotes={readyToShip}
                    options={readyToShip.getOptions()}
                    listaDeClientes={this.state.listaDeClientes}
                    setContent={this.setContent}
                    allQuotes={this.state.quotations}
                    updateTabs={this.updateVisualizarCotacoesTabs}
                    token={this.props.token}
                />
                this.setState({content:content})
                break;
            case 'Finalised':
                let finalised=this.state.quotations.getQuotesBySituation('FINALIZED')
                content=<Finalized
                                token={this.props.token}
                                options={finalised.getOptions()}
                                quotes={finalised}
                                listaDeClientes={this.state.listaDeClientes}
                            />
                this.setState({content:content})
                break;

            default:
                this.setState({content: content})
                break;
        }
    }

    shipProducts(shippingInfo){
        let quotationId=shippingInfo.quotationId
        //need to call an API to handle this update....
        //API should:
        //update quote state with shipping info
        //generate an invoice for client
        //add transaction to client history
        //upon receiving code 200 from server, update localStorageArrays

        let data = {
            "shippingInformation": {
                "shippingDate": shippingInfo.shippingDate,
                "cost": shippingInfo.cost,
                "trackingNumber": shippingInfo.trackingNumber,
                "shippingTime":shippingInfo.shippingTime,
                "quotationId":shippingInfo.quotationId
            },
            authorizationToken: this.props.token
        }
        fetch(API_URL+"/quotations/"+quotationId+"/ship", {
            method: "POST",
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body:JSON.stringify(data)
        }).then(response => response.json()).then(response => {
            let cotacao=new Quotation(response)
            //response has both info about quotation and updated
            //info about the client from the server
            //need to update localStorage accordingly
            let listaDeClientes=this.state.listaDeClientes
            let cotacoes=this.state.quotations
            listaDeClientes.changeClientData(new Client(response.cliente))
            cotacoes.updateQuote(cotacao)
            this.setState({
                quotations:cotacoes,
                openQuotes:cotacoes.getOpenQuotes(),
                listaDeClientes:listaDeClientes,
                content:<VisualizarCotacoes
                    token={this.props.token}
                    quotations={cotacoes}
                    openQuotes={cotacoes.getOpenQuotes()}
                    listaDeClientes={this.state.listaDeClientes}
                    setContent={this.setContent}
                    shipProducts={this.shipProducts}
                    tabs={this.state.quotationElements.tabs}
                    updateTabs={this.updateVisualizarCotacoesTabs}
                />
            })
            localStorage.setItem("quotations",JSON.stringify(cotacoes))

        })
            .catch(error => {
                console.error('Error:', error)
            })
    }

    updateFreightCompanies(freightCompany){
        if("freightCompanyId" in freightCompany){
            let novaLista=new ListaDeTransportadores();
            novaLista.changeFreightCompanyData(freightCompany)
            this.setState({
                listaDeTransportadores:novaLista,
                transportadores:novaLista.getTransportadores(),
                content: <FreightCompanyUpdatingWindow
                    headerType="updated"
                />
            })
            this.addListeners()

        }
    }
    updateFornecedor(supplier){
        if("supplierId" in supplier){
            let novaLista=new SupplierList();
            novaLista.changeSupplierData(supplier)
            this.setState({
                supplierList:novaLista,
                suppliers:novaLista.getFornecedores(),
                content: 'updated'
            })
            this.addListeners()

        }
    }
    updateClientList(cliente){
        if("clientId" in cliente){
            let novaLista=new ListaDeClientes();
            novaLista.changeClientData(cliente)
            this.setState({
                listaDeClientes:novaLista,
                clientes:novaLista.getClients(),
                content: <ClientUpdatingWindow
                    clientName={cliente.name}
                    headerType="updated"
                />
            })
            this.addListeners()

        }
    }
    updateProductList(product){
        if("productId" in product){
            let novaLista=new Stock();
            novaLista.addProduct(product)
            novaLista.changeProductData(product)
            this.setState({
                estoque:novaLista,
                produtos:novaLista.getProducts(),
                content: <ProductUpdatingWindow headerType="updated"/>
            })
            this.addListeners()

        }
    }
    setClientOptions(){
        this.setState({
            isEditing:false
        })
        let clients=this.state.clientes
        let options=[]
        for(let i=0;i<clients.length;i++){
            let value=clients[i]
            let label=clients[i].Nome
            console.log('trying: ',clients[i])
            try{
                options.push({
                    value:value,
                    label:label.toString()
                })}catch(error){
                console.log('error setting options: ',error)
            }
        }
        this.setState({
            options:options
        })
        this.setState({
            isMounted:true
        })
        return options
    }

    getProducts(e){
        e.preventDefault();
        return fetch(API_URL+"/products/", {
            method: "GET",
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(response => {
                if (!("errorMessage" in response)){
                    let produtos=sortByKey(response,"description")
                    localStorage.setItem("content","produtos")
                    localStorage.setItem("produtos",JSON.stringify(produtos))
                    this.setState({
                        isMounted:true,
                        produtos:produtos,
                    })
                    //update this state stock
                    let estoque = new Stock();
                    for(let i=0;i<produtos.length;i++){
                        let produto=produtos[i]
                        estoque.addProduct(produto)
                    }
                    this.setState({estoque:estoque})

                }
            })
            .catch(error => {
                console.error('Error:', error)
            });
    }
    getClients(e){
        e.preventDefault();
        return fetch(API_URL+"/clients/", {
            method: "GET",
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(response => {
                if (!("errorMessage" in response)){
                    let clientes=sortByKey(response,"name")
                    localStorage.setItem("clients",JSON.stringify(clientes))
                    this.setState({
                        clientes:clientes,
                    })

                    //set the client objects from local storage
                    let listaDeClientes=new ListaDeClientes()
                    for(let i=0;i<clientes.length;i++){
                        let cliente=new Client(clientes[i])
                        listaDeClientes.addClient(cliente)
                    }
                    this.setState({listaDeClientes:listaDeClientes})
                    this.updateVisualizarCotacoesTabs()
                }
            })
            .catch(error => {
                this.setState({
                })
                console.error('Error:', error)
            });
    }
    getSuppliers(e){
        e.preventDefault()
        return fetch(API_URL+"/suppliers/", {
            method: "GET",
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(response => {
                if (!("errorMessage" in response)){
                    let fornecedores=sortByKey(response,"Nome")
                    localStorage.setItem("fornecedores",JSON.stringify(fornecedores))
                    this.setState({
                        fornecedores:fornecedores,
                    })
                }
            })
            .catch(error => {
                console.error('Error:', error)
            });
    }

    componentWillUnmount(){
        this.setState({mounted:false})
    }
    handler(e,component){
        e.preventDefault()
        this.setState({
            content:component,
        })


    }

    showContent(){
        return (
            <div className="content">

                <div className="conteudo">
                    {this.state.content}
                </div>

            </div>
        );
    }

    showIconeCotacoes(){
        try{
            if(this.state.quotations.getQuotes().length>0){
                return(
                    <span className="icone">
                    <button onClick={(e)=>
                        this.handler(
                            e,
                            <VisualizarCotacoes
                                token={this.props.token}
                                quotations={this.state.quotations}
                                openQuotes={this.state.openQuotes}
                                listaDeClientes={this.state.listaDeClientes}
                                setContent={this.setContent}
                                shipProducts={this.shipProducts}
                                tabs={this.state.quotationElements.tabs}
                                updateTabs={this.updateVisualizarCotacoesTabs}
                            />
                        )}>
                        <TiGroup/>

                    </button>
                            <div className="iconLabel"><span className="iconLabelText">Visualizar cotações</span></div>

                </span>
                )
            }
        }catch(err){
            return ""
        }

    }

}