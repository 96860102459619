import React from 'react';
const rio = require('../assets/rio.jpg');
const logo_aerotubos = require('../assets/arcos.jpg');
export default class Loggedout extends React.Component {

    render() {
        return (
            <div className="appLoading">
                <div className="appLoadingInner">
                    <h1>LOGGED OUT</h1>
                    <div className="rio">
                        <img
                            className="rio"
                            src={rio}
                            aria-hidden
                            alt="background image"
                        />
                    </div>


                    <div className="aerotubos">
                        <img
                            className="aerotubos"
                            src={logo_aerotubos}
                            aria-hidden
                            alt="background image"
                        />

                    </div>

                    <div className="appLoadingText">

                        <h3>We have logged you out</h3>

                        <p>Thank you for using out app</p>
                        <p>To log back in, please refresh this page</p>

                    </div>
                    <div className="bottomNote">
                        <p>
                            Developed by Cloud Computing Team - Royal Melbourne Institute of Technology (RMIT)
                        </p>
                        <p>
                            Version: 1.0
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}


