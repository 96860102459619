import React from 'react';
import './css/adminFormStyles.css'
import config from './config.js'
import Logomarca from "./view/Logomarca";
const API_URL = config.api_endpoint

export default class Authenticate extends React.Component {
    constructor(props){
        super(props);
        this.state={
            password:'',
            userName: '',
            token:''
        }
        this.showAuthenticationFailure=this.showAuthenticationFailure.bind(this)
        this.handleChange=this.handleChange.bind(this)
        this.authenticate=this.authenticate.bind(this)
        this.showSubmitButton=this.showSubmitButton.bind(this)
    }

    handleChange(event) {
        event.preventDefault()
        this.setState({ [event.target.name]: event.target.value });
        console.log(this.state)

    }

    showSubmitButton(){
        if(this.state.password&&this.state.userName){
            return (
                <input className="signin-btn" name="signin-btn" value="SIGN IN" onClick={this.authenticate}/>
            )
        }

    }

    showAuthenticationFailure(){
        if(this.props.failed){
            return(
                <p>Authentication failed</p>
            )
        }
    }

    authenticate(e){
        if(e){
            e.preventDefault()
        }

        //to ensure local storage data is fresh
        localStorage.removeItem("banks")
        localStorage.removeItem("content")
        localStorage.removeItem("invoices")
        localStorage.removeItem("novaCotacao")
        localStorage.removeItem("produtos")
        localStorage.removeItem("quotations")
        localStorage.removeItem("transportadores")

        let bodyRequest={
            userName:this.state.userName,
            password:this.state.password
        }
        fetch(API_URL+"/admin/authenticate/", {
            method: "POST",
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body:JSON.stringify(bodyRequest)
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                    this.props.setToken(e,response)

            })
            .catch(error => {
                console.log(error)
            })
    }

    render() {
        if(this.props.failed){
            console.log('failed')
            return(
                <div>
                <div className="signin-box">
                    <div className="logoMarca">
                        <Logomarca/>
                    </div>
                    <div className="signin-form-h1">

                        SIGN IN
                    </div>
                    <form className="signin-form">
                        <div className="field-wrap">
                            <label className="signin-form-h2" htmlFor="usrname-input">username</label>
                            <input className="form-input" id="usrname-input" type="text" name="userName"
                                   onChange={this.handleChange}
                                   placeholder="username"/>
                        </div>
                        <div className="field-wrap">
                            <label className="signin-form-h2" htmlFor="pw-input">password</label>
                            <input className="form-input" id="pw-input" type="password" name="password"
                                   onChange={this.handleChange}
                                   placeholder="password"/>
                        </div>
                        {this.showSubmitButton()}
                        <p id="failed">Authentication failed. Please try again</p>
                    </form>
                </div>
                    <Logomarca/>
                </div>
            )

        }
        return (
            <div>
                <div className="signin-box">
                    <div className="logoMarca">
                        <Logomarca/>
                    </div>
                    <div className="signin-form-h1">
                        SIGN IN
                    </div>
                    <form className="signin-form">
                        <div className="field-wrap">
                            <label className="signin-form-h2" htmlFor="usrname-input">username</label>
                            <input className="form-input" id="usrname-input" type="text" name="userName"
                                   onChange={this.handleChange}
                                   placeholder="username"/>
                        </div>
                        <div className="field-wrap">
                            <label className="signin-form-h2" htmlFor="pw-input">password</label>
                            <input className="form-input" id="pw-input" type="password" name="password"
                                   onChange={this.handleChange}
                                   placeholder="password"/>
                        </div>
                        {this.showSubmitButton()}
                    </form>
                </div>

            </div>
        )

    }
}